/* ordered list of all media queries (for mqpacker)*/
.text-primary{ color: #d7001e; }
.text-white{ color: #fff; }
.text-default{ color: #323233; }
.text-dark-grey{ color: #7d7d80; }
.text-basic-grey{ color: #d9dadb; }
.text-muted{ color: #a0a2a3; }
.text-red{ color: #D7001E; }
.text-green{ color: #3FA435; }
.text-easy{ color: #0066CC; }
.text-middle{ color: #CC0000; }
.text-hard{ color: #323233; }
.bg-primary{ background: #d7001e; }
.bg-white{ background: #fff; }
.bg-muted{ background: #a0a2a3; }
.bg-light-grey{ background: #f2f4f5; }
.bg-basic-grey{ background: #d9dadb; }
.bg-dark-grey{ background: #7d7d80; }
.d-ib {display: inline-block;}
.d-b {display: block;}
.d-i {display: inline;}
.isClickable { cursor: pointer; }
.isCursor{ cursor: pointer; }
.font-style-italic{ font-style: italic }
.font-oswald{ font-family: Oswald-Regular, Arial !important; }
/* margins */
.mb0 { margin-bottom:0; }
.mb5 { margin-bottom:5px; }
.mb15 { margin-bottom:15px; }
.mb10 { margin-bottom:10px; }
.mb30 { margin-bottom:15px; }
.mb20 { margin-bottom:20px; }
.mb40 { margin-bottom:20px; }
.mb80 { margin-bottom:50px; }
.mt5 { margin-top:5px; }
.mt10 { margin-top:10px; }
.mt15 { margin-top:15px; }
.mt20 { margin-top:20px; }
.mt30 { margin-top:15px; }
.mt40 { margin-top:20px; }
.mt50 { margin-top:25px; }
.mt60 { margin-top:30px; }
.ml15{ margin-left: 15px; }
.ml20{ margin-left: 20px; }
.mr10{ margin-right: 10px; }
.mr5{ margin-right: 5px; }
.mr30{ margin-right: 15px; }
.p0.p0{
    padding: 0;
}
.no-margin.no-margin{ margin: 0;}
.w-auto{
    width: auto;
}
.underline{
    text-decoration: underline;
}
.pos-absolute{
    position: absolute;
}
.pos-relative{
    position: relative;
}
.hover-link:hover{
    text-decoration: underline;
}
a.skiplink {
    position: fixed;
    left: -10000px;
    top: -10000px;
    z-index: 1000;
    font-size: 14px;
    padding: 3px;
}
a.skiplink:focus {
    left: 0;
    top: 0;
    outline: none;
    background-color: #d7001e;
    color: #fff;
}
.toc-nav{
    position: absolute;
    z-index: 20;
}
.toc-nav .sr-only-focusable{
   padding: 20px;
    display: block;
    background: #fff;
    margin-top: 0!important;
}
.ankerinfo {
    display: block;
}
.ankerinfo:target {
    padding-top: 30px;
    margin-top: -30px;
}
.ankerinfo.editmode {
    display: inline-block;
    padding-top: 0px !important;
    margin-top: 0px !important;
    visibility: visible;
}
.link-primary{
    position: relative;
}
.link-primary:hover{
    text-decoration: underline;
}
.link-primary.text-primary,
.link-primary.text-primary:hover{
    color: #d7001e;
}
.link-primary>span.icon{
    position: relative;
    top: 2px;
    margin-right: 7px;
    font-size: 16px;
}
.list-primary{
    margin-bottom: 0;
}
.list-primary>li{
    position: relative;
    padding-left: 18px;
    margin-bottom: 8px;
}
.list-primary>li:before{
    content: "\E01C";
    font-family: iconfont;
    font-size: 14px;
    position: absolute;
    left: 0;
    top: 3px;
}
.list-primary>li:hover:before,
.list-primary>li>a:hover{
    color: #d7001e;
}
.list-primary>li>a:hover{
    text-decoration: underline;
}
.list-primary>li>a{
    display: block;
}
/*font sizes*/
.fz60{
    font-size: 38px;
}
.progress.progress--primary{
    margin-top: 20px;
    background-color: #fff;
    border-radius: 1000px;
}
.progress--primary .progress-bar{
    background: #3d9841;
}
.flex-parent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.flex-item {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 100px;
            flex: 1 1 100px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.flex-grow__1{
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.flex-grow__0{
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.flex-shrink__0{
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
.d-flex{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.align-items-center{
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.justify-content-between{
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.justify-content-center{
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.grey-overlay:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.35);
    z-index: 1;
}
.embed-responsive-cover{
    height: 100%;
}
.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
}
.h-100{
    height: 100%;
}
.position-relative{
    position: relative;
}
html {
    -ms-overflow-style: scrollbar; /*prevent scrollbar from overlapping content*/
}
a {
    color: inherit;
    cursor: pointer;
}
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}
address {
    margin-bottom: 0;
}
textarea {
    resize: vertical;
}
label {
    font-weight: inherit;
}
th {
    font-weight: normal;
    font-family: SourceSansPro-Bold, Arial;
}
::-moz-selection {
    background: #288cd2;
    color: #fff;
}
::selection {
    background: #288cd2;
    color: #fff;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
     position: static;
     width: auto;
     height: auto;
     margin: 0;
    margin-top: 30px;
     overflow: visible;
     clip: auto;
}
#vee24LiveHelpTabHTML {
    left:0;
}
body {
    position: relative;
}
html.has-overflow{
    overflow: hidden;
}
.container.container {
    max-width: 1530px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
}
.container.container--nav {
    max-width: 1860px;

    padding: 0 20px;
    /*padding: 0;*/
    margin: 0 auto;
    width: 100%;
}
.container--subnav {
    max-width: 1330px;
    display: block;
}
.container.container--webcam{
    max-width: 1230px;
    margin: 0 auto;
}
.container-outer {
    max-width: 1920px;
    margin: 0 auto;
}
.container.container--small{
    max-width: 1020px;
}
.container-small{
    max-width: 1020px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;

}
.container-medium{
    max-width: 1290px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin: 0 auto;

}
.container>.navbar-header,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container-fluid>.navbar-collapse{
    margin-right: -20px;
    margin-left: -20px;
}
.page-wrapper.page-wrapper--full{
    padding-right: 0;
}
.page-wrapper.page-wrapper--lp{
    padding-top: 0;
    padding-right: 0;
}
.pimcore_area_content{
    margin: 25px 0;
}
.main-content > .pimcore_area_content:last-of-type {
    margin-bottom: 0;
}
.pimcore_area_content.pimcore_area_snippet_slide,
.pimcore_area_content.pimcore_area_teaser_text-teaser-row{
    margin: 0;
}
.pimcore_area_content.pimcore_area_ankerinfo,
.pimcore_area_content.pimcore_area_text_headline1,
.main-header-content .pimcore_area_content{
    margin: 0;
}
.pimcore_area_content.pimcore_area_ankerinfo{
    visibility: hidden;
    overflow: hidden;
}
/*.content-block{*/
/*margin: 30px 0;*/
/*}*/
.contentblock{
    margin: 25px 0;
}
.contentblock-small{
    margin: 25px 0;
}
.pimcore_area_text_headline1 + .ankerinfo + .pimcore_area_text_wysiwyg .content-block,/* Abstand Hack wenn wysiwyg area nach der page-heading kommt */
.pimcore_area_separator + .ankerinfo + .pimcore_area_media_image .content-block,/* Abstand Hack wenn image area nach der separator kommt */
.pimcore_area_separator + .ankerinfo + .pimcore_area_media_video .content-block{
    margin: 0;
}
body {
    font-size: 16px;
    color: #323233;
    line-height: 1.3125;
    font-family: SourceSansPro-Light, Arial;
}
.font-bold, strong, .strong, b {
    font-family: SourceSansPro-Bold, Arial;
    font-weight: 400;
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: 400;
}
h1, .h1 {
    font-size: 26px;
    line-height: 1.15385;
    font-family:Oswald-Regular, Arial;
    margin-bottom: 20px;
}
h2, .h2 {
    font-size: 26px;
    line-height: 1.15385;
    font-family:Oswald-Regular, Arial;

}
h3, .h3 {
    font-size: 22px;
    line-height: 1.27273;
    font-family:Oswald-Regular, Arial;

}
h4, .h4 {
    font-size: 18px;
    line-height: 1.38889;
    font-family: Oswald-Regular, Arial;
}
h5, .h5 {
    font-size: 16px;
    line-height:1.25;
    font-family:Oswald-Regular, Arial;
}
h6, .h6{
    font-size: 15px;
    line-height:1.53333;
    font-family:Oswald-Regular, Arial;
}
.fz10 { font-size: 10px; }
.fz15 { font-size: 15px; }
.fz16 { font-size: 16px; }
.fz17 { font-size: 17px; }
.fz18 { font-size: 18px; }
.fz20 { font-size: 18px; }
.fz24 { font-size: 18px; }
.fz30 { font-size: 20px; }
.wysiwyg ul:not(.list-unstyled) {
    padding-left: 0;
    list-style-type: none;
}
.wysiwyg ul:not(.list-unstyled) li + li {
    margin-top: 5px;
}
.wysiwyg ul:not(.list-unstyled) li {
    padding-left: 26px;
    text-indent: -18px;
}
.wysiwyg ul:not(.list-unstyled) li:before {
    content: '';
    display: inline-block;
    vertical-align: .2em;
    width: 6px;
    height: 6px;
    background-color: #d7001e;;
    margin-right: 10px;
}
.wysiwyg p:last-child,
.wysiwyg ul:last-child,
.wysiwyg li:last-child,
.wysiwyg table:last-child {
    margin-bottom: 0;
}
.wysiwyg a{
    color: #d7001e;
}
.wysiwyg-img{
    margin-bottom: 20px;
}
.wysiwyg img{
    display: block;
    max-width: 100%;
    height: auto;
}
@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1724067418381/static/debug/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1724067418381/static/debug/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1724067418381/static/debug/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}
.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
            transform: translate(0, 0);
    min-width: 0;
}
.icon-lg {
                     font-size: 1.3333333333333333em;
                     line-height: 0.75em;
                     vertical-align: -15%;
                 }
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
                     width: 1.2857142857142858em;
                     text-align: center;
                 }
.icon-ul {
                     padding-left: 0;
                     margin-left: 2.14285714em;
                     list-style-type: none;
                 }
.icon-ul > li {
                     position: relative;
                 }
.icon-li {
                     position: absolute;
                     left: -2.14285714em;
                     width: 2.14285714em;
                     top: 0.14285714em;
                     text-align: center;
                 }
.icon-li.icon-lg {
                                         left: -1.85714286em;
                                     }
.icon-rotate-45 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
                     -webkit-transform: rotate(45deg);
                     -ms-transform: rotate(45deg);
                     transform: rotate(45deg);
                 }
.icon-rotate-90 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
                     -webkit-transform: rotate(90deg);
                     -ms-transform: rotate(90deg);
                     transform: rotate(90deg);
                 }
.icon-rotate-180 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
                     -webkit-transform: rotate(180deg);
                     -ms-transform: rotate(180deg);
                     transform: rotate(180deg);
                 }
.icon-rotate-270 {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
                     -webkit-transform: rotate(270deg);
                     -ms-transform: rotate(270deg);
                     transform: rotate(270deg);
                 }
.icon-flip-horizontal {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
                     -webkit-transform: scale(-1, 1);
                     -ms-transform: scale(-1, 1);
                     transform: scale(-1, 1);
                 }
.icon-flip-vertical {
                     filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
                     -webkit-transform: scale(1, -1);
                     -ms-transform: scale(1, -1);
                     transform: scale(1, -1);
                 }
:root .icon-rotate-90,
                       :root .icon-rotate-180,
                                              :root .icon-rotate-270,
                                                                     :root .icon-flip-horizontal,
                                                                                            :root .icon-flip-vertical {
                                                                                                                       -webkit-filter: none;
                                                                                                                               filter: none;
                                                                                                                   }
.icon-accordion:before { content: "\E001" }
.icon-achtung:before { content: "\E002" }
.icon-airplane:before { content: "\E003" }
.icon-anl-2103:before { content: "\E004" }
.icon-anl-2206:before { content: "\E005" }
.icon-anl-601:before { content: "\E006" }
.icon-anl-603:before { content: "\E007" }
.icon-anl-604:before { content: "\E008" }
.icon-anl-605:before { content: "\E009" }
.icon-anl-606:before { content: "\E00A" }
.icon-anl-607:before { content: "\E00B" }
.icon-anl-608:before { content: "\E00C" }
.icon-anl-609:before { content: "\E00D" }
.icon-anl-610:before { content: "\E00E" }
.icon-anl-611:before { content: "\E00F" }
.icon-anl-612:before { content: "\E010" }
.icon-anl-613:before { content: "\E011" }
.icon-anl-614:before { content: "\E012" }
.icon-anl-616:before { content: "\E013" }
.icon-anl-617:before { content: "\E014" }
.icon-anl-618:before { content: "\E015" }
.icon-anl-closed:before { content: "\E016" }
.icon-anl-open:before { content: "\E017" }
.icon-arrow-btn:before { content: "\E018" }
.icon-arrow-down-long:before { content: "\E019" }
.icon-arrow-down:before { content: "\E01A" }
.icon-arrow-full-left:before { content: "\E01B" }
.icon-arrow-full:before { content: "\E01C" }
.icon-arrow-up-long:before { content: "\E01D" }
.icon-arrow:before { content: "\E01E" }
.icon-attraktion:before { content: "\E01F" }
.icon-bergbahnen:before { content: "\E020" }
.icon-berghuette:before { content: "\E021" }
.icon-biken:before { content: "\E022" }
.icon-bildergalerie:before { content: "\E023" }
.icon-calendar:before { content: "\E024" }
.icon-camera:before { content: "\E025" }
.icon-car:before { content: "\E026" }
.icon-check:before { content: "\E027" }
.icon-checkliste:before { content: "\E028" }
.icon-clock-halftime:before { content: "\E029" }
.icon-clock:before { content: "\E02A" }
.icon-close:before { content: "\E02B" }
.icon-dauer:before { content: "\E02C" }
.icon-difficulty-easy:before { content: "\E02D" }
.icon-difficulty-hard:before { content: "\E02E" }
.icon-difficulty-middle:before { content: "\E02F" }
.icon-dots:before { content: "\E030" }
.icon-download:before { content: "\E031" }
.icon-du:before { content: "\E032" }
.icon-elements:before { content: "\E033" }
.icon-familie:before { content: "\E034" }
.icon-filter-arrow:before { content: "\E035" }
.icon-filter:before { content: "\E036" }
.icon-fussball:before { content: "\E037" }
.icon-geschenk:before { content: "\E038" }
.icon-golmi:before { content: "\E039" }
.icon-hochalpinstrasse:before { content: "\E03A" }
.icon-hoehe-abwaerts:before { content: "\E03B" }
.icon-hoehe-aufwaerts:before { content: "\E03C" }
.icon-information:before { content: "\E03D" }
.icon-kartenansicht:before { content: "\E03E" }
.icon-klettern:before { content: "\E03F" }
.icon-kontakt:before { content: "\E040" }
.icon-kosten:before { content: "\E041" }
.icon-kulinarik:before { content: "\E042" }
.icon-liste:before { content: "\E043" }
.icon-location:before { content: "\E044" }
.icon-magazin-facebook:before { content: "\E045" }
.icon-magazin-instagram:before { content: "\E046" }
.icon-magazin-kalender:before { content: "\E047" }
.icon-magazin-twitter:before { content: "\E048" }
.icon-magazine:before { content: "\E049" }
.icon-mountain-arrow:before { content: "\E04A" }
.icon-mountain-down:before { content: "\E04B" }
.icon-mountain-filled:before { content: "\E04C" }
.icon-mountain-up:before { content: "\E04D" }
.icon-mountain:before { content: "\E04E" }
.icon-musik:before { content: "\E04F" }
.icon-newsletter:before { content: "\E050" }
.icon-orte-kultur:before { content: "\E051" }
.icon-partner:before { content: "\E052" }
.icon-pdf:before { content: "\E053" }
.icon-pen:before { content: "\E054" }
.icon-percent:before { content: "\E055" }
.icon-person:before { content: "\E056" }
.icon-phone:before { content: "\E057" }
.icon-pin-filled:before { content: "\E058" }
.icon-pin-outline:before { content: "\E059" }
.icon-pin:before { content: "\E05A" }
.icon-play:before { content: "\E05B" }
.icon-plus:before { content: "\E05C" }
.icon-raindrop:before { content: "\E05D" }
.icon-rodeln:before { content: "\E05E" }
.icon-schlechtwetter:before { content: "\E05F" }
.icon-schneeschuh:before { content: "\E060" }
.icon-search:before { content: "\E061" }
.icon-ski:before { content: "\E062" }
.icon-snowboard:before { content: "\E063" }
.icon-soc-facebook:before { content: "\E064" }
.icon-soc-flickr:before { content: "\E065" }
.icon-soc-foursquare:before { content: "\E066" }
.icon-soc-googleplus:before { content: "\E067" }
.icon-soc-instagram:before { content: "\E068" }
.icon-soc-linkedin:before { content: "\E069" }
.icon-soc-panoramio:before { content: "\E06A" }
.icon-soc-pinterest:before { content: "\E06B" }
.icon-soc-snapchat:before { content: "\E06C" }
.icon-soc-tiktok:before { content: "\E06D" }
.icon-soc-twitter:before { content: "\E06E" }
.icon-soc-whatsapp:before { content: "\E06F" }
.icon-soc-xing:before { content: "\E070" }
.icon-soc-youtube:before { content: "\E071" }
.icon-sommer-winter:before { content: "\E072" }
.icon-sommer:before { content: "\E073" }
.icon-strecke-simple:before { content: "\E074" }
.icon-strecke:before { content: "\E075" }
.icon-sunrise:before { content: "\E076" }
.icon-sunset:before { content: "\E077" }
.icon-tab-gallery:before { content: "\E078" }
.icon-themen:before { content: "\E079" }
.icon-top-events:before { content: "\E07A" }
.icon-train:before { content: "\E07B" }
.icon-unterkunft:before { content: "\E07C" }
.icon-video:before { content: "\E07D" }
.icon-wandern:before { content: "\E07E" }
.icon-webcam:before { content: "\E07F" }
.icon-webshop:before { content: "\E080" }
.icon-winter:before { content: "\E081" }
.icon-zoom:before { content: "\E082" }
.embed-responsive { width: 100%; }
.embed-responsive.embed-responsive-1by1 {
    padding-top: 100%;
}
.embed-responsive.embed-responsive-3by2 {
    padding-top: 66.66%;
}
.embed-responsive.embed-responsive-3by1 {
    padding-top: 33.33%;
}
.embed-responsive.embed-responsive-2by1 {
    padding-top: 50%;
}
.embed-responsive.embed-responsive-4by1 {
    padding-top: 25%;
}
.embed-responsive.embed-responsive-6by5 {
    padding-top: 125%;
}
.embed-responsive.embed-responsive-9by5 {
    padding-top: 55.55%;
}
.embed-responsive.embed-responsive-9by7 {
    padding-top: 77.7777%;
}
.embed-responsive.embed-responsive-10by3 {
    padding-top: 30%;
}
.embed-responsive.embed-responsive-10by4 {
    padding-top: 40%;
}
.embed-responsive.embed-responsive-25by12 {
    padding-top: 47.961%;
}
.embed-responsive.embed-responsive-2by3 {
    padding-top: 150%;
}
.embed-responsive.embed-responsive-tour-teaser {
    padding-top: 135%;
    padding-bottom: 0;
}
.embed-responsive.embed-responsive-video-portrait {
    padding-top: 177.777777%;
}
.embed-responsive.embed-responsive-img-teaser-vertical {
    padding-top: 207%;
}
.embed-responsive.embed-responsive-big {
    padding-top: 100%;
}
.embed-responsive.embed-responsive-image-teaser {
    padding-top: 118.75%;
}
.embed-responsive.embed-responsive-anreise-map {
    padding-top: 37.888%;
}
.embed-responsive.embed-responsive-intermaps-map {
    padding-top: 100%;
}
.embed-responsive.embed-responsive-16by9 {
    padding-top: 56.25%;
    padding-bottom: 0;
}
.embed-responsive.embed-responsive-img-text-slide {
    padding-top: 75%;
}
.embed-responsive.embed-responsive-text-teaser {
    padding-top: 100%;
}
.embed-responsive.embed-responsive-perfect-day-slider {
    padding-top: 137.1875%;
}
.embed-responsive-cover{
    height: 100%;
}
.embed-responsive-cover .embed-responsive-item{
    width: auto;
    min-height: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
}
.embed-responsive-article-category-top-img{
    padding-top: 50%;
}
.embed-responsive-article-category-bottom-img{
    padding-top: 48.5436%;
}
.btn {
    border-radius: 0;
    border: none;
    position: relative;
    padding: 8px 15px;
    font-family:SourceSansPro-Light, Arial;
    text-shadow: none;
    color: #323233;
    font-size: 15px;
    line-height: 15px;
}
.btn.btn-lg {
    font-family: SourceSansPro-Bold, Arial;
    padding: 12px 15px;

}
.btn.btn-xl{
    padding: 14px 30px;
}
.btn.btn-white{
    background: #fff;
    -webkit-transition: background 200ms ease;
    -o-transition: background 200ms ease;
    transition: background 200ms ease;
}
.btn.btn-white:hover {
    background: #eaeaea;
}
.btn.btn-primary{
    background: #d7001e;
    color: #fff;
    -webkit-transition: background 200ms ease;
    -o-transition: background 200ms ease;
    transition: background 200ms ease;
}
.btn.btn-primary:hover{
    background: #bd001a;
}
.btn.btn-no-style{
    background: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    border:none;
}
.btn.text-primary{
    color: #d7001e;
}
.btn.btn-green{
    background: #688816;
    color: #fff;
}
/*scroll top button*/
.btn-scroll-top {
    position: fixed;
    bottom: 15px;
    right: 15px;
    color: #fff;
    background: #d7001e;
    font-size: 11px;
    padding: 12px 10px 10px;
    -webkit-transform: translateY(200%);
        -ms-transform: translateY(200%);
            transform: translateY(200%);
    -webkit-transition: -webkit-transform 350ms cubic-bezier(.68,-.55,.27,1.55);
    transition: -webkit-transform 350ms cubic-bezier(.68,-.55,.27,1.55);
    -o-transition: transform 350ms cubic-bezier(.68,-.55,.27,1.55);
    transition: transform 350ms cubic-bezier(.68,-.55,.27,1.55);
    transition: transform 350ms cubic-bezier(.68,-.55,.27,1.55), -webkit-transform 350ms cubic-bezier(.68,-.55,.27,1.55);
    z-index: 100;
    width: 50px;
}
.btn-scroll-top.affix {
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
}
.btn-scroll-top:hover {
    background: #d7001e;
    color: #fff;
}
.btn.btn-webshop{
    text-transform: uppercase;
    font-family: Oswald-Regular, Arial;
    padding: 10px 20px 11px;
}
.btn-webshop .icon{
    margin-right: 9px;
    font-size: 18px;
    position: relative;
    top: 1px;
}
.btn-list>.btn:not(:last-child){
    margin-right: 10px;
}
.btn.btn-icon{
    padding-left: 48px;
}
.btn.btn-icon>.icon{
    font-size: 21px;
    position: absolute;
    left: 20px;
    top: 11px;
}
.btn.btn-icon-text{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 5px;
}
.btn.btn-icon-text .icon{
    font-size: 11px;
}
.btn-dark-grey{
    background-color: #58585a;
}
.btn.btn-dark{
    background-color: #393939;
    color: #fff;
}
.btn.btn-bold{
    font-family: Oswald-Regular, Arial;
    font-size: 20px;
    line-height: 1.45455;
}
.btn.btn-success{
    color: #fff;
}
.navbar-main{
    height: 50px;
    background: #fff;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
    margin-bottom: 0;

    position: relative;
    z-index: 999;
    left: 0;
    right: 0;
}
.navbar-main.navbar-microsite{
    height: 87px;
}
.navbar-main.affix{
    position: fixed;
    height: 82px;
}
.navbar-main.navbar-microsite.affix{
    height: 123px;
}
.container>.navbar-header{
    margin: 0;
    height: 50px;
}
.navbar-main .navbar-brand{
    float: none;
    padding: 0;
    height: 100%;
    display: inline-block;
    position: relative;
    z-index: 5;
}
.navbar-default .navbar-nav>li>a{
    color: #7d7d80;
    font-size: 17px;
    line-height: 1.44444;
    font-family: Oswald-Regular, Arial;
    text-align: center;
    padding: 24px 17px 17px;
    position: relative;
    -webkit-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.navbar-main.affix.navbar-default .navbar-nav>li>a{
    font-size: 16px;
    padding: 14px 17px;
}
.navbar-main.affix.navbar-default.navbar-no-icons .navbar-nav>li>a{
    padding: 32px 13px;
    font-size: 15px;
}
.navbar-default .navbar-nav li:hover,
.navbar-default .navbar-nav li:hover>a,
.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:hover,
.navbar-default .navbar-nav>.active>a:focus{
    background: transparent;
    color: #d7001e;
}
.navbar-nav .navbar-nav__dropdown{
    position: absolute;
    left:0;
    right: 0;
    top: 100%;
    padding: 40px 0;
    color: #323232;
    display: none;
}
.navbar-nav .navbar-nav__dropdown__cat{
    font-family: Oswald-Regular, Arial;
    font-size: 18px;
    margin-bottom: 10px;
}
.navbar-nav .navbar-nav__dropdown__desc{
    margin-bottom: 10px;
}
.navbar-nav .navbar-nav__dropdown ul{
    list-style: none;
    padding-left: 0;
}
.navbar-nav .navbar-nav__dropdown ul>li{
    position: relative;
    padding-left: 17px;
}
.navbar-nav .navbar-nav__dropdown ul>li:before{
    content: "\E01C";
    font-family: iconfont;
    font-size: 15px;
    position: absolute;
    left: 0;
    top: 7px;
}
/* Navbar right */
.navbar-right .expand-search-nav {
    position: relative;
    z-index:2;
    cursor: pointer;
    height: 40px;
    margin: 29px 0;
}
.affix .navbar-right .expand-search-nav{
    margin: 20px 0;
}
.navbar-right .expand-search-nav .expand-search__box {
    position: relative;
    width: 195px;
    height: 40px;
    right: 0;
    background-color: #e6e6e6;
    border: 1px solid #bec1c2;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 0;
    line-height: 23px;
    display: inline-block;
    padding-left: 10px;
    font-size: 14px;
}
.navbar-right{
    padding: 29px 0;
}
.navbar-right .navbar-search{
    position: relative;
    float: right;
}
.navbar-right .navbar-search input{
    padding-right: 45px;
}
.navbar-right .navbar-search-nav__btn{
    border: none;
    height: 40px;
    width: 40px;
    position: absolute;
    right: -1px;
    top: -1px;
    /*background: var(--color-primary);*/
    /*color: #fff;*/
}
.navbar-right .navbar-search-nav__btn>span.icon{
    position: relative;
    left: -7px;
}
.dropdown--lang{
    font-size: 18px;
    font-family: SourceSansPro-Bold, Arial;
    margin: 8px 20px 0 30px;
    float: right;
}
.dropdown--lang .dropdown-menu{
    width: auto;
    min-width: auto;
}
.lang-flag{
    background-image: url('/static/img/sprite-languages.png');
    background-repeat: no-repeat;
    display: inline-block;
    width: 18px;
    height: 12px;
    margin-left: 5px;
    background-size: cover;
}
.lang-flag.flag-de{ background-position: 0 0; }
.lang-flag.flag-fr{ background-position: 0 -12px; }
.lang-flag.flag-en{ background-position: 0 -23px; }
.lang-flag.flag-nl{ background-position: 0 -34px; }
.expand-lang .lang-flag{
    width: 35px;
    height: 21px;
    position: relative;
    top: 2px;
}
.expand-lang .lang-flag.flag-de{ background-position: 0 -2px; }
.expand-lang .lang-flag.flag-fr{ background-position: 0 -24px; }
.expand-lang .lang-flag.flag-en{ background-position: 0 -46px; }
.expand-lang .lang-flag.flag-nl{ background-position: 0 -69px; }
.top-bar{
    padding: 8px 0;
}
/*@media screen and (min-width: 1200px){*/
/*.navbar-main .container--nav,*/
/*.navbar-main .navbar-collapse{*/
/*height: 100px!important; !* overwrite bootstrap navbar-collapse style *!*/
/*}*/
/*}*/
.container-scrollspy{
    z-index: 7;
    margin-bottom: 40px;
}
.navbar-scrollspy{
    background: #d9dadb;
    border-radius: 0;
    border: 0;
    margin-bottom: 0;
    display: block;
}
.container-scrollspy.affix{
    position: fixed;
    top: 82px;
    background: #d9dadb;
}
.container-scrollspy.affix{
    margin-left: 0;
    padding-left:140px;
    width: 100%;
    max-width:1920px;
    left: 0;
}
.scrollspy-wrapper{
    position: relative;
    height: 60px;
    float: none;
    clear: both;
    margin-bottom: 50px;
}
.navbar-scrollspy .navbar-nav>li:hover>a:before,
.navbar-scrollspy .navbar-nav>li.is-open>a:before,
.navbar-scrollspy .navbar-nav>li>a:after{
    display: none;
}
.navbar-default.navbar-scrollspy .navbar-nav>li>a{
    padding: 14px 25px;
    font-size: 15px;
    border-right: 1px solid #fff;
}
.navbar-default.navbar-scrollspy .navbar-nav>li:last-child>a{
    border-right:0;
}
.navbar-default.navbar-scrollspy .navbar-nav.navbar-nav>li>a:hover,
.navbar-default.navbar-scrollspy .navbar-nav.navbar-nav>.active>a{
    color: #fff;
    background: #58585a;
    border-color:transparent;
}
.navbar-scrollspy .navbar-collapse{
    padding: 0 ;
}
.navbar-lp{
  position: fixed;
  top: 0;
  z-index: 5;
}
.navbar-lp-brand img{
  -webkit-transition: all .15s linear;
  -o-transition: all .15s linear;
  transition: all .15s linear;
  -webkit-transform-origin: top left;
      -ms-transform-origin: top left;
          transform-origin: top left;
}
.breadcrumb-wrapper,
.breadcrumb{
    background: #d9dadb;
    color: #6e6f70;
}
.breadcrumbs{
    margin-bottom: 0;
    display: inline-block;
}
.breadcrumb>.active{
    color: #080808;
}
.breadcrumb>li+li:before{
    content: "\E01E";
    font-family: iconfont;
    font-size: 8px;
    color: #6e6f70;
    margin-left: 10px;
    margin-right: 3px;
    top: -1px;
    position: relative;
}
.breadcrumb li a:hover{
    text-decoration: underline;
}
.console{
    background-color: #2b2c2c;
}
.footer{
    background-color: #f2f4f5;
}
.footer-border{
    border-bottom: 1px solid #d9dadb;
}
/* Footer Quicklinks */
.footer__quicklinks{
    padding: 35px 0 30px;
}
/* Footer Social */
.footer__social{
    padding: 30px 0;
}
.footer__social ul>li {
    margin: 0 5px;
}
.social-item{
    position: relative;
    height: 40px;
    display: block;
}
.social-item.d-ib{
    display: inline-block;
}
.social-item .social-text{
    padding-left: 48px;
    position: relative;
    top: -32px;
}
.circle-social{
    width: 40px;
    height: 40px;
    background-color: #969699;
    border: 2px solid #d9dadb;
    border-radius: 50%;
    position: relative;
    display: block;
    -webkit-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.social-item:hover .circle-social {
    background: #d7001e;
    border-color: #d7001e;
}
.circle-social .icon{
    position: absolute;
    font-size: 19px;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    display: inline-block;
    color: #fff;
}
.footer__partner a{
    margin-left: 30px;
    display: inline-block;
}
.footer__partner a:first-child{
    margin-left: 0;
}
.circle-social .icon.icon-soc-facebook{
    font-size: 19px;
}
.circle-social .icon.icon-soc-googleplus{
    font-size: 16px;
}
.circle-social .icon.icon-soc-instagram{
    font-size: 17px;
}
.circle-social .icon.icon-soc-flickr{
    font-size: 8px;
}
.circle-social .icon.icon-soc-twitter{
    font-size: 16px;
}
/* Footer Basic */
.footer__basic{
    padding: 20px 0;
}
.footer__basic .link-list{
    max-width: 400px;
}
.footer__basic .link-list>li{
    position: relative;
    padding: 4px 15px 4px 5px;
}
.footer__basic .link-list>li:last-child {
    padding-right: 5px;
}
.footer__basic .link-list>li:after {
    content: '//';
    position: absolute;
    right: -1px;
}
.footer__basic .link-list>li:last-child:after {
    content: '';
}
.footer__basic .link-list>li:hover a{
    color: #d7001e;
}
.footer__bottom{
    background-color: #7d7d80;
    color: #fff;
    padding: 9px 0;
}
.slide{
    padding: 30px 0;
    background: #fff;
}
.slide.slide--lg{
    padding: 60px 0;
}
.slide.slide--no-padding{
    padding: 0;
}
.slide.slide--no-padding-top{
    padding-top: 0;
}
.slide.slide--no-padding-bottom{
    padding-bottom: 0;
}
.slide.slide--padding-top{
    padding-top: 30px;
    padding-bottom: 0;
}
.slide.slide--grey{
    background: #f2f4f5;
}
.slide .decorated-hr{
    margin-top: 0;
}
.arrow-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    border: none;
    z-index: 1;
    width: 40px;
    background: rgba(0,0,0,0.2);
}
.arrow-wrapper.slick-disabled{
    display: none!important; /* overwrite inline style */
}
.arrow-wrapper.arrow-wrapper--portal{
    width: 148px;
}
.arrow-wrapper.arrow-wrapper--teaser{
    position: absolute;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    height: 60px;
    width: 40px;
    background-color: rgba(255, 255, 255, 0.6);
}
.arrow-wrapper.arrow-wrapper--teaser.arrow-wrapper--top-40{
    top: 40%;
}
.arrow-wrapper.arrow-wrapper--teaser.arrow-dark{
    background-color: rgba(0,0,0,.6);
}
.arrow-wrapper:focus{
    outline: none;
}
.arrow-wrapper.arrow-left {
    left: 0;
}
.arrow-wrapper.arrow-wrapper--portal.arrow-left{
    background: -webkit-gradient(linear,  left top, right top,  from(rgba(0,0,0,0.1)),to(rgba(0,0,0,0)));
    background: -webkit-linear-gradient(left,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(left,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to right,  rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.arrow-wrapper.arrow-right {
    right: 0;
}
.arrow-wrapper.arrow-wrapper--portal.arrow-right {
    background: -webkit-gradient(linear,  left top, right top,  from(rgba(0,0,0,0)),to(rgba(0,0,0,0.1)));
    background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
    background: -o-linear-gradient(left,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%);
    background: linear-gradient(to right,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.arrow-wrapper .icon {
    font-size: 20px;
    position: absolute;
    opacity: .7;
    color: #fff;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}
.arrow-wrapper.arrow-left .icon{
    left: 10px;
    -webkit-transform: translateY(-50%) rotate(180deg);
        -ms-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
}
.arrow-wrapper.arrow-right .icon{
    right: 10px;
}
.arrow-wrapper.arrow-wrapper--teaser.arrow-left .icon {
    left: 10px;
    margin-top: -2px;
}
.arrow-wrapper.arrow-wrapper--teaser.arrow-right .icon {
    right: 10px;
}
.arrow-wrapper.arrow-wrapper--portal.arrow-left .icon{
    left: 50px;
}
.arrow-wrapper.arrow-wrapper--portal.arrow-right .icon{
    right: 50px;
}
.arrow-wrapper:hover .icon{
    opacity: 1;
}
.arrow-wrapper:focus .icon{
    opacity: 1;
}
.slick-dots{
    position: relative;
    text-align: center;
}
.teaser-slider--white-dots .slick-dots li button {
    background:#fff;
}
.teaser-slider--white-dots .slick-dots li.slick-active button:before {
    background:#000;
}
.region-slider__container{
    padding: 30px 10px;
}
.portal-header-slider__item{
    position: relative;
    z-index: 4;
}
.portal-header-slider__item .slider-item__body{
    position: absolute;
    top: 35px;
    left:35px;
    right:35px;
    padding:0;

    z-index: 4;
    font-size: 18px;
    text-align: center;
}
.slider-item__logo {
    position: absolute;
    bottom: 25px;
    right:25px;
}
.slider-item__logo-img {
    display: block;
    width:auto;
    height:100px;
    -webkit-transform-origin: initial !important;
        -ms-transform-origin: initial !important;
            transform-origin: initial !important;
    -webkit-transform: translateX(0) scale(1) !important;
        -ms-transform: translateX(0) scale(1) !important;
            transform: translateX(0) scale(1) !important;
    -webkit-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}
.portal-header-slider__item .slider-item__body .link-primary{
    pointer-events: auto;
}
.portal-header-slider__item.text-white .slider-item__body {
    color: #fff;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
.portal-header-slider__item h1, .portal-header-slider__item .h1{
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 15px;
}
.portal-header-slider__item .link-primary:before {
    top: 4px;
}
.portal-header-slider__item.text-dark a{
    color: #d7001e;
}
.portal-header-video{
    position: relative;
}
.video-header__video{
    -o-object-fit: cover;
       object-fit: cover;
}
.content-header{
    position: relative;
}
.content-header:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
}
.content-header--no-overlay:before {
    display: none;
}
.content-header.content-header--overlay-bottom:before {
    top:auto;
    height:60%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,0.84)));
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.84) 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.84) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.84) 100%);
}
.content-header-image__body{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    width: 100%;
    z-index: 5;
}
.content-header-image__body--fullwidth-bottom{
    position: absolute;
    top: auto;
    bottom:20px;
    left: 0;
    right: 0;
    -webkit-transform: translateX(0) translateY(0);
        -ms-transform: translateX(0) translateY(0);
            transform: translateX(0) translateY(0);
}
.content-header-image__body h1,
.content-header-image__body h3,
.content-header-image__body .h1,
.content-header-image__body .h3{
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.content-header-image__body .h1{
    font-size: 38px;
}
.video-js{
    width: 100% !important; /* Overwrite inline styles */
    height: 100% !important;
}
.vjs-default-skin .vjs-big-play-button{
    width: 50px;
    height: 50px;
    background: #d7001e;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
    font-size: 17px;
    margin-top: -25px;
    margin-left: -25px;
}
.vjs-default-skin .vjs-big-play-button:before{
    content: "\E05B";
    font-family: iconfont;
    text-shadow: none;
    color: #fff;
    line-height: inherit;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    height: auto;
    left: 0;
}
.vjs-default-skin:hover .vjs-big-play-button,
.vjs-default-skin .vjs-big-play-button:focus{
    background: #d7001e;

}
.content-header .video-play,
.content-slider__item .video-play{
    font-size: 28px;
    height: 70px;
    width: 70px;
    background: #d7001e;
    color: #fff;
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}
.content-slider__item .video-play{
    width: 50px;
    height: 50px;
    font-size: 20px;
}
.content-header .video-play .icon,
.content-slider__item .video-play .icon{
    position: absolute;
    left: 54%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}
.content-header-video:hover .video-play,
.content-slider__item:hover .video-play{
    border-color: #fff;
    -webkit-box-shadow: 0 0 3em #fff;
            box-shadow: 0 0 3em #fff;
    -webkit-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
}
.video-wide .video-overlay,
.video-teaser .video-overlay{
    position: absolute;
    z-index: 6;
    top: 67%;
    left: 50%;
    font-size: 28px;
    -webkit-transform: translateY(-50%) translateX(-50%);
        -ms-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background: #d7001e;
}
.video-wide .video-overlay .icon,
.video-teaser .video-overlay .icon {
    position: relative;
    top: 22px;
    left: 3px;
    color: #fff;
}
.content-video-iframe-active .content-video__img{
    display: none;
}
.content-video-iframe-active .content-video-overlay{
    display: none;
}
.js-video-iframe-wrapper{
    cursor: pointer;
}
.content-video-overlay{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    z-index: 1;
    color: #fff;
    width: 70px;
    height: 70px;
    background-color: #d7001e;
    border-radius: 50%;
    -webkit-box-shadow: 0 0 1em rgba(0,0,0,.5);
            box-shadow: 0 0 1em rgba(0,0,0,.5);
    pointer-events: none;
    cursor: pointer;
}
.content-video-overlay .icon{
    color: #fff;
    position: absolute;
    font-size: 20px;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    margin-left: 2px;
}
.is-playing .video-overlay{
    display: none;
}
.is-playing .video-overlay-img{
    display: none;
}
.video-overlay-img{
    position: relative;
    z-index: 5;
}
.video-overlay{
    z-index: 6;
}
.mfp-content {
    max-width: 1300px;
}
.show-lightbox a{
    /*display: inline-block;*/
    position: relative;
}
.mfp-container{
    cursor: pointer;
}
.mfp-content img.mfp-img{
    padding: 0;
}
.mfp-bottom-bar{
    background: #fff;
    margin: 0;
    position: relative;
    padding: 10px 0;
}
.mfp-title{
    color: #323233;
    margin-top: 10px;
    text-transform: uppercase;
    text-align: center;
}
.mfp-title .lightbox-copyright{
    text-transform: none;
}
.mfp-close .close-wrapper .icon.mfp-close{
    width: auto;
    position: absolute;
    top: -45px;
    right: -38px;
    color: #000;
    cursor: pointer;
}
.mfp-partner .mfp-close .close-wrapper .icon.mfp-close{
    top: -20px;
    right: -20px;
}
.mfp-image-holder .mfp-content{
    border: 10px solid #fff;
    border-bottom: 0px solid #fff;
}
.mfp-figure:after{
    display: none;
}
.mfp-arrow{
    width: 25px;
    height: 35px;
    background-color: rgba(0,0,0,.6);
    cursor: pointer;
}
.mfp-arrow:after{
    display: none;
}
.mfp-arrow:before{
    border:none;
    color: #fff;
    margin: 0;
    font-size: 18px;
}
.mfp-arrow-left{
    left: 0;
}
.mfp-arrow-right{
    right: 0;
}
.mfp-arrow-left:before{
    left: 17px;
    top: 26px;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    opacity: 1;
}
.mfp-arrow-right:before{
    left: 8px;
    top: 10px;
    opacity: 1;
}
.teaser .teaser__img{
    position: relative;
}
.show-lightbox:not(.no-zoom):hover img,
.teaser:hover img {
    opacity: 0.85;
    -webkit-transform: scale3d(1.05,1.05,1);
            transform: scale3d(1.05,1.05,1);
}
.show-lightbox .img-hover__overlay,
.teaser .img-hover__overlay,
.show-lightbox .lightbox-icon,
.show-lightbox img,
.teaser img,
.show-lightbox:after,
.teaser .teaser__img:after{
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    -o-transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
}
.show-lightbox .img-hover__overlay{
    position: absolute;
    background: -webkit-gradient(linear,  left top, left bottom,  from(rgba(0,0,0,0)),to(rgba(0,0,0,0.25)));
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%);
    background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    -o-transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
.teaser .teaser__img:after{
    content: '';
    position: absolute;
    background: -webkit-gradient(linear,  left top, left bottom,  from(rgba(0,0,0,0)),to(rgba(0,0,0,0.25)));
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%);
    background: -o-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.25) 100%);
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, -webkit-transform 0.35s;
    -o-transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
.show-lightbox:hover .img-hover__overlay,
.teaser:hover .teaser__img:after{
    opacity: 1;
}
.show-lightbox .img-hover__overlay .lightbox-icon{
    color: #fff;
    font-size: 50px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
}
.mfp-iframe-holder .mfp-content{
    max-width: 1200px;
}
.decorated-hr {
    overflow: hidden;
    text-align: center;
    margin-bottom: 30px;
    -webkit-transform: translateZ(0);
            transform: translateZ(0);

    width: 280px;
    margin: 0 auto;
    color: #d9dadb;
    border-color: #d9dadb;
    margin-bottom: 20px;
    margin-top: 15px;
}
.is-primary.decorated-hr{
    color: #d7001e;
    border-color: #d7001e;
}
.decorated-hr__icon {
    position: relative;
    font-size: 30px;
    padding: 0 20px;
    line-height: 40px;
}
.decorated-hr__left, .decorated-hr__right {
    display: block;
    position: absolute;
    top: 50%;
    left: 70px;
    width: 1000px;
    height: 0;
    border-top: 1px solid;
    margin-top: -1px;
    border-color: #d9dadb;
}
.decorated-hr__right {
    left: auto;
    right: 100%;
}
.decorated-hr--mountain .decorated-hr__icon{ font-size: 26px; }
.decorated-hr--mountain .decorated-hr__left{ left: 90px; }
.decorated-hr--biken .decorated-hr__icon{ font-size: 28px; }
.decorated-hr--biken .decorated-hr__left{ left: 85px; }
.decorated-hr--hochalpinstrasse .decorated-hr__left{ left: 82px; }
.decorated-hr--location .decorated-hr__left{ left: 64px; }
.decorated-hr--newsletter .decorated-hr__icon{ font-size: 26px; }
.decorated-hr--newsletter .decorated-hr__left{ left: 77px; }
.decorated-hr--partner .decorated-hr__left{ left: 79px; }
.decorated-hr--unterkunft .decorated-hr__icon{ font-size: 28px; }
.decorated-hr--unterkunft .decorated-hr__left{ left: 85px; }
.decorated-hr.decorated-hr--golmi:before {
    content: '';
    width: 105px;
    height: 1px;
    background: #d9dadb;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}
.decorated-hr.decorated-hr--golmi:after {
    content: '';
    width: 105px;
    height: 1px;
    background: #d9dadb;
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}
.decorated-hr.decorated-hr--golmi img{
    height: 40px;
}
.main-content{
    padding-top: 40px;
    position: relative;
}
.main-header-content + .main-content {
    padding-top: 0;
}
.margin-helper-80{
    margin-top: -40px;
}
/*.page-heading{*/
/*margin-bottom: 20px;*/
/*}*/
/*.heading{*/
/*margin-bottom: -15px;*/
/*}*/
.page-heading:not(.text-left){
    text-align: center;
}
.page-heading .price-badge{
    padding: 7px 15px;
    background-image: -webkit-gradient(linear,left top, left bottom,color-stop(0, #ff0d2d),color-stop(51%, #d7001e),to(#ab0017));
    background-image: -webkit-linear-gradient(top,#ff0d2d 0,#d7001e 51%,#ab0017 100%);
    background-image: -o-linear-gradient(top,#ff0d2d 0,#d7001e 51%,#ab0017 100%);
    background-image: linear-gradient(180deg,#ff0d2d 0,#d7001e 51%,#ab0017 100%);
    color: #fff;
    border-radius: 0;
    font-size: 17px;
    font-weight: 300;
    z-index: 1;
    display: inline-block;
}
.page-heading .price-badge__price {
    font-size: 24px;
    line-height: 1.29167;
}
.overview-map__legend .icon{
    margin-right: 5px;
    font-size: 25px;
    position: relative;
    top: 2px;
}
.wysiwyg table{
    width: 100%;
}
.wysiwyg table td,
.wysiwyg table th{
    padding: 9px 10px;
}
.wysiwyg table td.valign-top,
.wysiwyg table th.valign-top{
    vertical-align: top;
}
.wysiwyg table>tbody>tr:nth-of-type(odd) {
    background-color: #f2f4f5;
}
.wysiwyg table.table-not-striped>tbody>tr:nth-of-type(odd) {
    background-color: transparent;
}
.wysiwyg table>thead>tr{
    background: #bec1c2;
}
.wysiwyg table>thead>tr>th{
    color: #fff;
    font-family: SourceSansPro-Bold, Arial;
    text-transform: uppercase;
}
.wysiwyg table.table-middle-border>thead>tr>th,
.wysiwyg table.table-middle-border>tbody>tr>th,
.wysiwyg table.table-middle-border>tfoot>tr>th,
.wysiwyg table.table-middle-border>thead>tr>td,
.wysiwyg table.table-middle-border>tbody>tr>td,
.wysiwyg table.table-middle-border>tfoot>tr>td {
    border-top:0;
}
.wysiwyg table.table-middle-border>tbody>tr>td{
    padding-left:0;
}
.wysiwyg table.table-middle-border>tbody>tr>td+td{
    border-left: 1px solid #ECF3F4;
    padding-left:22px;
}
.wysiwyg.table-responsive{
    border: none;
}
.content-accordion .panel-heading{
    padding: 0;
}
.content-accordion.panel-group .panel,
.content-accordion .panel-default>.panel-heading+.panel-collapse>.panel-body{
    border-radius: 0;
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.content-accordion .panel-title>a{
    display: block;
    padding: 10px;
    padding-right: 50px;
    font-family: SourceSansPro-Light, Arial;
    font-size: 18px;
    line-height: 1.38889;
    position: relative;
    background: #d9dadb;
}
.content-accordion .panel-title>a:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #d9dadb;
    left: 12px;
    bottom: -9px;
    z-index: 2;
}
.content-accordion .panel-title>a.collapsed:before {
    display: none;
}
.content-accordion .panel-title>a.collapsed{
    background: #f2f4f5;
}
.content-accordion .panel-title>a .accordion__close{
    position: absolute;
    right: 0;
    top:0;
    bottom:0;
    width: 45px;
    background: #d7001e;
    color: #fff;
}
.content-accordion .panel-title>a .accordion__close .icon{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
        -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
            transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.content-accordion .panel-title>a.collapsed .accordion__close .icon{
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
        -ms-transform: translateX(-50%) translateY(-50%) rotate(0deg);
            transform: translateX(-50%) translateY(-50%) rotate(0deg);
}
.anlagen-accordion .panel-default>.panel-heading,
.anlagen-accordion.panel-group .panel{
    padding: 0;
    border: none;
    background: #fff;
    -webkit-box-shadow: none;
            box-shadow: none;
    color: #323233;
}
.anlagen-accordion .panel-title{
    font-size: 22px;
    line-height:1.45455;
}
.anlagen-accordion .panel-title>a{
    display: block;
    padding: 18px 0 18px 30px;
    position: relative;
    -webkit-transition: color 250ms ease;
    -o-transition: color 250ms ease;
    transition: color 250ms ease;
    color: #d7001e;
}
.anlagen-accordion .panel-title>a.collapsed{
    color: #323233;
}
.anlagen-accordion .panel-default>.panel-heading:hover a{
    color: #d7001e;
}
.anlagen-accordion .panel-title>a>.icon{
    font-size: 10px;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(180deg);
        -ms-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg);
}
.anlagen-accordion .panel-title>a.collapsed>.icon{
    -webkit-transform: translateY(-50%) rotate(0deg);
        -ms-transform: translateY(-50%) rotate(0deg);
            transform: translateY(-50%) rotate(0deg);
}
.anlagen-accordion .panel-default .panel-title>a{
    border-top:1px solid #b1b3b4;
}
.anlagen-accordion .panel-default:first-child .panel-title>a{
    border-top: none;
}
.anlagen-accordion .panel-default:last-child .panel-title>a{
    border-bottom:1px solid #b1b3b4;

}
.anlagen-accordion .panel-default>.panel-heading+.panel-collapse>.panel-body{
    padding: 0;
    border:none;
    padding-bottom: 40px;
    margin: 0;
}
.anlagen-accordion.panel-group .panel-body{
    padding-bottom: 40px;
}
.anlagen-accordion.panel-group .panel+.panel{
    margin: 0;
}
.pois-accordion h4.panel-title>a {
    font-family: SourceSansPro-Bold, Arial;
    font-size: 17px;
}
.pois-accordion__id {
    color: #d7001e;
    display: inline-block;
    padding: 0 20px 0 10px;
}
.pois-accordion .panel-title>a .accordion__close .icon{
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
        -ms-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
            transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    color: #d7001e;
    -webkit-transition: -webkit-transform 500ms ease;
    transition: -webkit-transform 500ms ease;
    -o-transition: transform 500ms ease;
    transition: transform 500ms ease;
    transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.pois-accordion .panel-title>a.collapsed .accordion__close .icon{
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(90deg);
        -ms-transform: translateX(-50%) translateY(-50%) rotate(90deg);
            transform: translateX(-50%) translateY(-50%) rotate(90deg);
    color: #CCCECF;
}
.pois-accordion .panel-title>a .accordion__close{
    background: none;
}
.pois-accordion .panel-title>a, .pois-accordion .panel-title>a.collapsed {
    background: #F3F4F6;
}
.pois-accordion .panel-title>a{
    border-bottom: 2px solid #d7001e;
}
.pois-accordion .panel-title>a.collapsed {
    border-bottom: 2px solid #CCCECF;
}
.pois-accordion .panel-title>a:before {
    display: none;
}
.pois-accordion.panel-group .panel+.panel {
    margin-top: 0;
}
.content-slider__item{
    margin-right: 5px;
    position: relative;
}
.content-slider__item .slider-item__body{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)),to(rgba(0,0,0,0.5)));
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.5) 100%);
    color: #fff;
    padding: 10px;
    padding-top: 30px;
    text-align: center;
}
.content-slider__item .slider-item__body .icon{
    margin-right: 8px;
    position: relative;
    top: 2px;
}
.content-slider .arrow-wrapper.arrow-right{
    width: 44px;
}
.content-slider .slick-slide{
    height: auto;
}
.image-box{
    position: relative;
}
.image-box__body{
    position: absolute;
    top: 50%;
    left:50%;
    -webkit-transform: translateY(-50%) translateX(-50%) translateZ(0);
            transform: translateY(-50%) translateX(-50%) translateZ(0);
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px;

    width: 100%;
    max-width: 1500px;
}
.image-box__desc{
    margin: 15px 0;
}
.snippet-teaser-slide{
    position: relative;
}
.snippet-teaser-slide__body{
    width: 100%;
    max-width: 1470px;
    margin: 0 auto;
    padding: 0 15px;
}
.snippet-teaser-slide__desc{
    margin: 15px 0;
}
.snippet-teaser-slide .decorated-hr{
    margin-top: 0;
}
.image-teaser-slide{
    position: relative;
}
.image-teaser-slide:before,
.image-teaser-slide--video .video-wide a:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(50, 50, 51, 0.8);
    z-index: 1;
}
.image-teaser-slide.image-teaser-slide--video:before{
    display: none;
}
.image-teaser-slide__body{
    position: absolute;
    left:50%;
    top:50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
        -ms-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    padding: 0 20px;
    width: 100%;
    max-width: 1500px;

    z-index: 2;
}
.image-teaser-slide--video .image-teaser-slide__body{
    top: 25%;
}
.image-teaser-slide__desc{
    margin: 15px 0;
}
.image-teaser-slide__body h2{
    font-size: 40px;
    line-height: 1.225;
}
.text-teaser{
    position: relative;
    margin-bottom: 20px;
}
.text-teaser__body{
    padding: 20px;
}
.hasImg .text-teaser__body{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index:5;
}
.text-teaser__body h2{
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.text-teaser__desc{
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.text-teaser__body a{
     -webkit-box-flex: 0;
         -ms-flex-positive: 0;
             flex-grow: 0;
 }
.text-teaser--booking-benefits .text-teaser__desc ul{
    list-style: none;
    padding:0;
}
.text-teaser--booking-benefits .text-teaser__desc li{
    position: relative;
    padding-left: 25px;
    font-family: SourceSansPro-Bold, Arial;
    margin-bottom: 3px;
}
.text-teaser--booking-benefits .text-teaser__desc li:after{
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    border: 1px solid #688816;
    border-radius: 50%;
    left: 0;
    top: 4px;
}
.text-teaser--booking-benefits .text-teaser__desc li:before{
    content: "\E027";
    font-family: iconfont;
    position: absolute;
    left: 4px;
    top: 7px;
    color: #688816;
    font-size: 9px;
}
.img-text-slide__text{
    padding: 20px;
    font-size: 16px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.img-text-slide__text a{
    margin-top: 10px;
}
.text-right .img-text-slide__center{
    right: 20px;
}
.img-text-slide__body{
    position: absolute;
    bottom: 0;
    right: 0;
    text-shadow: 0 0 15px rgba(0, 0, 0, 0.8);
    z-index: 5;
}
.text-left .img-text-slide__body{
    right: auto;
    left:0;
}
.img-text-slide__body blockquote{
    border:none;
    margin: 0;
    padding: 20px;
}
.img-text-slide__body .quote .h2{
    font-size: 18px;
    line-height: 1.36364;
}
.img-text-slide__img{
    position: relative;
    overflow: hidden;
}
.img-text-slide__img:before{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 600px;
    background-image: -webkit-gradient(linear, left top, right top, from(transparent), to(rgba(0, 0, 0, 0.5)));
    background-image: -webkit-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: -o-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    z-index: 1;
}
.img-text-slide__img.text-left:before{
    right: auto;
    left: 0;
    background-image: -webkit-gradient(linear, right top, left top, from(transparent), to(rgba(0, 0, 0, 0.5)));
    background-image: -webkit-linear-gradient(right, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: -o-linear-gradient(right, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
    background-image: linear-gradient(to left, transparent 0%, rgba(0, 0, 0, 0.5) 100%);
}
.content-teaser{
    margin-bottom: 20px;
}
.content-teaser .h6{
    margin-top: 15px;
}
.teaser .badge{
    padding: 5px 15px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff0d2d), color-stop(51%, #d7001e), to(#ab0017));
    background-image: -webkit-linear-gradient(top, #ff0d2d 0%, #d7001e 51%, #ab0017 100%);
    background-image: -o-linear-gradient(top, #ff0d2d 0%, #d7001e 51%, #ab0017 100%);
    background-image: linear-gradient(180deg, #ff0d2d 0%, #d7001e 51%, #ab0017 100%);
    color: #fff;
    border-radius: 0;
    font-size: 17px;
    font-weight: 300;

    position: absolute;
    bottom: 20px;
    left: 0;
    z-index: 1;
}
.teaser .price-badge__price{
    font-size: 24px;
    line-height: 1.29167;
}
.teaser .badge.topevent-badge{
    padding: 13px 15px;
}
.teaser .difficulty-level{
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 5px 10px;
    z-index: 1;
}
.teaser .difficulty-level .circle-level{
    margin-left: 5px;
    position: relative;
    top: 4px;
}
.teaser .badge.event-badge{
    padding: 12px 15px;
}
.event-teaser{
    margin-bottom: 30px;
    position: relative;
}
.event-teaser.isTopevent{
    border: 2px solid #d7001e;
}
.event-teaser__body{
    padding: 10px 15px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    font-size: 15px;
}
.event-teaser__bottom{
    border-top: 1px solid #d9dadb;
    /*margin-top: 10px;*/
    padding: 10px 0;
    margin: 0 20px;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.event-teaser__img{
    -webkit-box-flex:0;
        -ms-flex-positive:0;
            flex-grow:0;
    position: relative;
}
.event-teaser__date {
    position: absolute;
    right:0;
    top:0;
    background: #fff;
    padding: 5px 10px;
    z-index: 1;
    display: inline-block;
}
.event-teaser__berge-plus{
    background-image: url('/static/img/bergeplus.png');
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    background-size: 70px;
    background-position-x: -36px;
    position: absolute;
    bottom: 0;
    right: 20px;
}
.event-teaser__berge-plus-premium{
    background-image: url('/static/img/bergeplus-premium.svg');
    background-repeat: no-repeat;
    background-repeat: no-repeat;
    width: 87px;
    height: 35px;
    background-size: 87px;
    position: absolute;
    bottom: 0;
    right: 20px;
}
.event-teaser__berge-plus + .event-teaser__berge-plus-premium {
    right: 62px;
}
.event-teaser__kinderlogo{
    width: 90px;
    height: 90px;
    display: inline-block;
    position: absolute;
    top: 0;
    right:0;
    padding:10px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+50,000000+100&0+50,0.5+100 */ /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(45deg, rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%);
    background: linear-gradient(45deg, rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.event-teaser__kinderlogo>img{
    width: auto;
    height: 35px;
    display: block;
    position: absolute;
    top: 45px;
    right:10px;
}
.storytelling-teaser{
    position: relative;
}
.storytelling-teaser__body{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    font-size: 15px;
    text-shadow: 0 0 7px rgba(0,0,0,.4);
}
.storytelling-teaser__body:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: -webkit-gradient(linear,left top, left bottom,color-stop(0, transparent),to(rgba(0,0,0,.5)));
    background: -webkit-linear-gradient(top,transparent 0,rgba(0,0,0,.5) 100%);
    background: -o-linear-gradient(top,transparent 0,rgba(0,0,0,.5) 100%);
    background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.5) 100%);
    z-index: 0;
    -webkit-transition: opacity 250ms ease;
    -o-transition: opacity 250ms ease;
    transition: opacity 250ms ease;
}
.storytelling-teaser:hover .storytelling-teaser__body:before {
    opacity: 0;
}
.storytelling-teaser__body__content{
    z-index: 1;
    position: relative;
}
.storytelling-teaser .list-categories>li{
    position: relative;
    padding-right: 14px;
}
.storytelling-teaser .list-categories>li:before{
    content: '/';
    position: absolute;
    right: 0;
}
.storytelling-teaser .list-categories>li:last-child:before{
    content: '';
}
.storytelling-teaser__img{
    position: relative;
}
.storytelling-teaser__date{
    font-size: 13px;
    line-height: 1.30769;
    width: 60px;
    height: 60px;
    background: #323233;
    color: #d9dadb;

    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    -webkit-transition: all 250ms ease;

    -o-transition: all 250ms ease;

    transition: all 250ms ease;
}
.storytelling-teaser:hover .storytelling-teaser__date {
    background: #d7001e;
    color: #fff;
}
/*.storytelling-teaser:hover .storytelling-teaser__body{*/
/*background: none;*/
/*transition: all 250ms ease;*/
/*}*/
.storytelling-teaser__date .date-day{
    font-size: 26px;
    line-height: 1.26923;
    margin-top: 4px;
}
.background-slide{
    /*height: 795px;*/
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}
.background-slide:before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.65);
}
.offer-teaser-slide .teaser-slider .teaser-slider__item,
.article-category-teaser-slide .teaser-slider .teaser-slider__item{
    width: 480px;
    /*margin: 0 15px;*/
}
.offer-teaser-slide.tour-teaser-slide .teaser-slider .teaser-slider__item{
    width: 358px;
}
.offer-teaser-slide .teaser-slider .teaser-slider__item--auto,
.article-category-teaser-slide .teaser-slider .teaser-slider__item--auto{
    width: auto;
}
.offer-teaser-slide__body{
    position: relative;
    z-index: 5;
}
.offer-teaser-slide__additional{
    font-size: 20px;
    line-height: 1.4;
    margin-top: 10px;
}
.offer-teaser__body{
    padding: 20px;
}
.offer-teaser__img{
    position: relative;
}
.offer-teaser__kinderlogo{
    width: 90px;
    height: 90px;
    display: inline-block;
    position: absolute;
    top: 0;
    right:0;
    padding:10px;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+50,000000+100&0+50,0.5+100 */ /* FF3.6-15 */
    background: -webkit-linear-gradient(45deg, rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%); /* Chrome10-25,Safari5.1-6 */
    background: -o-linear-gradient(45deg, rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%);
    background: linear-gradient(45deg, rgba(0,0,0,0) 50%,rgba(0,0,0,0.5) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#80000000',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.offer-teaser__kinderlogo>img{
    width: auto;
    height: 35px;
    display: block;
    position: absolute;
    top: 10px;
    right:10px;
}
.navbar-teaser{
    position: relative;
}
.navbar-teaser:before{
    content: '';
    position: absolute;
    top: 0;
    bottom:0;
    left:0;
    right: 0;
    background: rgba(0,0,0,0.4);
    z-index: 1;
    opacity: 1;
    -webkit-transition: opacity .35s,-webkit-transform .35s;
    transition: opacity .35s,-webkit-transform .35s;
    -o-transition: opacity .35s,transform .35s;
    transition: opacity .35s,transform .35s;
    transition: opacity .35s,transform .35s,-webkit-transform .35s;
}
.navbar-teaser:hover:before{
    opacity: 0;
}
.navbar-teaser__body{
    font-size: 19px;
    color: #fff;
    position: absolute;
    z-index: 2;
    text-align: center;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}
.list-sponsors{
    margin: 0;
}
.list-sponsors>li {
    padding: 0;
    margin: 20px;
}
.contact-teaser{
    max-width: 990px;
    margin: 0 auto;
    margin-bottom: 20px;
}
.content-teaser__img{
    position: relative;
    overflow: hidden;
}
.teaser.content-teaser--brochure .teaser__img:after{
    display: none;
}
.contact-slide{
    padding: 30px;
    max-width: 990px;
    margin: 0 auto;
}
.contact-slide--fullwidth{
    max-width: 1500px;
}
.icon-weather {
    height: 130px;
    width: 130px;
    display: inline-block;
    background-position: center;
}
.weather-teaser-simple .icon-weather{
    height: 90px;
    width: 90px;
}
.weather-teaser-simple{
    background: #e6e7e8;
    padding: 20px;
    padding-bottom: 70px;
    margin-bottom: 15px;
}
.weather-teaser-simple .status-wrapper{
    position: relative;
}
.weather-teaser-simple.isWeather .status-wrapper{
    text-align: left;
}
.weather-icon{
    display: inline-block;
}
.weather-temp{
    font-size: 30px;
    line-height: 1.33333;
    /*position: absolute;*/
    /*left: 60%;*/
    /*top: 50%;*/
    /*transform: translateY(-50%);*/
}
.avalanche-status{
    font-size: 22px;
    line-height: 1.18182;
}
.avalanche-img{
    width: 80px;
    margin: 10px auto;
}
.weather-teaser-simple a{
    position: absolute;
    bottom: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
}
.weather-teaser{
    background: #e6e7e8;
    padding: 20px;
}
.weather-item__temp{
    font-size: 28px;
    font-family: SourceSansPro-Bold, Arial;
    margin-left: 10px;
}
.weather-teaser--big .weather-data{
    padding: 0 5px;
}
.weather-teaser .weather-data>li{
    border-bottom: 1px solid #c5c6c7;
    padding: 6px 0;
}
.weather-teaser .weather-desc{
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.weather-teaser .weather-item{
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.avalanche-teaser{
    background: #f2f4f5;
    padding: 20px;
}
.avalanche-text{
    font-size: 18px;
}
.avalanche-text__status{
    font-size: 22px;
    font-family: Oswald-Regular, Arial;
    margin-left: 5px;
}
.weather-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.weather-info__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 5px 0;
}
.weather-info__item .weather-info__icon {
    margin-right: 8px;
    font-size: 20px;
}
.weather-info__item .weather-info__value {
    margin: 0 10px 0 5px;
}
/* ARRANGE STUFF */
.arrange {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: table;
    margin: 0;
    min-width: 100%;
    padding: 0;
    table-layout: auto;
}
.arrange-sizeFill,
.arrange-sizeFit {
    display: table-cell;
    padding: 0;
    vertical-align: top;
}
.arrange-sizeFill {
    width: 100%;
}
.arrange-sizeFill img {
    height: auto;
    max-width: 100%;
}
.arrange-sizeFit img {
    max-width: none !important;
    width: auto !important;
}
.arrange--middle .arrange-sizeFill,
.arrange--middle .arrange-sizeFit {
    vertical-align: middle;
}
.arrange--bottom .arrange-sizeFill,
.arrange--bottom .arrange-sizeFit {
    vertical-align: bottom;
}
.arrange--equal {
    table-layout: fixed;
    width: 100%; /* 1 */
}
.arrange--equal > .arrange-sizeFill,
.arrange--equal > .arrange-sizeFit {
    width: 1%;
}
.arrange--withGutter {
    margin: 0 -10px;
}
.arrange--withGutter > .arrange-sizeFit,
.arrange--withGutter > .arrange-sizeFill {
    padding: 0 10px;
}
.arrange--withGutter-5 {
    margin: 0 -5px;
}
.arrange--withGutter-5 > .arrange-sizeFit,
.arrange--withGutter-5 > .arrange-sizeFill {
    padding: 0 5px;
}
.arrange--vmiddle .arrange-sizeFit,
.arrange--vmiddle .arrange-sizeFill {
    vertical-align: middle;
}
.arrange--vbottom .arrange-sizeFit,
.arrange--vbottom .arrange-sizeFill {
    vertical-align: bottom;
}
.arrange--vtop .arrange-sizeFit,
.arrange--vtop .arrange-sizeFill {
    vertical-align: top;
}
.arrange-width--33{
    width: 33%;
}
.circle-status,
.circle-level{
    width: 18px;
    height: 18px;
    background: #000;
    border-radius: 50%;
    display: inline-block;
}
.circle-status.status-blue,
.circle-level.level-blue{
    background: #49c1f5;
}
.circle-status.status-red,
.circle-level.level-red{
    background: #d7001e;
}
.circle-status.status-black,
.circle-level.level-black{
    background: #000;
}
.circle-status.status-green,
.circle-level.level-green{
    background: #32c832;
}
.status-open{
    color: #32c832;
}
.status-close{
    color: #d7001e;
}
.route-status{
    width: 12px;
    height: 12px;
    background: #ffc800;
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    display: inline-block;
}
.table.table-status{
    margin-bottom: 0;
}
.table.table-status>thead>tr>th,
.table.table-status>tbody>tr>th,
.table.table-status>tfoot>tr>th,
.table.table-status>thead>tr>td,
.table.table-status>tbody>tr>td,
.table.table-status>tfoot>tr>td{
    vertical-align: middle;
    padding: 0 15px;
    border-top: none;
}
.table.table-status.table-status--anlagen>thead>tr>th,
.table.table-status.table-status--anlagen>tbody>tr>th,
.table.table-status.table-status--anlagen>tfoot>tr>th,
.table.table-status.table-status--anlagen>thead>tr>td,
.table.table-status.table-status--anlagen>tbody>tr>td,
.table.table-status.table-status--anlagen>tfoot>tr>td{
    padding: 8px 10px;
    font-size: 14px;
}
table.table-status>thead>tr{
    background: #bec1c2;
    color: #fff;
}
.table.table-status>thead>tr>th{
    padding: 8px 15px;
    text-transform: uppercase;
    border-bottom: none;
}
table.table-status>tbody>tr:nth-of-type(odd) {
    background-color: #f2f4f5;
}
.table-status .icon-lifte{
    font-size: 35px;
    margin-right: 5px;
    position: relative;
    top: 4px;
    left:-5px;
}
.table-status .circle-status{
    position: relative;
    top: 3px;
    margin-right: 7px;
}
.table-status .icon-status{
    font-size: 20px;
    margin-right: 7px;
    position: relative;
    top: 2px;
    width: 30px;
}
.table-status .route-status{
    margin-right: 9px;
    margin-left: 3px;
}
.table-status .trackname{
    position: relative;
    padding-left: 45px;
}
.table-status .trackname .icon{
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}
.status-slide{
    margin: 20px 0;
}
.status-slide .snow-value{
    font-size: 20px;
    line-height: 1.4;
}
.status-slide .status-slide__mountain{
    position: relative;
    margin-top: 40px;

    text-align: center;
}
.status-slide .mountain-figure .mountain-icon{
    color: #f0f1f2;
    font-size: 90px;
}
.console .status-slide .mountain-figure .mountain-icon{
    color: #d9dadb;
}
.status-slide .mountain-figure .snow-value{
    margin-left: 5px;
    position: relative;
    top: 2px;
}
.status-slide .mountain-figure__tal {
    position: absolute;
    bottom: 20px;
    left: -10px;
}
.status-slide .mountain-figure__tal .icon{
    display: block;
    text-align: right;
    margin-right: 20px;
    margin-top: 10px;
    font-size: 22px;
}
.status-slide .mountain-figure__mountain{
    position: absolute;
    top: 25px;
    right: 8px;
    width: 145px;
}
.status-slide .mountain-figure__mountain .icon{
    display: block;
    font-size: 22px;
    margin-top: 10px;
    position: absolute;
    top: -25px;
    left: -25px;
}
.regions-list{
    padding-bottom: 15px;
}
.nav-tabs.nav-tabs--primary{
    border-bottom: none;
}
.nav-tabs.nav-tabs--primary>li>a{
    background: #e6e7e8;
    color: #a0a2a3;
    font-family: SourceSansPro-Bold, Arial;
    border-radius: 0;
    margin: 0;
    border-right: 1px solid #cccecf;
    border-bottom: 1px solid transparent;
    position: relative;
}
.nav-tabs.nav-tabs--primary.nav-tabs--icons>li>a{
    padding-left: 45px;
}
.nav-tabs.nav-tabs--primary.nav-tabs--without-icon>li>a{
    padding-left: 15px;
}
.nav-tabs.nav-tabs--primary>li.active>a,
.nav-tabs.nav-tabs--primary>li.active>a:hover,
.nav-tabs.nav-tabs--primary>li.active>a:focus,
.nav-tabs.nav-tabs--primary>li>a:hover{
    border-color: transparent;
    background: #e6e7e8;
    border-bottom: 1px solid #d7001e;
    border-right: 1px solid #cccecf;

    color: #323233;
}
.nav-tabs.nav-tabs--primary>li:last-child>a,
.nav-tabs.nav-tabs--primary>li.active:last-child>a:hover{
    border-right: none;
}
.nav-tabs.nav-tabs--primary>li>a>.nav-tabs-icon {
    position: relative;
    top: 3px;
    left: -10px;
    font-size: 19px;
}
.nav-tabs.nav-tabs--primary>li>a>.nav-tabs-icon.icon-liste{
    font-size: 25px;
}
.nav-tabs.nav-tabs--primary>li>a.icon-bigger>.nav-tabs-icon {
    font-size: 22px;
}
.tab-content--primary {
    margin-top: 20px;
}
.pagination>li>a,
.pagination>li>span{
    border:none;
    color: #d7001e;
    text-decoration: underline;
    background: transparent;
    padding: 6px 13px;
}
.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>span:focus,
.pagination>.disabled>a,
.pagination>.disabled>a:hover,
.pagination>.disabled>a:focus{
    color: #ccc;
}
.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus{
    background: transparent;
    color: #323233;
    text-decoration: none;
}
.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus{
    background: transparent;
    color: #323233;
}
.pagination-arrow{
    position: relative;
    top: 3px;
}
.items-per-page a {
    width: 30px;
    height: 27px;
    display: inline-block;
    text-align: center;
    background: #d9dadb;
}
.items-per-page a.active,
.items-per-page a:hover{
    background: #d7001e;
    color: #fff;
}
.items-per-page>span{
    display: inline-block;
    margin-right: 10px;
}
.form-group{
    position: relative;
}
.form-control,
.form-control[readonly]{
    background: #fff;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
            box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
    border-radius: 0;
    border: none;
    height: 40px;
    font-size: 17px;
    padding: 8px 20px;
    -webkit-appearance: none;
}
.form-control:disabled {
    background-color: #f2f4f5;
}
.form-control.form-control--big{
    height: 50px;
}
.input-wrapper{
    position: relative;
}
.input-wrapper span.icon{
    position: absolute;
    right: 12px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #a0a2a3;
}
.checkbox-wrap input[type=checkbox] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
}
.checkbox-wrap label {
    padding-left: 27px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.checkbox-wrap label a{
    color: #d7001e;
}
.checkbox-wrap label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid #b9bbbd;
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
            box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
}
.checkbox-wrap input[type=checkbox]+label:after {
    content: "\E027";
    font-family: iconfont;
    position: absolute;
    left: 4px;
    top: 4px;
    width: 19px;
    height: 19px;
    font-size: 10px;
    color: #323233;
    opacity: 0;
}
.checkbox-wrap input[type=checkbox]:checked+label:after {
    opacity: 1;
}
.checkbox-wrap input[type=checkbox]:checked+label,
.checkbox-wrap input[type=checkbox]:focus+label{
    color: #d7001e;
}
.select{
    position: relative;
}
.select select::-ms-expand {
    display: none;
}
.select select {
    -moz-appearance: none;
    -webkit-appearance: none;
    font-size: 18px;
}
.select__icon {
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 8px;
    pointer-events: none;
}
.form-group--big .select__icon{
    right: 15px;
    top: 24px;
    font-size: 7px;
}
/*.has-error .parsley-errors-list {*/
/*padding: 0;*/
/*list-style: none;*/
/*position: absolute;*/
/*right: 15px;*/
/*top: -15px;*/
/*color: #fff;*/
/*background: #d7001e;*/
/*padding: 2px 10px;*/
/*transform: translateX(100%);*/
/*z-index: 1;*/
/*}*/
.has-error .parsley-errors-list {
    list-style: none;
    position: absolute;
    color: #fff;
    background: #d7001e;
    padding: 2px 10px;
    z-index: 1;
    margin-top: 10px;
    display: inline-block;
}
/*.has-error .select .parsley-errors-list{*/
/*top:-43px;*/
/*}*/
/*.has-error .parsley-errors-list:before {*/
/*content: '';*/
/*position: absolute;*/
/*width: 0;*/
/*height: 0;*/
/*border-left: 9px solid transparent;*/
/*border-right: 9px solid transparent;*/
/*border-top: 9px solid #d7001e;*/
/*left: 0;*/
/*bottom: -8px;*/
/*z-index: 1;*/
/*}*/
.has-error .parsley-errors-list:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #d7001e;
    left: 0;
    top: -8px;
    z-index: 1;
}
.has-error .form-control,
.has-error .checkbox-wrap label:before{
    border: 1px solid #d7001e;

}
.has-error .checkbox label {
    color: #323233;
}
input[type='number'] {
    -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
.datepicker{
    position: relative;
    cursor: pointer;
}
.datepicker input{
    cursor: pointer;
}
.datepicker__icon{
    position: absolute;
    right: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #a0a2a3;
    pointer-events: none;
    cursor: pointer;
}
#ui-datepicker-div{
    z-index: 6!important; /* overwrite datepicker inline style */
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover{
    border: 1px solid #a5a5a5;
    background: #d7001e;
    font-weight: 400;
    color: #fff;
}
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight{
    border: 1px solid #d7001e;
    background: #f6f6f6;
    color: #454545;
}
.ui-datepicker-next .ui-icon,
.ui-datepicker-prev .ui-icon {
    text-align: center;
}
.ui-datepicker-prev .ui-icon {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}
.ui-datepicker-next .ui-icon:before,
.ui-datepicker-prev .ui-icon:before {
    content: "\E01E";
    display: block;
    font-family: iconfont;
    speak: none;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transform: translate(0,0);
    -ms-transform: translate(0,0);
        transform: translate(0,0);
    text-indent: 0;
}
.filter-sidebar .label-hl{
    font-family: Oswald-Regular, Arial;
    font-size: 18px;
}
.filter-box__title{
    padding: 15px 0;
    border-bottom: 1px solid #d9dadb;
    position: relative;
    -webkit-transition: color 200ms ease;
    -o-transition: color 200ms ease;
    transition: color 200ms ease;
}
.filter-box__head:hover .filter-box__title{
    color: #d7001e;
}
.filter-box__collapse-icon{
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
        -ms-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg);
}
.filter-box__head.collapsed .filter-box__collapse-icon{
    -webkit-transform: translateY(-50%) rotate(0deg);
        -ms-transform: translateY(-50%) rotate(0deg);
            transform: translateY(-50%) rotate(0deg);
}
/* Filterbox Dropdown */
.filter-box--dropdown{
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
            box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
}
.filter-box--dropdown .filter-box__collapse-icon{
    -webkit-transform: translateY(-50%) rotate(-90deg);
        -ms-transform: translateY(-50%) rotate(-90deg);
            transform: translateY(-50%) rotate(-90deg);
    right: 15px;
    font-size: 14px;
}
.filter-box__head.collapsed .filter-box__collapse-icon{
    -webkit-transform: translateY(-50%) rotate(90deg);
        -ms-transform: translateY(-50%) rotate(90deg);
            transform: translateY(-50%) rotate(90deg);
}
.filter-box--dropdown .checkbox-wrap:last-child {
    margin-bottom: 0;
}
.filter-box--dropdown .checkbox-wrap:first-child {
    margin-top: 0;
}
.filter-box--dropdown .filter-box__title{
    margin: 0 7px;
    padding: 12px 10px;
}
.filter-box--dropdown .filter-box__body{
    padding: 15px;
}
.quick-filter{
    border: none;
    background: #fff;
    font-size: 15px;
    padding: 0 10px 0 0;
}
.quick-filter:hover span{
    color: #d7001e;
}
.quick-filter__icon{
    font-size: 11px;
}
/*Filter Sidebar Overlay*/
.filter-sidebar__overlay-toggle{
    display: none;
}
.filter-sidebar__overlay-close{
    display: none;
}
.image-pois{
    position: relative;
}
.img-poi {
    display: block;
    position: absolute;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    z-index: 1;
    color: #323233;
}
.img-poi:hover {
    color: #d7001e;
}
.img-poi__content {
    display: block;
    position: absolute;
    bottom: 28px;
    left: 50%;
    background: #fff;
    opacity: 0.9;
    white-space: nowrap;
    padding: 9px 15px;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-box-shadow: 0 0 5px 1px #bcbcbc;
            box-shadow: 0 0 5px 1px #bcbcbc;
}
.img-poi__content:after {
    content: '';
    height: 20px;
    width: 2px;
    position: absolute;
    background: #fff;
    bottom: -20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
}
.image-pois.tour .img-poi, .image-pois.tour .img-poi__content:after {
    background: #CCCECF;
}
.image-pois.tour .img-poi.poi-focused, .image-pois.tour .poi-focused .img-poi__content:after {
    background: #d7001e;
}
.poi-focused .img-poi__content {
    -webkit-box-shadow: 0 0 5px 1px #d7001e;
            box-shadow: 0 0 5px 1px #d7001e;
    font-family: SourceSansPro-Bold, Arial;
}
.poi-legend {
    margin: -5px 0 15px;
}
.poi-legend li {
    display: inline-block;
}
.poi-legend li:not(:last-child):after {
    content: "|";
    padding: 0 10px;
}
.routenplaner input{
    max-width: 480px;
    height: 41px;
    display: inline-block;
}
.routenplaner .btn{
    position: relative;
    top: -1px;
    margin-left: 30px;
}
.information-hint{
    width: 500px;
    background-color: #f2f4f5;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
            box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);

    position: fixed;
    top: 100px;
    right: 100px;
    bottom: 0;
    z-index: 110;

    overflow-y: auto;

}
.information-hint.information-hint--no-console{
    right: 0;
}
.information-hint.affix{
    top:82px;
}
.information-hint__body{
    padding: 70px 20px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
}
.information-hint__close{
    font-size: 25px;
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}
.information-hint .decorated-hr{
    width: 440px;
}
.information-hint__img{
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
}
.darkpage{
    background-color: rgba(0, 0, 0, 0.85);

    position: fixed;
    top:0;
    bottom:0;
    left:0;
    right: 0;
    z-index: 10;
}
.darkpage__close{
    position: absolute;
    right: 40px;
    top: 40px;
    font-size: 30px;
    cursor: pointer;
}
.darkpage__top{
    margin: 40px;
}
.darkpage__body{
    position: absolute;
    -webkit-transform: translateY(-50%) translateX(-50%);
        -ms-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    top: 50%;
    left: 50%;
    width: 100%;
}
.darkpage__desc{
    max-width: 912px;
    width: 100%;
    margin: 30px auto;
}
.darkpage__body a{
    margin-right: 40px;
    display: inline-block;
}
.partner__item{
    padding:20px;
    margin-bottom: 30px;
    background:#fff;
}
.partner-overlay ul>li{
    padding: 0 10px;
}
.mfp-partner .mfp-content {
    max-width: 1500px;
}
.mfp-partner{
    background: rgba(125,125,128,0.90);
}
.mfp-partner .lightbox__close-bar{
    position: fixed;
    top: 40px;
    right: 40px;
}
.mfp-partner .mfp-close{
    color: #fff;
    opacity:1;
    font-size: 35px;
    cursor: pointer;
}
.mfp-partner .mfp-close .close-wrapper .icon.mfp-close {
    color: #fff;
}
/*html.console-open,*/
/*html.console-open body{*/
/*overflow: hidden;*/
/*}*/
.expand-search .list-keyword{
    margin-left: 0;
}
.expand-search .list-keyword>li{
    padding: 0;
}
.expandable-block {
    position: relative;
    overflow: hidden;
}
.expandable-block.is-expandable {
    padding-bottom:2rem;
}
.expandable-block.is-collapsed:not(.is-not-expandable) {
    display: block;
    height: 150px;
}
.expandable-block.is-expandable.is-collapsed:after {
    content:'';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+62,1+100 */ /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 62%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0)),color-stop(62%, rgba(255,255,255,1)),to(rgba(255,255,255,1)));
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 62%,rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 62%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}
.expandable-block__more {
    display: none;
    padding:1rem 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    text-decoration: underline;
}
.expandable-block:not(.is-collapsed) .expandable__content {
    margin-bottom:25px;
}
.expandable-block.is-not-expandable .expandable-block__content,
.expandable-block.is-not-expandable {
    height: auto;
    overflow: visible;
}
.expandable-block.is-expandable .expandable-block__more {
    display: block;
}
.expandable-block.is-not-expandable .expandable-block__more {
    display: none;
}
.expandable-block.is-not-expandable:after {
    display: none;
    visibility: hidden;
}
.expandable-block.is-collapsed .expandable-block__more-less {
    display: none;
}
.expandable-block:not(.is-collapsed) .expandable-block__more-more {
    display: none;
}
.search-teaser{
    position: relative;
}
.search-teaser:before{
    content: '';
    position: absolute;
    left:0;
    right: 0;
    top:0;
    bottom:0;
    background: rgba(0,0,0,0.3);
    z-index: 2;
    -webkit-transition: background 200ms ease;
    -o-transition: background 200ms ease;
    transition: background 200ms ease;
}
.search-teaser:hover:before {
    background: rgba(0,0,0,.0);
}
.search-teaser__body{
    position: absolute;
    top: 50%;
    left:50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
        -ms-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    z-index: 3;
}
.search-teaser__body .h4{
    font-size: 24px;
    line-height: 1.5;
}
.webcam-slider__caption{
    background: #323233;
    padding: 8px 7px;
    font-size: 16px;
}
.webcam-slider--nav__item{
    position: relative;
}
.webcam-slider--nav__item.slick-current:before{
    content: '';
    position: absolute;
    top:0;
    bottom:0;
    left:10px;
    right: 10px;
    background: #d7001e;
    opacity: 0.5;
    z-index: 1;
}
.webcam-slider__item.slick-slide,
.webcam-slider--nav__item.slick-slide{
    height: auto;
}
.webcam-slider__item__img{
    position: relative;
}
.img-badge{
    position: absolute;
    right: 10px;
    top: 10px;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#ff0d2d), color-stop(51%, #d7001e), to(#ab0017));
    background-image: -webkit-linear-gradient(top, #ff0d2d 0%, #d7001e 51%, #ab0017 100%);
    background-image: -o-linear-gradient(top, #ff0d2d 0%, #d7001e 51%, #ab0017 100%);
    background-image: linear-gradient(180deg, #ff0d2d 0%, #d7001e 51%, #ab0017 100%);
}
.img-badge .img-badge__text{
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    font-size: 12px;
    line-height: 1.16667;
}
.arrow-wrapper.arrow-wrapper--webcam-big {
    top: 50%;
    bottom: auto;
    height: 60px;
    width: 40px;
    margin-top: -59px;
    background-color: rgba(0, 0, 0, 0.6);
}
.arrow-wrapper.arrow-wrapper--webcam-big .icon {
    font-size: 25px;

}
.arrow-wrapper.arrow-wrapper--webcam-big.arrow-left .icon {
    left: 11px;
}
.arrow-wrapper.arrow-wrapper--webcam-big.arrow-right .icon {
    right: 11px;
}
.arrow-wrapper.arrow-wrapper--webcam-nav{
    width: 28px;
    background-color: rgba(0, 0, 0, 0.6);
}
.arrow-wrapper.arrow-wrapper--webcam-nav.arrow-left{
    left: 10px;
}
.arrow-wrapper.arrow-wrapper--webcam-nav.arrow-right{
    right: 10px;
}
.arrow-wrapper.arrow-wrapper--webcam-nav .icon{
    font-size: 18px;
}
.arrow-wrapper.arrow-wrapper--webcam-nav.arrow-left .icon{
    left:8px;
}
.arrow-wrapper.arrow-wrapper--webcam-nav.arrow-right .icon{
    right:8px;
}
/* slick-vertical */
.slick-vertical .arrow-wrapper.arrow-wrapper--webcam-nav.arrow-left {
    display: block;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    height: 28px;
    width: 100%;
    text-align: center;
}
.slick-vertical .arrow-wrapper.arrow-wrapper--webcam-nav.arrow-left .icon {
    position: static;
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg);
}
.slick-vertical .arrow-wrapper.arrow-wrapper--webcam-nav.arrow-right {
    display: block;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: 28px;
    width: 100%;
    text-align: center;
}
.slick-vertical .arrow-wrapper.arrow-wrapper--webcam-nav.arrow-right .icon {
    position: static;
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
}
.slick-vertical .webcam-slider--nav__item.slick-current:before {
    left: 0;
    right: 0;
}
/*.google-map__marker>span.icon {*/
/*text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.75);*/
/*}*/
.google-map__marker-wrapper{
    overflow: visible!important; /* overwrite inlinestyle */
}
/* leaflet map */
.leaflet-touch .leaflet-bar, .leaflet-touch .leaflet-control-layers {
    border: 0;
}
.marker-cluster__count {
    position: absolute;
    top: 40%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    font-size: 15px;
    font-family: SourceSansPro-Bold, Arial;
    font-weight: 400;
    color: #fff;
}
.leaflet-div-icon {
    background: transparent;
    border: none;
}
.map__marker {
    font-size: 36px;
}
.is-active > .map__marker {
    color: #323233;
}
.leitprodukt-teaser__img{
    position: relative;
}
.leitprodukt-teaser__img__body{
    position: absolute;
    left: 50%;
    bottom:30px;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center;
}
.leitprodukt-teaser__img__body .h3{
    color: #fff;
}
.leitprodukt-teaser__body{
    padding: 15px;
}
.anreise-map__img{
    max-width: 1788px;
    margin-top: 40px;
}
.anreise-map .nav-tabs{
    position: relative;
    z-index: 1;
    margin-top: 30px;
}
.top-event,
.top-event-teaser{
    position: relative;
}
.top-event-teaser__body{
    padding: 18px 20px;
}
.top-event-teaser__icon{
    display: none;
}
.top-event-teaser__info{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 15px;
}
.perfect-day {
    position: relative;
}
.perfect-day__timeline {
    position: absolute;
    left: 50px;
    right: 50px;
    height: 3px;
    background-color: #d9dadb;
    bottom: 115px;
    z-index: 2;
    color: #d9dadb;
}
.perfect-day__timeline .is-left {
    position: absolute;
    left: 0;
    top: 5px;
}
.perfect-day__timeline .is-right {
    position: absolute;
    right: 0;
    top: 5px;
}
.perfect-day__body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.perfect-day__hl {
    font-size: 60px;
    line-height: 85px;
    margin-bottom: 25px;
    margin-top: 3%;
}
.perfect-day__info-box {
    position: absolute;
    bottom: 110px;
    left: 50px;
    z-index: 5;
}
.perfect-day__more-infos {
    position: absolute;
    left: auto;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    padding: 25px 0px 65px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 600px;
}
.perfect-day__info-box .show-more {
    background-image: -webkit-gradient(linear,left top, left bottom,color-stop(0, #ff0d2d),color-stop(51%, #d7001e),to(#ab0017));
    background-image: -webkit-linear-gradient(top,#ff0d2d 0,#d7001e 51%,#ab0017 100%);
    background-image: -o-linear-gradient(top,#ff0d2d 0,#d7001e 51%,#ab0017 100%);
    background-image: linear-gradient(180deg,#ff0d2d 0,#d7001e 51%,#ab0017 100%);
    color: #fff;
    font-family: Oswald-Regular, Arial;
    padding: 15px 30px;
    font-size: 20px;
    -webkit-transition: all 150ms ease;
    -o-transition: all 150ms ease;
    transition: all 150ms ease;
}
.perfect-day__info-box .show-more:hover{
    background: #bd001a;
}
.perfect-day__info-text {
    padding-right: 40px;
}
.perfect-day__links-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 30px;
}
.perfect-day__links-right .link-primary {
    padding: 10px 30px 10px 15px;
    background: rgba(255,255,255,0.4);
    white-space: nowrap;
}
.perfect-day__links-right .link-primary + .link-primary {
    margin-top: 10px;
}
.perfect-day__links-right .link-primary > span.icon {
    margin-right: 20px;
}
.perfect-day__links-bottom {
   margin-top: 10px;
}
.perfect-day__links-bottom .link-primary {
    margin-right: 20px;
}
.perfect-day__close-icon {
    position: absolute;
    right: 19px;
    top: 17px;
    font-size: 10px;
    cursor: pointer;
}
/* ---------- SLIDER DOTS -------------- */
.perfect-day-slider .slick-dots {
    bottom: 80px;
    z-index: 3;
    display: -webkit-box!important;
    display: -ms-flexbox!important;
    display: flex!important;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: -24px;
}
.perfect-day-slider .slick-dots li button {
    height: 17px;
    width: 17px;
    background: #fff;
}
.perfect-day-slider > .slick-dots li.slick-active button {
    background: #d7001e;
}
.perfect-day-slider > .slick-dots li button:before {
    content: '';
    position: absolute;
    bottom: 17px;
    left: 50%;
    top: auto;
    width: 2px;
    height: 32px;
    background: #d7001e;
    opacity: 0;
    border-radius: 0;
}
.perfect-day-slider > .slick-dots li.slick-active button:before {
    opacity: 1;
}
.perfect-day-slider .dots-wrapper {
    position: relative;
    width: 100px;
    cursor: pointer;
}
.perfect-day-slider > .slick-dots li .perfect-day__time {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    font-family: Oswald-Regular, Arial;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}
.perfect-day-slider > .slick-dots li.slick-active .perfect-day__time {
    color: #d7001e;
}
#perfect-day-map{
    visibility: hidden;
    height: 0;
}
#perfect-day-map.is-open {
    visibility: visible;
    height: auto;
}
/* --------- MOBILE ------ */
.google-map__infobox{
    background: #fff;
    padding: 10px 15px;
    width: 220px;
    text-align: center;
    position: relative;
}
.google-map__infobox .link-primary>span.icon{
    font-size: 13px;
}
.google-map__infobox:before{
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid #fff;
    left: 50%;
    margin-left: -9px;
    bottom: -7px;
    z-index: 10;
}
.google-map__infobox .h5{
    margin-top: 10px;
}
.google-map__infobox img{
    margin-top: 10px;
}
.single-facility-teaser{
    padding: 15px;
}
.single-facility-teaser__hl{
    margin-bottom: 20px;
}
.single-facility-teaser__hl .icon{
    margin-right: 5px;
}
.facility__item-hl{
    color: #b1b1b1;
    margin-bottom: 7px;
    font-size: 15px;
}
.facility-list>li{
    padding: 0 15px;
    border-left: 1px solid #b5b5b5;
    line-height: 1;
}
.facility-icon {
    position: relative;
    left: 5px;
    top: 1px;
    font-size: 12px;
}
/*row gutter*/
.row.row--gutter-width-0 {
    margin-left:0;
    margin-right:0;
}
.row.row--gutter-width-0>.col {
    padding-left:0;
    padding-right:0;
}
.row.row--gutter-width-4 {
    margin-left:-2px;
    margin-right:-2px;
}
.row.row--gutter-width-4>.col {
    padding-left:2px;
    padding-right:2px;
}
.row.row--gutter-width-10 {
    margin-left:-5px;
    margin-right:-5px;
}
.row.row--gutter-width-10>.col {
    padding-left:5px;
    padding-right:5px;
}
.row.row--gutter-width-15 {
    margin-left:-7.5px;
    margin-right:-7.5px;
}
.row.row--gutter-width-15>.col {
    padding-left: 7.5px;
    padding-right: 7.5px;
}
.row.row--gutter-width-20,
.row.row--gutter-width-20.slick-slider{
    margin-left:-10px;
    margin-right:-10px;
}
.row.row--gutter-width-20>.col,
.row.row--gutter-width-20.slick-slider .col {
    padding-left:10px;
    padding-right:10px;
}
.row.row--gutter-width-50 {
    margin-left:-25px;
    margin-right:-25px;
}
.row.row--gutter-width-50>.col {
    padding-left:25px;
    padding-right:25px;
}
.row.row--gutter-width-100 {
    margin-left:-50px;
    margin-right:-50px;
}
.row.row--gutter-width-100>.col {
    padding-left:50px;
    padding-right:50px;
}
.row--vertical-gutter-10 {
    margin-top: -10px;
    clear: both;
}
.row--vertical-gutter-10 > .col {
    margin-top: 10px;
}
.row--vertical-gutter-20 {
    margin-top: -20px;
    clear: both;
}
.row--vertical-gutter-20 > .col {
    margin-top: 20px;
}
.row--vertical-gutter-30 {
    margin-top: -20px;
    clear: both;
}
.row--vertical-gutter-30 > .col {
    margin-top: 20px;
}
.row--vertical-gutter-80 {
    margin-top: -50px;
    clear: both;
}
.row--vertical-gutter-80 > .col {
    margin-top: 50px;
}
/*Center cols*/
.row--inline-block {
    font-size: 0;
}
.row--inline-block> .col.text-right{
    text-align: right;
}
.row--inline-block>.col {
    display: inline-block;
    float: none;
    font-size: 17px;
    vertical-align: top;
    text-align: left;
}
.row--inline-block--middle>.col {
    vertical-align: middle;
}
.row--inline-block--bottom>.col {
    vertical-align: bottom;
}
.row--inline-block--middle.text-center>.col,
.row--inline-block--middle>.col.text-center{
    text-align: center;
}
/*same height cols*/
.row--same-height-all .row--same-height__item,
.row--same-height--all.slick-initialized .row--same-height__item.slick-slide {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
/*
 * the .row--same-height selector has to be after
 * .row--same-height__item to increase the specificity
 * in case of chaining the two selectors
 * (which is needed for nested rows)
 */
.row--same-height-all{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.row--same-height-all .slick-track{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
}
.row--same-height-all:before,
.row--same-height-all:after{
    display: none;
}
.row--same-height-all.overflow-visible{
    overflow: visible;
}
.row--same-height-all > .col,
.row--same-height-all.slick-slider .col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
}
.row--flex-center{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.row.row--tour-overview {
    margin-top: -10px;
    clear: both;
    margin-left:-5px;
    margin-right:-5px;
}
.row--tour-overview > .col {
    margin-top: 10px;
    padding-left:5px;
    padding-right:5px;
}
.infobox {
    max-width: 990px;
    margin: 0 auto;
    margin-bottom: 20px;
    padding: 15px;
}
.infobox__body__content{
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
}
.infobox__body__block{
    margin-bottom: 10px;
}
.infobox__body__block:last-child{
    margin-bottom: 0;
}
.countdown-slide__body{
    position: relative;
    z-index: 2;
}
.flip-clock-wrapper{
    margin: 0 auto;
    max-width: 600px;
    margin-top: 30px;
    margin-bottom: 30px;
}
.flip-clock-wrapper ul{
    width: 58px;
    height: 69px;
}
.flip-clock-wrapper ul li{
    line-height: 73px;
    font-family: Oswald-Regular, Arial;
}
.flip-clock-wrapper ul li a div div.inn{
    font-size: 55px;
    background-color: #2b2c2c;
}
.flip-clock-wrapper ul li a div.up:after{
    top: 34px;
    background-color: rgba(0, 0, 0, 0.18);
}
.flip-clock-dot{
    visibility: hidden;
}
.flip-clock-divider .flip-clock-label{
    color: #fff;
    font-size: 20px;
    font-family: Oswald-Regular;
    position: relative;
    left:27px;
    top:88px;
}
.flip-clock-divider.days .flip-clock-label{
    left:6px;
}
.oax-part-flexviewpage .oax-flexView,
.oax-singleviewpage{
    position: relative;
    z-index: 2;
}
#myflexview .oax .oax_open_filter .oax-view-controls-box.oax_fix_view_controls{
    position: static;
    top: auto;
}
#myflexview .oax-part-flexviewpage {
    z-index: auto;
}
.infomax-quickfinder-form .btn-remove-room .icon{
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(45deg);
        -ms-transform: translateY(-50%) rotate(45deg);
            transform: translateY(-50%) rotate(45deg);
    left: 0;
}
.infomax-quickfinder-form .btn-add-room .icon{
    position: absolute;
    top: 30%;
    left: 0;
    font-size: 0.9em;
}
.infomax-quickfinder-form .btn-add-room {
    color: #688816;
}
.row--child-ages{
    display: none;
}
.infomax-quickfinder-form .form-group--big .select select{
    font-size: 16px;
}
.formularbuilder{
  max-width: 1000px;
  width: 100%;
}
.formularbuilder legend{
  font-family: SourceSansPro-Bold, Arial;
  padding-top: 30px;
  display: block;
  border-color: #b5b5b5;
}
.formularbuilder select.form-control{
  -webkit-appearance: menulist;

}
.social-sharing__wrapper {
  height:77px;
  width: 231px;
}
.shariff .orientation-horizontal li a, .shariff .orientation-horizontal li a:hover {
  color:#fff;
  width: 100%;
  height: 100%;
  display: inline-block;
  float: left;
}
.shariff .orientation-horizontal li {
  width: 37px;
  height: 37px;
  margin-right:4px;

}
.shariff li, .shariff li a {
  height: 37px;
  line-height: 37px;
  text-align: center;
  display:inline-block;
}
.shariff .facebook .fa-facebook-f, .shariff .flattr .fa-money-bill-alt, .shariff .flipboard .fa-flipboard, .shariff .linkedin .fa-linkedin-in {
  font-size: 21px;
}
.shariff li .fab, .shariff li .far, .shariff li .fas {
  width: 37px;
  line-height: 37px;
  margin-top:0;
  vertical-align: initial;
}
.shariff ul {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
}
.shariff-button .fab, .shariff-button .fas {
  display: inline-block;
  font-family: iconfont;
  speak: none;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transform: translate(0);
      -ms-transform: translate(0);
          transform: translate(0);
}
.shariff-button.facebook .fab:before {
  content:"\E064";
}
.shariff-button.whatsapp .fab:before {
  content:"\E06F";
}
.shariff-button.mail .fas:before {
  content:"\E050";
}
.shariff-button.addthis .fas:before {
  content:"\E05C";
}
.shariff .facebook > a {
  background: #3b5998;
}
.shariff .facebook > a:hover {
  background: #4273c8;
}
.shariff .whatsapp > a {
  background: #4dc247;
}
.shariff .whatsapp > a:hover {
  background: #32c228;
}
.shariff .mail > a {
  background: #585858;
}
.shariff .mail > a:hover {
  background: #686868;
}
.shariff .addthis > a {
  background: #ff6550;
}
.shariff .addthis > a:hover {
  background: #ff5b34;
}
.breadcrumb-wrapper .shariff{
  position: relative;
  top: 9px;
}
.head-sujet{
  position: relative;
}
.head-sujet__video .vjs-poster:before,
.head-sujet__img:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,0.2)));
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
  background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 100%);
  z-index: 1;
}
.head-sujet__content{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 33px;
  padding: 30px 30px 0;
  color: #fff;
  text-align: center;
  z-index: 2;
  text-shadow: 0 0 10px rgba(0,0,0,0.5);
  pointer-events: none;
}
.head-sujet__hl{
  font-size: 30px;
  line-height: 1.0625;
  font-family:Oswald-Regular, Arial;
}
.head-sujet__text{
  font-size: 20px;
  margin-top: 20px;
}
.head-sujet__video-play{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: #d7001e;
  color: #fff;
  font-size: 20px;
  position: relative;
  -webkit-transition: all 150ms ease;
  -o-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-bottom: 20px;
}
.head-sujet__video-play:hover{
  outline: 0;
  border-color: #fff;
  -webkit-box-shadow: 0 0 3em #fff;
          box-shadow: 0 0 3em #fff;
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}
.head-sujet__video-play .icon{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  margin-left: 2px;
}
.head-sujet__video .vjs-big-play-button{
  display: none;
}
.head-sujet__video.hide-content .head-sujet__video-play{
  display: none;
}
.head-sujet__btn{
  position: absolute;
  bottom: -56px;
  top: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
}
.headline-block{
  margin: 30px 0 30px;
}
.img-gallery-sujet{
  margin: 50px 0 60px;
}
.img-gallery-sujet .video-play{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: #d7001e;
  color: #fff;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  -webkit-transition: all 150ms ease;
  -o-transition: all 150ms ease;
  transition: all 150ms ease;
}
.img-gallery-sujet .show-lightbox:hover .video-play{
  outline: 0;
  border-color: #fff;
  -webkit-box-shadow: 0 0 3em #fff;
          box-shadow: 0 0 3em #fff;
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}
.img-gallery-sujet .video-play .icon{
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  margin-left: 2px;
}
.accommodation-teaser__img{
  position: relative;
}
.accommodation-teaser .badge {
  font-size: 18px;
  text-transform: uppercase;
  font-family: SourceSansPro-Bold, Arial;
  padding: 6px 15px 8px;
  top: 10px;
  bottom: auto;
  left: -12px;
}
.accommodation-teaser__body{
  padding: 20px 30px;
}
.accommodation-teaser hr{
  border-color: #CFCFD4;
}
.accommodation-teaser__price{
  font-family: Oswald-Regular, Arial;
  font-size: 22px;
  margin: 20px 0 15px
}
.accommodation-teaser__price-info{
  font-size: 14px;
  white-space: nowrap;
  margin-bottom: 15px;
  display: block;
}
.btn.accommodation-teaser__btn{
  width: 100%;
  padding: 12px 15px;
  background-color: #57a53b;
  color: #fff;
}
.btn.accommodation-teaser__btn:hover{
  background: #4d9838;
}
.is-added .accommodation-teaser__btn{
  background-color: #58585a;
}
.teaser-stars{
  background-image: url(/static/img/sprites/stars-sprite.png);
  background-repeat: no-repeat;
  width: 86px;
  height: 14px;
  display: inline-block;
  margin-left: 5px;
}
.teaser-stars.superior{
  width: 100px;
}
.teaser-stars.stars-1{
  background-position-y: -112px;
}
.teaser-stars.stars-2{
  background-position-y: -84px;
}
.teaser-stars.stars-3{
  background-position-y: -56px;

}
.teaser-stars.stars-4{
  background-position-y: -28px;
}
.teaser-stars.stars-5{
  background-position-y: 0;
}
.teaser-blumen{
  background-image: url(/static/img/sprites/blumen-sprite.png);
  background-repeat: no-repeat;
  width: 96px;
  height: 18px;
  display: inline-block;
  margin-left: 5px;
}
.teaser-blumen.stars-1{
  width: 20px;
}
.teaser-blumen.stars-2{
  width: 39px;
}
.teaser-blumen.stars-3{
  width: 57px;
}
.teaser-blumen.stars-4{
  width: 78px;
}
.teaser-blumen.stars-5{
  width: 96px;
}
.teaser-edelweis{
  background-image: url(/static/img/sprites/edelweis-sprite.png);
  background-repeat: no-repeat;
  width: 96px;
  height: 18px;
  display: inline-block;
  margin-left: 5px;
}
.teaser-edelweis.stars-1{
  width: 21px;
}
.teaser-edelweis.stars-2{
  width: 40px;
}
.teaser-edelweis.stars-3{
  width: 58px;
}
.teaser-edelweis.stars-4{
  width: 80px;
}
.teaser-edelweis.stars-5{
  width: 98px;
}
.accommodation-teaser__info{
  margin-bottom: 15px;
}
.acco-console{
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  color: #fff;
  z-index: 5;
  width: 100%;
  -webkit-transform: translateX(-100%) ;
      -ms-transform: translateX(-100%) ;
          transform: translateX(-100%) ;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  -o-transition: transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.acco-console.is-open {
  -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
          transform: translateX(0);
}
.acco-console__nav {
  position: absolute;
  top: 50%;
  left: 100%;
  -webkit-transform: rotate(90deg) translateY(-50%) translateX(-50%);
      -ms-transform: rotate(90deg) translateY(-50%) translateX(-50%);
          transform: rotate(90deg) translateY(-50%) translateX(-50%);
  -webkit-transform-origin: left;
      -ms-transform-origin: left;
          transform-origin: left;
}
.acco-console__nav .btn{
  color: #fff;
  padding: 7px 10px;
  font-size: 13px;
}
.acco-console__nav .console__item{
  padding: 10px 5px;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none;
  width: 100%;
  background: transparent;
  font-size: 16px;
}
/*
.acco-console__nav .console__item.is-open{
  background: #95c11f;
  background: linear-gradient(to bottom,#95c11f 0,#779a19 100%);
  color: #fff;
}
*/
.acco-console__content {
  position: absolute;
  left: 0;
  top: 50px;
  right: 0;
  bottom: 0;
  background: #f2f4f5;
  z-index: 4;
  color: #323233;
  padding: 20px;
  overflow-y: scroll;
}
.acco-console__content::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}
.acco-console__content::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background-color: #a3a3a3;
}
.acco-console__content::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 15px;
}
.acco-console__content .close-console{
  font-size: 30px;
  color: #7d7d80;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}
.acco-console hr{
  border-color: #D9DADB;
  margin: 35px 0;
}
.acco-console__item{
  background: #d7001e;
}
@-webkit-keyframes flash {
  50%, to {
    background: #58585a;
  }

  25%, 75% {
    background: #d7001e;

  }
}
@keyframes flash {
  50%, to {
    background: #58585a;
  }

  25%, 75% {
    background: #d7001e;

  }
}
.bg-flash {
  -webkit-animation-name: flash;
          animation-name: flash;

  -webkit-animation-duration: 1.5s;

          animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}
.request-teaser+.request-teaser{
  margin-top: 20px;
}
.request-teaser__body {
  padding: 15px 22px;
}
.btn.btn-remove-request{
  font-size: 15px;
  padding: 6px 12px 9px;
  line-height: 1;
  padding-left: 28px;
}
.btn-remove-request .icon{
  position: absolute;
  left: 10px;
  font-size: 13px;
  top: 8px;
}
.required-message{
  color: #A0A1A3;
  font-style: italic;
  margin: 20px 0;
}
.request-teaser .teaser-stars,
.request-teaser .teaser-blumen,
.request-teaser .teaser-edelweis{
  display: block;
  margin-bottom: 13px;
}
.request-teaser__title{
  margin-bottom: 6px;
}
.header-img-text-block__text-block{
  position: relative;
  z-index: 2;
}
.header-img-text-block__text-block.header-img{
  margin-top: -100px;
}
.header-img-text-block__text-block .headline-block{
  background-color: #fff;
  padding: 30px 20px;
  margin: 0;
}
.header-top-bar{
  background-color: #d7001e;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 4;
  text-align: center;
  padding: 7px;
  font-size: 15px;
  font-family: SourceSansPro-Bold, Arial;
}
.cookie-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #212529;
    color: #ffffff;
    padding: .75rem 1rem;
    z-index: 200;
}
.cookie-bar__text {
    display: inline;
}
.cookie-bar__detail,
a.cookie-bar__detail {
    display: inline;
}
.cookie-bar__detail-btn {
    display: inline;
    text-decoration: underline;
    vertical-align: inherit;
    cursor: pointer;
    margin-right: .25em;
    color: #fff;
    background-color: transparent;
}
.cookie-bar__detail-btn:hover,
.cookie-bar__detail-btn:focus{
    color: #fff;
}
.cookie-bar__buttons {
    margin-top: .75rem;
    text-align: right;
}
.cookie-bar__accept {
    margin-left: .75rem;
}
/* modal */
.cookie-modal__item {
    margin-bottom: .75rem;
}
.cookie-modal__item--all {
    margin-bottom: 1rem;
}
.cookie-modal__label {
    font-size: 1.5rem;
    /*font-weight: bold;*/
}
.cookie-modal__link {
    display: inline-block;
    margin-left: .5rem;
    text-decoration: underline;
}
/* switch */
.cookie-modal__switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    vertical-align: middle;
    margin-right: .5rem;
}
.cookie-modal__switch-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}
.cookie-modal__switch-toggle:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    border-radius: 34px;
}
.cookie-modal .modal-header .close {
    position: absolute;
    right: 15px;
    top: 15px;
}
input:checked + .cookie-modal__switch-toggle {
    background-color: #d7001e;
}
input:focus + .cookie-modal__switch-toggle {
    -webkit-box-shadow: 0 0 2px #d7001e;
            box-shadow: 0 0 2px #d7001e;
}
input:checked + .cookie-modal__switch-toggle:before {
    -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
            transform: translateX(26px);
}
[hidden]{
    display: none!important;
}
.twitter-typeahead{
  width: 100%;
  height: 100%;
}
.tt-menu {
  right: 0;
  background-color: #fff;
  color: #323233;
  border: 1px solid #C2C2C2;
  -webkit-box-shadow: 0 6px 12px 0 rgba(0,0,0,0.3);
          box-shadow: 0 6px 12px 0 rgba(0,0,0,0.3);
  padding: 12px;
  z-index: 900;
  min-width: auto;
  width: 100%;
}
.tt-dataset:not(:last-child){
  margin-bottom: 20px;
}
.tt-dataset-heading__hl{
  font-size: 12px;
  text-transform: uppercase;
  line-height: 16px;
}
.tt-dataset-heading__hl:hover{
  color: #d7001e;
}
.tt-suggestion{
  font-size: 17px;
  line-height: 24px;
  font-family: SourceSansPro-Light, Arial;
  cursor: pointer;
  padding: 6px 15px;
}
.tt-suggestion.tt-cursor{
  color: #d7001e;
}
.tt-suggestion:hover{
  background: #efefef;
}
.tt-suggestion mark{
  background: none;
  padding: 0;
  color: #d7001e;
  font-family: SourceSansPro-Light, Arial;
  font-weight: 400;
}
.tt-dataset-heading-icon{
  font-size: 16px;
  margin-right: 8px;
  vertical-align: -3px;
}
.badge.badge-luenersee{
    background-color: #004f95;
    color: #fff;
}
.badge.badge-brandnertal-card{
    background-color: #d7001e;
    color: #fff;
}
.badge.badge-silvretta{
    background-color: #004f95;
    color: #fff;
}
.badge.badge-gargellen{
    background-color: #0062A7;
    color: #fff;
}
.badge.badge-golm{
    background-color: #004f95;
    color: #fff;
}
.contact-person-area{
    margin-top: 30px;
}
.contact-person-teaser{
    position: relative;
    padding-bottom: 15px;
    font-size: 14px;

    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.contact-person-teaser:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 80px;
    background-color: #f2f4f5;
    z-index: -1;
}
.contact-person-teaser__img{
    max-width: 225px;
    width: 100%;
    margin: 0 auto;
}
.contact-person-teaser__img img{
    border-radius: 50%;
}
.contact-person-teaser__body{
    margin-top: 15px;
    padding: 0 20px;
}
.contact-person-teaser__title{
    font-size: 20px;
    line-height: 1.48;
    font-family: Oswald-Regular, Arial;
    margin-bottom: 8px;
}
.contact-person-teaser__contact{
    margin-top: 20px;
    font-size: 16px;
}
.contact-person-teaser__contact .icon-newsletter{
    font-size: 14px;
    vertical-align: -2px;
    margin-right: 4px;
}
.contact-person-teaser__contact .icon-phone{
    vertical-align: -3px;
    margin-right: 4px;
    font-size: 18px;
}
.contact-person-teaser__quote{
    font-family: Oswald-Regular, Arial;
    font-size: 16px;
    line-height: 1.5;
    margin-top: 15px;
    color: #7d7d80;
}
.contact-person-teaser__link{
    padding: 0 15px;
    margin-top: auto;

}
.contact-person-teaser__link a{
    display: inline-block;
    margin-top: 25px;
}
.brand-person-area{
    margin-top: 30px;
}
.brand-person-teaser{
    position: relative;
    padding-bottom: 15px;
}
.brand-person-teaser__img img{
    border-radius: 50%;
}
.brand-person-teaser__body{
    margin-top: 15px;
    padding: 0 3px;
}
.brand-person-teaser__title{
    font-size: 18px;
    line-height: 1.56;
    font-family: Oswald-Regular, Arial;
    margin-top: 7px;
}
.brand-person-teaser__info{
    font-size: 13px;
}
.brand-person-teaser__link{
    padding: 0 15px;
}
.brand-person-teaser__link a{
    display: inline-block;
    margin-top: 10px;
}
.quote-item{
    background-color: #f2f4f5;
    margin: 90px 0 60px;
}
.quote-item--small{
    max-width: 990px;
}
.quote-item--big{
    max-width: 1245px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}
.quote-item__img{
    height: auto;
    margin-top: -50px;
    margin-left: 15px;
    max-width: 130px;
}
.quote-item--big .quote-item__img{
    margin-left: auto;
    margin-right: auto;
}
.quote-item__img img{
    border-radius: 50%;

}
.quote-item__body{
    padding: 40px 30px;
}
.quote-item__text{
    font-family: Zeyada, Arial;
    font-size: 30px;
    line-height: 1;
}
.quote-item__author{
    font-size: 22px;
    line-height: 1.45455;
    font-family: SourceSansPro-Bold, Arial;
}
.modal-content{
    border-radius: 0;
}
/* Info Modal */
.modal-header{
    position: relative;
}
.modal-close{
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 32px;
    padding: 0 7px;
    line-height: 1;
    background-color: transparent;
    border: none;

}
.modal--information{
    opacity: 0;
    -webkit-transition: opacity 250ms ease;
    -o-transition: opacity 250ms ease;
    transition: opacity 250ms ease;
}
.modal--information.fade-in{
    opacity: 1;
    -webkit-transition-delay: 1.5s;
         -o-transition-delay: 1.5s;
            transition-delay: 1.5s;
}
.modal--information .modal-header{
    padding: 12px 40px;
}
.modal--information__title{
    font-family: SourceSansPro-Bold, Arial;
    font-size: 16px;
    color: #666;
}
.modal--information .modal-body{
    padding: 0;
}
.info-modal-item{
    padding: 20px 40px;
}
.info-modal-item__title{
    font-family: Oswald-Regular, Arial;
    font-size: 20px;
    margin-bottom: 14px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.info-modal-item__icon {
    color: #b9b9b9;
    font-size: 24px;
    margin-top: 1px;
    margin-right: 10px;
}
.info-modal-item__icon.is-warning{
    color: #d7001e;
}
.arrow-wrapper--modal{
    background-color: #666666;
    width: 25px;
    height: 40px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border: 0;
    padding: 0;
    position: absolute;
    color: #fff;
}
.arrow-wrapper--modal .icon{
    font-size: 14px;
    opacity: 1;
    position: relative;
    left: auto;
    right: auto;
}
.arrow-wrapper--modal.arrow-right{
    right: 0;
}
.info-modal-slider .slick-dots{
    display: none!important;
}
.smartbanner-show {
    margin-top: 80px;
}
.smartbanner-show .smartbanner {
    display: block;
}
/** Default **/
.smartbanner {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-family: 'Helvetica Neue', sans-serif;
    background: #f4f4f4;
    z-index: 9998;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    -webkit-text-size-adjust: none;

    -webkit-box-shadow: 0 0 5px rgb(0 0 0 / 35%);

            box-shadow: 0 0 5px rgb(0 0 0 / 35%);
    background: #ffffff;
    z-index: 999;
}
.smartbanner-container {
    margin: 0 auto;
    white-space: nowrap;
}
.smartbanner-close {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px 0 5px;
    font-family: 'ArialRoundedMTBold', Arial;
    font-size: 20px;
    text-align: center;
    color: #888;
    text-decoration: none;
    border: 0;
    border-radius: 14px;
    -webkit-font-smoothing: subpixel-antialiased;
}
.smartbanner-close:active,
.smartbanner-close:hover {
    color: #aaa;
}
.smartbanner-icon {
    display: inline-block;
    vertical-align: middle;
    width: 57px;
    height: 57px;
    margin-right: 12px;
    background-size: cover;
    border-radius: 10px;
}
.smartbanner-info {
    display: inline-block;
    vertical-align: middle;
    width: 44%;
    font-size: 11px;
    line-height: 1.2em;
    font-family: SourceSansPro-Light, Arial;
    font-weight: 300;
}
.smartbanner-info > span{
    display: none;
}
.smartbanner-title {
    font-size: 13px;
    line-height: 18px;
    font-family: SourceSansPro-Bold, Arial;
    font-weight: 700;
    text-transform: uppercase;
}
.smartbanner-button {
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 12px;
    line-height: 24px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 10px;
    background: #d7001e;
    display: inline-block;
    padding: 4px 12px;
    height: auto;
}
.smartbanner-ios .smartbanner-icon {
    background: rgba(0, 0, 0, 0.6);
    background-size: cover;
    -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}
.smartbanner-android .smartbanner-icon {
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.range-slider {
    border-radius: 0;
    border: 0;
    -webkit-box-shadow: none;
            box-shadow: none;
    background-color: #d9dadb;
    height: 3px;
    padding: 0 12px;
}
.range-slider .noUi-handle {
    width: 20px;
    height: 20px;
    background: #d7001e;
    border-radius: 100%;
    -webkit-box-shadow: none;
            box-shadow: none;
    border: 0;
    top: -7px;
    left: -12px;
    cursor: pointer;
}
.range-slider .noUi-handle.noUi-handle-upper{
    left: -8px;
}
.range-slider .noUi-connect {
    background: #d7001e;
    -webkit-box-shadow: none;
            box-shadow: none;
    height: 6px;
    position: absolute;
    top: -50%;
}
.range-slider .noUi-handle-lower:before {
    display: none;
}
.range-slider .noUi-handle-upper:before {
    display: none;
}
.range-slider .noUi-handle:after {
    content: none;
}
.range-slider__title{
    padding-top: 20px;
    padding-bottom: 10px;
}
.range-slider__input-container{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.range-slider__input-spacer{
    background: #d9dadb;
    height: 2px;
    width: 24px;
}
.range-slider__values__input{
    padding-right: 50px;
}
.range-slider__label{
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    color: #969699;
}
.consent__overlay{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: rgb(242 244 245 / 80%);
    font-size: 22px;
    padding: 20px;
    color: #323233;
    text-align: center;
    -webkit-box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
            box-shadow: 0 0 10px 0 rgb(0 0 0 / 20%);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
}
.embed-responsive-2by3 .consent__overlay,
.embed-responsive-video-portrait .consent__overlay,
.embed-responsive-1by1 .consent__overlay{
    padding: 10px;
}
.consent__overlay-text{
    font-size: 16px;
}
.embed-responsive-2by3 .consent__overlay-text,
.embed-responsive-video-portrait .consent__overlay-text,
.embed-responsive-1by1 .consent__overlay-text{
    font-size: 12px;
}
.consent__overlay-accept-btn{
    margin-top: 30px;
}
.embed-responsive.consent-loaded:not(.consent-video-iframe) {
    height: auto;
    padding: 0!important;
    overflow: visible;
}
.embed-responsive.consent-loaded:not(.consent-video-iframe)>.embed-responsive-item {
    position: relative;
}
.has-consent-overlay .text-white{
    color: #323233!important;
}
.consent-overlay-video,
.consent-overlay-map{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;

    -o-object-fit: cover;

       object-fit: cover;
}
/* Magazin */
.font-zeyada{
  font-family: Zeyada, Arial;
}
.hl-big{
  font-size: 50px;
  line-height: 1.05;
}
.icon-text{
  margin-top: 20px;
}
.icon-text .icon{
  font-size: 20px;
  vertical-align: -3px;
  margin-right: 8px;
}
.is-magazin .content-block{
  margin: 40px 0;
}
.slider-same-height,
.slider-same-height .slick-list,
.slider-same-height .slick-track{
  height: 100%;
}
.teaser-grid-big .pagination-top .pagination{
  margin-top: -20px;
}
.row--col-divider>.col:not(:last-child){
  border-right: 1px solid #d9dadb;
}
a.author-item:hover{
  text-decoration: underline;
}
.author-item__name .icon{
  font-size: 15px;
  vertical-align: -2px;
  margin-right: 4px;
}
.author-item__img img{
  border-radius: 50%;
}
.list-authors>li{
  vertical-align: top;
}
.list-authors>li:not(:last-child){
  padding: 0;
  margin-right: 30px;
}
.list-authors__item{
  max-width: 100px;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
.list-authors__item:hover{
  -webkit-transform: translateY(-5px);
      -ms-transform: translateY(-5px);
          transform: translateY(-5px);
}
.list-authors__item img{
  border-radius: 50%;
}
.list-authors__link{
  display: block;
  margin-top: 15px;
}
.authors-slider:before {
  content: '';
  position: absolute;
  left: -15px;
  bottom: 0;
  top: 0;
  width: 100px;
  background: -webkit-gradient(linear, right top, left top, from(rgba(255,255,255,0)), to(#FFFFFF));
  background: -webkit-linear-gradient(right, rgba(255,255,255,0) 0%, #FFFFFF 100%);
  background: -o-linear-gradient(right, rgba(255,255,255,0) 0%, #FFFFFF 100%);
  background: linear-gradient(270deg, rgba(255,255,255,0) 0%, #FFFFFF 100%);
  z-index: 1;
}
.authors-slider:after {
  content: '';
  position: absolute;
  right: -15px;
  bottom: 0;
  top: 0;
  width: 100px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255,255,255,0)), to(#FFFFFF));
  background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, #FFFFFF 100%);
  background: -o-linear-gradient(left, rgba(255,255,255,0) 0%, #FFFFFF 100%);
  background: linear-gradient(to right, rgba(255,255,255,0) 0%, #FFFFFF 100%);
  z-index: 1;
}
/*overview*/
.list-authors-overview .list-authors__item {
  max-width: 100%;
}
.image-text-sujet{
  position: relative;
}
.image-text-sujet__body{
  padding: 30px;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%;
}
.image-text-sujet--extended .image-text-sujet__body{
  padding: 30px;
}
.image-text-sujet__body__inner{
  max-width: 480px;
  width: 100%;
}
.image-text-sujet__pretitle{
  font-family: Oswald-Regular, Arial;
  font-size: 18px;
  margin-top: 20px;
}
.image-text-sujet__title{
  font-family: Oswald-Regular, Arial;
  font-size: 22px;
  line-height: 1.42857;
  margin-top: 15px;
}
.image-text-sujet__wysiwyg{
  margin-top: 20px;
}
.image-text-sujet__hl{
  font-size: 50px;
  line-height: 1.05;
}
.image-text-sujet__img{
  position: relative;
}
.image-text-sujet__img__bottom{
  font-size: 18px;
  font-family: Oswald-Regular, Arial;
  color: #fff;
  position: absolute;
  padding: 35px 20px;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}
.image-text-sujet__img__bottom:before{
  content: '';
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,0.6)));
  background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
  background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.6) 100%);
}
.image-text-sujet__img__bottom>ul{
  position: relative;
  margin: 0;
}
.scroll-down-btn{
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background-color: #d7001e;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateY(50%) translateX(-50%);
      -ms-transform: translateY(50%) translateX(-50%);
          transform: translateY(50%) translateX(-50%);
  z-index: 3;
}
.scroll-down-btn .icon{
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
      -ms-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  font-size: 12px;
}
.image-text-sujet .arrow-wrapper.arrow-left,
.full-width-media-slider .arrow-wrapper.arrow-left{
  background: -webkit-gradient(linear,left top, right top,color-stop(0, rgba(0, 0, 0, 0.2)),to(transparent));
  background: -webkit-linear-gradient(left,rgba(0, 0, 0, 0.2) 0,transparent 100%);
  background: -o-linear-gradient(left,rgba(0, 0, 0, 0.2) 0,transparent 100%);
  background: linear-gradient(to right,rgba(0, 0, 0, 0.2) 0,transparent 100%);
}
.image-text-sujet .arrow-wrapper.arrow-right,
.full-width-media-slider .arrow-wrapper.arrow-right{
  background: -webkit-gradient(linear,right top, left top,color-stop(0, rgba(0, 0, 0, 0.2)),to(transparent));
  background: -webkit-linear-gradient(right,rgba(0, 0, 0, 0.2) 0,transparent 100%);
  background: -o-linear-gradient(right,rgba(0, 0, 0, 0.2) 0,transparent 100%);
  background: linear-gradient(to left,rgba(0, 0, 0, 0.2) 0,transparent 100%);
}
.image-text-sujet__tags{
  font-size: 18px;
  font-family: Oswald-Regular, Arial;
}
.page-heading-magazin__hl{
  font-family: Zeyada, Arial;
  text-align: center;
  line-height: 1;
  font-size: 40px;
  margin-bottom: 0;
}
.page-heading-magazin .wysiwyg{
  margin-top: 25px;
}
.teaser-text{ padding: 10px 0 30px; }
.teaser-text__hl{
  font-size: 18px;
  font-family: Oswald-Regular, Arial;
  margin-bottom: 15px;
}
.teaser-text ul{
  margin-left: 20px;
}
.teaser-text ul>li{
  text-transform: uppercase;
  font-size: 16px;
  font-family: Oswald-Regular, Arial;
  margin-bottom: 15px;
}
.teaser-text ul>li>a:hover{
  text-decoration: none;
}
.img-teaser{
  position: relative;
  height: 100%;
}
.img-teaser__body{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  text-shadow: 0 2px 4px rgba(0,0,0,.8);

  color: #fff;
}
.img-teaser--big .img-teaser__body{
  padding: 15px;
}
.img-teaser__body:before{
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: -webkit-gradient(linear,left top, left bottom,color-stop(0, transparent),to(rgba(0,0,0,.6)));
  background: -webkit-linear-gradient(top,transparent 0,rgba(0,0,0,.6) 100%);
  background: -o-linear-gradient(top,transparent 0,rgba(0,0,0,.6) 100%);
  background: linear-gradient(180deg,transparent 0,rgba(0,0,0,.6) 100%);
  z-index: 1;
  opacity: 1;
  -webkit-transition: opacity 250ms ease;
  -o-transition: opacity 250ms ease;
  transition: opacity 250ms ease;
}
.img-teaser:hover .img-teaser__body:before{
  opacity: 0;
}
.img-teaser__content{
  position: relative;
  z-index: 2;
}
.img-teaser__top-title{
  margin-top: 10px;
  font-size: 15px;
}
.img-teaser__title{
  margin-top: 7px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.45455;
}
.img-teaser .icon-text{
  font-size: 15px;

}
.img-teaser .icon-text .icon{
  font-size: 14px;
}
.img-teaser__badge-overlay{
  overflow: hidden;
  position: absolute;
  top: -6px;
  right: -6px;
  bottom: 0;
  left: 0;
}
.img-teaser__badge{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  background-image: -webkit-gradient(linear,left top, right top,color-stop(0, #ff0d2d),color-stop(51%, #d7001e),to(#ab0017));
  background-image: -webkit-linear-gradient(left,#ff0d2d 0,#d7001e 51%,#ab0017 100%);
  background-image: -o-linear-gradient(left,#ff0d2d 0,#d7001e 51%,#ab0017 100%);
  background-image: linear-gradient(to right,#ff0d2d 0,#d7001e 51%,#ab0017 100%);
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  font-family: SourceSansPro-Bold, Arial;
  text-align: center;
  padding: 18px 50px;
  width: 168px;
  line-height: 1;
  -webkit-transform: rotate(45deg) translateX(48px) translateY(-49%);
      -ms-transform: rotate(45deg) translateX(48px) translateY(-49%);
          transform: rotate(45deg) translateX(48px) translateY(-49%);
  height: 40px;
  z-index: 2;
}
.img-teaser__badge:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  left: 8px;
  border-width: 7px 6px 6px 0;
  border-color: transparent #BD0019 transparent;
  /* top: 116%; */
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: left;
      -ms-transform-origin: left;
          transform-origin: left;
  bottom: -6px;
}
.img-teaser__badge:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  right: 14px;
  border-width: 0 0 7px 7px;
  border-color: transparent #57000C;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
  bottom: -7px;
}
.img-teaser__video-badge{
  position: absolute;
  top: 1px;
  left: 0;
  width: 23px;
  height: 23px;
  font-size: 10px;
  background: #fff;
  color: #d7001e;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-shadow: none;
}
.img-teaser__title-container{
  position: relative;
  padding-left: 30px;
}
.author-sujet__img img{
  border-radius: 50%;
}
.author-sujet__hl{
  font-family: Zeyada, Arial;

}
.author-sujet__social{
  margin-top: 40px;
}
.author-sujet__social ul>li:not(:last-child){
  margin-right: 9px;
}
.author-sujet__social__item{
  font-size: 32px;
}
.teaser-facts{
  background-color: #d9dadb;
  padding: 20px;

}
.teaser-facts__hl{
  font-family: Oswald-Regular, Arial;
  font-size: 22px;
  line-height: 1.45455;
  margin-bottom: 15px;
}
.teaser-facts .list-facts>li{
  margin-bottom: 15px;
  padding-left: 30px;
  position: relative;
}
.teaser-facts .list-facts>li:before{
  content: "\E05A";
  font-family: iconfont;
  position: absolute;
  top: 0;
  left: 0;
}
.tag-teaser{
  font-size: 12px;
  line-height: 1.70588;
  font-family: Oswald-Regular, Arial;
  padding: 10px;
  position: relative;
}
.tag-teaser:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 35px;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255,255,255,0.1)),color-stop(39%, rgba(255,255,255,0.1)),to(rgba(255,255,255,1)));
  background: -webkit-linear-gradient(top, rgba(255,255,255,0.1) 0%,rgba(255,255,255,0.1) 39%,rgba(255,255,255,1) 100%);
  background: -o-linear-gradient(top, rgba(255,255,255,0.1) 0%,rgba(255,255,255,0.1) 39%,rgba(255,255,255,1) 100%);
  background: linear-gradient(to bottom, rgba(255,255,255,0.1) 0%,rgba(255,255,255,0.1) 39%,rgba(255,255,255,1) 100%);
}
.author-teaser{
  color: #fff;
  position: relative;
}
.author-teaser__body{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  text-shadow: 0 2px 4px rgba(0,0,0,0.8);

  color: #fff;
}
.author-teaser__title{
  font-size: 25px;
  line-height: 1.3;
  font-family: Oswald-Regular, Arial;
  text-transform: uppercase;
  max-width: 65%;
}
.link-teaser{
  position: relative;
  background-color: #d7001e;
  color: #fff;
  -webkit-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
}
.link-teaser:hover{
  background-color: #bd001a;
}
.link-teaser__hl{
  font-size: 22px;
  line-height: 1.3;
  text-transform: uppercase;
  font-family: Oswald-Regular, Arial;

  position: absolute;
  bottom: 15px;
  left: 15px;
  right: 15px;
}
.link-teaser__icon{
  font-size: 22px;
  position: absolute;
  top: 15px;
  left: 15px;
}
.page-heading-tag{
  width: 100%;
  max-width: 740px;
  margin: 60px auto 30px;
  text-align: center;
  padding: 0 15px;
}
.page-heading-tag__hl,
.page-heading-tag__title{
  font-size: 50px;
  line-height: 1.05;
  font-family: Zeyada, Arial;
  display: inline-block;
}
.page-heading-tag__hashtag{
  line-height: 0;
  display: inline-block;

  vertical-align: -16px;
  margin-right: -15px;
  margin-left: 0;
}
.page-heading-tag .wysiwyg{
  text-align: left;
}
.article-detail-bar{
    padding: 10px 0;
}
.article-tabbing__hl{
    font-family: Zeyada, Arial;
    line-height: 1.33333;
    padding: 15px 15px 0;
    font-size: 38px;
}
.article-wysiwyg__hl{
    font-size: 38px;
    font-family: Zeyada, Arial;
    margin-bottom: 15px;
}
.img-portrait__title,
.img-landscape__title{
    font-family: Zeyada, Arial;
    background-color: #fff;
    padding: 6px 15px 0;
    position: absolute;
    right: 0;
    bottom: 30px;
    font-size: 20px;
}
.img-landscape .show-lightbox,
.img-portrait .show-lightbox{
    overflow: visible;
}
.article-img-text-slide__hl{
    font-size: 38px;
    font-family: Zeyada, Arial;
}
.article-text-right .article-img-text-slide__body{
    padding: 15px;
}
.article-text-left .article-img-text-slide__body{
    padding: 15px;
}
.full-width-img{
    position: relative;
    color: #fff;
}
.full-width-img__body{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    text-align: center;
}
.full-width-img__title{
    text-shadow: 2px 2px 3px #000000;
    font-family: Zeyada, Arial;
}
.article-checklist__container {
    padding: 15px;
    position: relative;
}
.article-checklist__body {
    border: 1px solid #CCCECF;
    background: #ffffff;
    padding: 25px 15px;
    margin-top: -50px;
    margin-left: 12px;
    margin-right: 12px;
}
.article-checklist__hl {
    font-size: 38px;
    font-family: Zeyada, Arial;
}
.article-checklist__icon {
    padding-top: 5px;
    padding-right: 15px;
    font-size: 24px;
}
.article-checklist__list {
    padding-top: 13px;
}
.article-checklist__list-hl {
    font-size: 35px;
    line-height: 25px;
    font-family: Zeyada, Arial;
}
.article-teaser-slider__hl{
    font-family: Zeyada, Arial;
    font-size: 50px;
    margin-bottom: 40px;
    margin-top: 20px;
    position: relative;
    z-index: 2;
}
.article-category-teaser{
    position: relative;
    text-align: left;
}
.article-category-teaser__top{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-shadow: 0 2px 4px rgba(0,0,0,0.7);
    padding: 15px 15px 0;
}
.article-category-teaser__top:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0,0,0,0)), to(rgba(0,0,0,0.8)));
    background: -webkit-linear-gradient(bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    background: -o-linear-gradient(bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    z-index: 1;
}
.article-category-teaser .list-tags{
    font-size: 16px;
    font-family: Oswald-Regular, Arial;
    margin-top: 10px;
}
.article-category-teaser__bottom{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 40px 15px 15px;
    text-shadow: 0 2px 4px rgba(0,0,0,0.7);
}
.article-category-teaser__bottom:after{
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0)), to(rgba(0,0,0,0.8)));
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);
    z-index: 1;
}
.article-category-teaser__text{
    margin: 5px 0;
}
.article-category-teaser .inner{
    position: relative;
    z-index: 2;
}
.article-category-slider__item{

}
.article-category-slide{
    padding-top: 6px;
    padding-right: 6px;
}
.article-category-slide__top-img,
.article-category-slide__bottom-row{
    height: 50%;
}
.article-category-slide__img-title{
    font-family: Zeyada, Arial;
    font-size: 35px;
    color: #fff;
    text-shadow: 2px 2px 3px rgba(0,0,0,0.8);
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    z-index: 1;
    line-height: 1;
}
.article-category-slide__tag-list{
    font-size: 18px;
    line-height: 1.16667;
    font-family: Oswald-Regular, Arial;
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    z-index: 1;
}
.article-category-slide__tag-list>li:not(:last-child){
    margin-bottom: 10px;
}
.article-category-slide__body{
    background-color: #f2f4f5;
    padding: 20px;
}
.article-category-slide__title{
    font-family: Zeyada, Arial;
    font-size: 35px;
    margin-bottom: 0;
    line-height: 1;
    margin-top: 20px;
}
.article-category-slide__subtitle{
    font-size: 28px;
    font-family: Oswald-Regular, Arial;
    margin-top: 10px;
}
.article-category-slide__tag-inline-list{
    font-family: Oswald-Regular, Arial;
    font-size: 18px;
    margin-top: 15px;
}
.article-category-slide .img-teaser__badge{
    padding: 18px 65px;
}
.category-slider-container{
    position: relative;
    padding-bottom: 65px;
}
.category-slider-navigation{
    position: absolute;
    right: 45px;
    bottom: 0;
}
.arrow-wrapper-dark{
    background-color: #323233;
    color: #fff;
    width: 32px;
    height: 42px;
    border-radius: 0;
    border: none;
}
.arrow-wrapper-dark .icon{
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    font-size: 16px;
}
.arrow-wrapper-dark .icon.slick-prev{
    -webkit-transform: translateX(-50%) translateY(-50%) scale(-1);
        -ms-transform: translateX(-50%) translateY(-50%) scale(-1);
            transform: translateX(-50%) translateY(-50%) scale(-1);
}
.category-slider-navigation .arrow-left{
    position: absolute;
    left: -32px;
    bottom: 50%;
    -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
            transform: translateY(50%);
}
.category-slider-navigation .arrow-right{
    position: absolute;
    right: -32px;
    bottom: 50%;
    -webkit-transform: translateY(50%);
        -ms-transform: translateY(50%);
            transform: translateY(50%);
}
.category-slider__paging{
    font-size: 23px;
    font-family: Oswald-Regular, Arial;
    padding: 5px;
    min-width: 50px;
    display: block;
    text-align: center;
}
.category-slider__paging strong{
    font-family: Oswald-Regular, Arial;
}
.audio-player__container{
    padding: 20px 15px;
    background: #f2f4f5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.audio-player__container + .audio-player__container{
    margin-top: 10px;
}
.audio-player__intro{
    margin-bottom: 15px;
}
/*Alpenmosaik*/
.region-badge{
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    padding: 3px 4px 4px;
    color: #FFFFFF;
}
.bg-primary .region-badge.region-badge--verwall,
.bg-primary .region-badge.region-badge--silvretta,
.bg-primary .region-badge.region-badge--tal,
.bg-primary .region-badge.region-badge--raetikon{
    background: #FFF;
    color: #323233;
}
.region-badge.region-badge--lg{
    padding: 5px 13px 6px;
}
.region-badge.region-badge--verwall{
    background: #4BA927;
}
.region-badge.region-badge--tal{
    background: #6EC3BF;
}
.region-badge.region-badge--raetikon{
    background: #FAB118;
}
.region-badge.region-badge--silvretta{
    background: #E52A1E;
}
.region-badge__title{
    font-family: SourceSansPro-Bold, Arial;
    text-shadow: none;
    font-size: 11px;
    line-height: 0.93333;
}
.region-badge--lg .region-badge__title{
    font-size: 16px;
    line-height: 1.25;
}
/*styling silvretta*/
.region--silvretta .btn.btn-primary{
    background-color: #E52A1E;
}
.region--silvretta .btn.btn-primary:hover{
    background-color: #D4190D;
}
.region--silvretta .text-primary{
    color: #E52A1E;
}
.region--silvretta .bg-primary{
    background: #E52A1E;
}
.region--silvretta .bg-primary{
    background: #E52A1E;
}
.region--silvretta .nav-tabs.nav-tabs--primary>li.active>a,
.region--silvretta .nav-tabs.nav-tabs--primary>li.active>a:hover,
.region--silvretta .nav-tabs.nav-tabs--primary>li.active>a:focus,
.region--silvretta .nav-tabs.nav-tabs--primary>li>a:hover{
    border-bottom: 1px solid #E52A1E;
}
.region--silvretta .is-primary.decorated-hr{
    color:#E52A1E;
    border-color: #E52A1E;
}
.region--silvretta .link-primary.text-primary, .region--silvretta .link-primary.text-primary:hover{
    color: #E52A1E;
}
.region--silvretta .vjs-default-skin .vjs-big-play-button{
    background: #E52A1E;
}
.region--silvretta .tour-head__title,
.region--silvretta .tour-head__subtitle{
    color: #FFF;
}
.region--silvretta .wysiwyg a{
    color: #E52A1E;
}
/*styling raetikon*/
.region--raetikon .btn.btn-primary{
    background-color: #FAB118;
}
.region--raetikon .btn.btn-primary:hover{
    background-color: #E9A007;
}
.region--raetikon .text-primary{
    color: #FAB118;
}
.region--raetikon .bg-primary{
    background: #FAB118;
}
.region--raetikon .nav-tabs.nav-tabs--primary>li.active>a,
.region--raetikon .nav-tabs.nav-tabs--primary>li.active>a:hover,
.region--raetikon .nav-tabs.nav-tabs--primary>li.active>a:focus,
.region--raetikon .nav-tabs.nav-tabs--primary>li>a:hover{
    border-bottom: 1px solid #FAB118;
}
.region--raetikon .is-primary.decorated-hr{
    color:#FAB118;
    border-color: #FAB118;
}
.region--raetikon .link-primary.text-primary, .region--raetikon .link-primary.text-primary:hover{
    color: #FAB118;
}
.region--raetikon .vjs-default-skin .vjs-big-play-button{
    background: #FAB118;
}
.region--raetikon .wysiwyg a{
    color: #FAB118;
}
/*styling verwall*/
.region--verwall .btn.btn-primary{
    background-color: #4BA927;
}
.region--verwall .btn.btn-primary:hover{
    background-color: #3A9816;
}
.region--verwall .text-primary{
    color: #4BA927;
}
.region--verwall .bg-primary{
    background: #4BA927;
}
.region--verwall .bg-primary{
    background: #4BA927;
}
.region--verwall .nav-tabs.nav-tabs--primary>li.active>a,
.region--verwall .nav-tabs.nav-tabs--primary>li.active>a:hover,
.region--verwall .nav-tabs.nav-tabs--primary>li.active>a:focus,
.region--verwall .nav-tabs.nav-tabs--primary>li>a:hover{
    border-bottom: 1px solid #4BA927;
}
.region--verwall .is-primary.decorated-hr{
    color:#4BA927;
    border-color: #4BA927;
}
.region--verwall .link-primary.text-primary, .region--verwall .link-primary.text-primary:hover{
    color: #4BA927;
}
.region--verwall .vjs-default-skin .vjs-big-play-button{
    background: #4BA927;
}
.region--verwall .wysiwyg a{
    color: #4BA927;
}
/*styling tal*/
.region--tal .btn.btn-primary{
    background-color: #6EC3BF;
}
.region--tal .btn.btn-primary:hover{
    background-color: #5DB2AE;
}
.region--tal .text-primary{
    color: #6EC3BF;
}
.region--tal .bg-primary{
    background: #6EC3BF;
}
.region--tal .bg-primary{
    background: #6EC3BF;
}
.region--tal .nav-tabs.nav-tabs--primary>li.active>a,
.region--tal .nav-tabs.nav-tabs--primary>li.active>a:hover,
.region--tal .nav-tabs.nav-tabs--primary>li.active>a:focus,
.region--tal .nav-tabs.nav-tabs--primary>li>a:hover{
    border-bottom: 1px solid #6EC3BF;
}
.region--tal .is-primary.decorated-hr{
    color:#6EC3BF;
    border-color: #6EC3BF;
}
.region--tal .link-primary.text-primary, .region--tal .link-primary.text-primary:hover{
    color: #6EC3BF;
}
.region--tal .vjs-default-skin .vjs-big-play-button{
    background: #6EC3BF;
}
.region--tal .wysiwyg a{
    color: #6EC3BF;
}
.tour-teaser{
    position: relative;
    color: #FFF;
}
.tour-teaser:before{
    position: absolute;
    content: '';
    inset: 0;
    width: 100%;
    height: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(50,50,51,0)), to(rgba(47,47,47,0.85)));
    background: -webkit-linear-gradient(top, rgba(50,50,51,0) 0%, rgba(47,47,47,0.85) 100%);
    background: -o-linear-gradient(top, rgba(50,50,51,0) 0%, rgba(47,47,47,0.85) 100%);
    background: linear-gradient(180deg, rgba(50,50,51,0) 0%, rgba(47,47,47,0.85) 100%);
    z-index: 1;
}
.tour-teaser__content{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 9px 12px;
    z-index: 2;
}
.tour-teaser__title{
    font-size: 15px;
    line-height: 1.2;
    font-family: Oswald-Regular, Arial;
}
.tour-teaser__info{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 7px;
    position: relative;
}
.tour-teaser__info + .tour-teaser__info{
    padding-left: 7px;
}
.tour-teaser__info.tour-teaser__info-small{
    font-size: 15px;
    gap: 5px;
}
.tour-teaser__info.tour-teaser__info-small + .tour-teaser__info.tour-teaser__info-small{
    padding-left: 9px;
    margin-left: 0;
}
.tour-teaser__info + .tour-teaser__info:before{
    content: '';
    position: absolute;
    left: 3px;
    top: 50%;
    width: 2px;
    height: 2px;
    background: #FFF;
    border-radius: 50%;
}
.tour-teaser__icon{
    font-size: 15px;
    display: none;
}
.tour-teaser__icon.icon-difficulty-hard{
    text-shadow: 0px 0px 2px #FFFFFF;
}
.tip-area{
    padding: 0 20px;
}
.tip-area__container{
    position: relative;
    max-width: 1246px;
    width: 100%;
    padding: 81px 10px 48px;
    margin: 0 auto;
}
.tip-area__badge{
    background: #d7001e;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: -20px;
    left: -10px;
}
.tip-area__badge-content{
    border: 2px dashed #FFFFFF;
    margin: 5px;
    padding: 6px;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.tip-area__badge-title{
    font-size: 18px;
    line-height: 1.33333;
    font-family: Oswald-Regular, Arial;
    color: #FFF;
}
.region-teaser{
    color: #FFFFFF;
}
.region-teaser__content{
    padding: 18px 32px 24px;
    background: transparent;
}
.region-teaser.region-teaser--silvretta{
    background: #E52A1E;
}
.region-teaser.region-teaser--tal{
    background: #6EC3BF;
}
.region-teaser.region-teaser--verwall{
    background: #4BA927;
}
.region-teaser.region-teaser--raetikon{
    background: #FAB118;
}
.img-tour-area__content{
    padding: 20px 20px;
}
.img-tour-area__title{
    font-size: 26px;
    line-height: 1.225;
    margin: 15px 0;
}
.img-tour-area__info{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 7px;
    position: relative;
}
.img-tour-area__info + .img-tour-area__info{
    padding-left: 7px;
}
.img-tour-area__icon{
    font-size: 15px;
}
.img-tour-area__text{
    margin-top: 15px;
    max-width: 474px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

}
.img-tour-area__slider{
    position: relative;
}
.img-tour-area__slider-container{
    padding: 0 20px;
}
.tour-head{
    max-width: 100%;
    overflow: hidden;
}
.tour-head__content{
    padding: 25px 20px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.tour-head__title{
    color: #323233;
    margin: 10px 0;
}
.tour-head__subtitle{
    color: #323233;
    font-size: 17px;
    line-height: 1.45455;
    margin-bottom: 10px;
}
.tour-head__badge{
    background: #FFFFFF;
    color: #323233;
    padding: 3px 10px 4px;
    font-size: 15px;
    line-height: 1.26667;
}
.tour-head__badge-list.list-inline{
    margin: -3px;
}
.tour-head__badge-list.list-inline li{
    padding: 5px 3px;
}
.tour-detail-info__fact-list.list-inline li{
    padding: 5px 40px;
}
.tour-detail-info__list.list-inline{
    margin-left: -5px;
}
.tour-detail-info__list.list-inline li{
    padding: 5px 5px;
}
.tour-detail-info__rating-list.list-inline{
    margin-left: -1px;
}
.tour-detail-info__rating-list.list-inline li{
    padding: 5px 1px;
}
.tour-detail-info__fact-icon{
    font-size: 30px;
}
.tour-detail-info__rating-icon{
    font-size: 12px;
}
.tour-detail-info__month{
    height: 10px;
}
.tour-detail-info__month-list{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 4px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.anchor-nav{
    width: 100%;
    border-bottom: 1px solid #d9dadb;
}
.anchor-nav__list{
    margin-top: 11px;
    margin-bottom: 0;
    padding-bottom: 11px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow-x: auto;
}
.anchor-nav__link{
    font-family: Oswald-Regular, Arial;
    font-size: 17px;
    line-height: 1.47059;
    color: #7d7d80;
    white-space: nowrap;
}
.anchor-nav__list{
    margin-left: -16px;
}
.anchor-nav__list .anchor-nav__link{
    padding: 0 16px;
}
.poi-map-teaser{
    min-height: 100px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: #FFF;
    position: relative;
}
.poi-map-teaser:hover .poi-map-teaser__content,
.slick-active > .poi-map-teaser .poi-map-teaser__content{
    background: #d7001e;
    color: #FFF;
}
.region--verwall .poi-map-teaser:hover .poi-map-teaser__content,
.region--verwall .slick-active > .poi-map-teaser .poi-map-teaser__content{
    background: #4BA927;
}
.region--tal .poi-map-teaser:hover .poi-map-teaser__content,
.region--tal .slick-active > .poi-map-teaser .poi-map-teaser__content{
    background: #6EC3BF;
}
.region--silvretta .poi-map-teaser:hover .poi-map-teaser__content,
.region--silvretta .slick-active > .poi-map-teaser .poi-map-teaser__content{
    background: #E52A1E;
}
.region--raetikon .poi-map-teaser:hover .poi-map-teaser__content,
.region--raetikon .slick-active > .poi-map-teaser .poi-map-teaser__content{
    background: #FAB118;
}
.poi-map-teaser + .poi-map-teaser{
    margin-top: 10px;
}
.poi-map-teaser__img{
    width: 134px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
.poi-map-teaser__icon{
    font-size: 71px;
    color: #d9dadb;
    opacity: 0.2;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.poi-map-teaser__title{
    font-family: SourceSansPro-Bold, Arial;
    font-size: 20px;
    line-height: 1.25;
    margin-bottom: 0;
}
.poi-map-teaser__content{
    background: #FFF;
    padding: 28px 20px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: relative;
    -webkit-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
    -o-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
    z-index: 1;
}
.poi-map-teaser__line{
    width: 4px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}
.poi-map{
    position: relative;
}
.poi-map__pin{
    position: absolute;
}
.poi-map__pin:after{
    content: "\E059";
    font-family: iconfont;
    font-size: 20px;
    color: #FFF;
    bottom: 100%;
    right: 100%;
    position: absolute;
}
.poi-map__pin:before{
    content: "\E058";
    font-family: iconfont;
    font-size: 20px;
    color: #FFF;
    z-index: 1;
    bottom: 100%;
    right: 100%;
    position: absolute;
    opacity: 0;
    -webkit-transition: opacity 200ms ease-in-out;
    -o-transition: opacity 200ms ease-in-out;
    transition: opacity 200ms ease-in-out;
}
.poi-map__pin.is-active:before{
    opacity: 1;
}
.poi-map-slider{
    padding: 10px;
}
.poi-map-slider-arrows .arrow-wrapper.arrow-wrapper--teaser{
    top: 43%;
}
/* Button Primary Color */
.btn.btn-primary{
    background: #d7001e;
    color: #fff;
}
/* Button Primary Hover Color */
.btn.btn-primary:hover{
    background: #bd001a;
}
/* Console Color */
.console {
    background-color: #2b2c2c;
}
/* Console Icons und Text Color */
.nav-tabs.nav-tabs--console>li>a{
    color: #d9dadb;
}
/* Links in Teasern und Slides */
.link-primary.text-primary,
.link-primary.text-primary:hover{
    color: #d7001e;
}
@media screen and (max-width: 767px) {
    .text-center-xs{
        text-align: center;
    }
    .iframe-wrapper{
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
    }
    .progress.progress--primary{
        margin: 10px 0;
    }
    .container.container--no-padding-xs{ padding: 0; }
    body {
        min-width: 320px;
        max-width: 100%;
        overflow-x: hidden;
    }
    html {
        overflow-x: hidden;
    }

    .page-wrapper{
        padding-top: 0;
    }
    .wysiwyg {
        overflow-y: hidden;
        overflow-x: auto; /* for responsive tables */
    }
    .btn-list .btn {
        margin-bottom: 12px;
    }
    .btn.btn-icon{
        padding-left: 38px;
    }
    .btn.btn-icon>.icon{
        font-size: 17px;
        position: absolute;
        left: 15px;
        top: 8px;
    }
    .btn.btn-bold{ padding: 15px 20px; }
    .btn.btn-bold>.icon{ font-size: 30px; vertical-align: -6px; margin-right: 3px; }
    .navbar-brand>img{
        width: 70px;
        height: auto;
    }

    .portal-gargellen .navbar-brand>img{
        margin-top: 5px;
    }
    .navbar-nav__dropdown ul>li>a {
        display: block;
        padding: 7px 0;
    }

    .navbar-nav .navbar-nav__dropdown ul {
        margin-bottom: 0;
    }
   .navbar-lp-brand img{
     width: 70px;
   }
    .breadcrumbs{
        padding: 8px 0;
    }

    .breadcrumb>li{
        display: none;
        font-size: 15px;
    }

    .breadcrumb>li:first-child,
    .breadcrumb>li.active{
        display: inline-block;
    }

    .breadcrumb>li+li:before{
        content: "\E030";
        color: #fff;
        font-size: 4px;
        top: -3px;
        margin-left: 0;
    }

    .share-xs{
        margin: 0 -20px;
        border-top: 1px solid #fff;
        padding: 20px 0;
    }

    .breadcrumb-wrapper .at-resp-share-element .at-share-btn .at-icon-wrapper,
    .breadcrumb-wrapper .at-resp-share-element .at-icon{
        width: 30px!important; /* overwrite addthis inline style */
        height: 30px!important;
    }

    .breadcrumb-wrapper .at-resp-share-element.at-mobile .at-share-btn{
        margin-right: 15px;
    }

    .navbar-toggle:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #d7001e;
        left: 50%;
        margin-left: -6px;
        bottom: -17px;
        z-index: 10;
    }

    .navbar-toggle.collapsed:before {
       display: none;
    }

    .navbar-nav{
        margin: 0;
    }

    .nav-open{
        position: relative;
    }
    .nav-open:before,
    .search-open:before,
    .lang-open:before{
        content: '';
        position: absolute;
        top:0;
        bottom:0;
        left:0;
        right:0;
        background: rgba(0,0,0,0.5);
        z-index: 5;
    }

    .nav-open .navbar-brand,
    .lang-open .navbar-brand{
        visibility: hidden;
        z-index: -1;
    }

    .navbar-main .navbar-collapse{
        background-image: -webkit-gradient(linear, left top, left bottom, from(#f8f9fa), to(white));
        background-image: -webkit-linear-gradient(top, #f8f9fa 0%, white 100%);
        background-image: -o-linear-gradient(top, #f8f9fa 0%, white 100%);
        background-image: linear-gradient(180deg, #f8f9fa 0%, white 100%);
        -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        border: none;
        position: relative;
        top: -1px;
        margin: 0 -21px;
        border-top: 1px solid #d7001e;
    }

    .navbar-default .navbar-nav>li>a{
        text-align: left;
        font-size: 18px;
        padding: 12px 10px;
        border-bottom: 1px solid #d9d9d9;
        position: relative;
    }

    .navbar-default .navbar-nav>li.is-open>a{
        border-bottom: none;
    }

    .navbar-default .navbar-nav>li.active>a{
        color: #7d7d80;
    }
    
    .navbar-default .navbar-nav>li>a>.subnav-toggle{
        position: absolute;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
        padding: 14px 15px;
    }

    .navbar-default .navbar-nav>li.is-open>a>.subnav-toggle>.icon{
        -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
                transform: rotate(45deg);
    }

    .navbar-default .navbar-nav>li>a>.subnav-toggle.hide{
        display: none;
    }

    .navbar-default .navbar-nav>li:last-child>a{
        border-bottom: none;
    }

    .navbar-nav__icon{
        margin-right: 7px;
        font-size: 25px;
        position: relative;
        top: 3px;
    }

    .navbar-default .navbar-toggle:hover,
    .navbar-default .navbar-toggle:focus{
        background: #fff;
    }

    .navbar-default .navbar-toggle.collapsed .icon-bar{
        background: #323233;
    }

    .navbar-default .navbar-toggle .icon-bar{
        background: #d7001e;
    }

    .navbar-nav .navbar-nav__dropdown{
        position: relative;
        padding: 0 0 10px;
        border-bottom: 1px solid #d9d9d9;
    }

    /*.navbar-nav li.is-open .navbar-nav__dropdown{*/
        /*display: block;*/
    /*}*/

    .navbar-default .navbar-toggle{
        padding: 9px 15px 9px 18px;
        border: none;
        margin: 9px 0px;
        margin-right: -15px;
        border-left: 1px solid  #cdd0d1;
        border-radius: 0;
        display: inline-block;
        float: none;
    }

    .navbar-toggle .icon-bar{
        width: 16px;
    }

    .navbar-toggle .icon-bar+.icon-bar {
        margin-top: 3px;
    }

    .navbar-default .navbar-toggle .icon-bar{
        background-color: #d7001e;
    }
    
    .navbar-right-xs{
        float: right;
    }

    a.search-mobile{
        font-size: 15px;
        padding: 12px;
        color: #d7001e;
        position: relative;
    }
    
    .search-mobile.collapsed{
        color: #323233;
    }

    .search-mobile:before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #d7001e;
        left: 50%;
        margin-left: -6px;
        bottom: -10px;
        z-index: 10;
    }

    .search-mobile.collapsed:before {
        display: none;
    }

    .search-mobile .icon{
        font-size: 19px;
        position: relative;
        top: 3px;
    }

    .navbar-header .btn.btn-webshop{
        width: 36px;
        height: 36px;
        padding: 10px 0;
        text-align: center;
        margin: -3px 7px 0 3px;
    }

    .navbar-header .btn.btn-webshop .icon{
        margin-right: 0;
        font-size: 15px;
    }
    .console{
        color: #fff;
        margin: 0 -15px;
    }

    .console .icon-weather{
        width: 40px;
        height: 40px;
        margin-bottom: -3px;
    }

    .console__item .icon{
        display: block;
        font-size: 27px;
        position: relative;
        top: -4px;
        margin-bottom: 4px;
    }

    .console__item {
        height: 80px;
        border-bottom: 1px solid #585858;
        border-right: 1px solid #585858;
        padding: 15px 10px;
        display: block;
        font-size: 14px;
    }

    .console__item-weather{
        padding-top: 10px;
    }

    .console__item__value{
        display: block;
    }
    .console-live-info{
        background: #212121;
        color: #fff;
        text-align: center;
    }
    .console-live-info__parent>li{
        border-right: 1px solid #585858;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        padding: 10px 0;
    }
    .console-live-info__solo__icon {
        margin-bottom: 31px;
    }

    .console-live-info__parent>li:last-child{
        border-right: none;
    }

    .console-live-info__icon.icon-weather {
        width: 40px;
        height: 40px;
        margin: 0 auto;
    }

    .console-live-info__icon.icon {
        font-size: 26px;
        position: relative;
        margin-top: 9px;
    }

    .console-live-info__value{
        margin: 10px 0;
        font-family: SourceSansPro-Bold, Arial;
        font-size: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

    .console-live-info__link{
        font-size: 14px;
        color: #ccc;
    }
    .footer__social ul{
        text-align: center;
    }
    .footer__social ul>li{
        margin-bottom: 0;
    }
    .social-item .social-text{
        display: none;
    }

    .footer__partner{
        margin-top: 10px;
    }

    .circle-social .icon.icon-soc-youtube{
        font-size: 14px;
    }
    .footer__basic .media-middle{
        vertical-align: top;
    }

    .footer__basic .meta-links{
        margin-top: 20px;
        text-align: center;
    }
    .footer__bottom{
        text-align: center;
    }

    .footer__basic .media-object{
        width: 55px;
    }
    .arrow-wrapper.arrow-wrapper--teaser.arrow-small-xs{
        height: 40px;
        width: 25px;
        margin-top: -80px;
    }

    .arrow-wrapper.arrow-wrapper--teaser.arrow-right.arrow-small-xs{
        right: -5px;
    }

    .arrow-wrapper.arrow-wrapper--teaser.arrow-left.arrow-small-xs{

        left: -5px;
    }

    .arrow-wrapper.arrow-wrapper--teaser.arrow-small-xs .icon {
        font-size: 15px;
    }
    .arrow-wrapper.arrow-wrapper--portal.arrow-left .icon{
        left: 20px;
    }
    .arrow-wrapper.arrow-wrapper--portal.arrow-right .icon{
        right: 20px;
    }
    .slider-item__logo {
        bottom: 15px;
        right:15px;
    }
    .slider-item__logo-img {
        height:50px;
    }
    .portal-header-slider__item .slider-item__body a{
        display: inline;
    }
    .video-wide .video-overlay{
        top: 100%;
        -webkit-transform: translateY(-110%) translateX(-50%);
            -ms-transform: translateY(-110%) translateX(-50%);
                transform: translateY(-110%) translateX(-50%);

    }
    .video-teaser .video-overlay {
        top: 50%;
    }
    .mfp-arrow{
        margin-top: -55px;
    }
    .page-heading .page-heading__location.mt15{
        margin-bottom: 10px;
    }
    .touren-teaser__img {
        margin-bottom: 20px;
    }

    .page-heading .price-badge {
        padding: 5px 15px;
        margin-top: -10px;
        margin-bottom: 10px;
    }

    .slide .decorated-hr{
        width: 218px;
    }

    .infomax-container{
        width: 100%;
        height: 200px;
        background: #fff;
        border: 1px solid #ddd;
        margin-top: 20px;
    }
    .content-accordion .panel-body{
        padding: 10px 0;
    }
     .text-teaser__body a.btn{
         position: absolute;
         bottom:20px;
         left:20px;
         right: 20px;
     }
    .img-text-slide{
        margin-bottom: 20px;
    }

    .img-text-slide__body blockquote{
        font-size: 15px;
    }

    .content-teaser__desc{
        display: none;
    }

    .content-teaser--brochure .content-teaser__desc{
        display: block;
    }

    .content-teaser-slider .slick-slide{
        margin: 0 10px;
    }

    .content-teaser-slider .arrow-wrapper.arrow-wrapper--teaser{
        height: 40px;
        width: 25px;
        background-color: #666;
        top: 115px;
        -webkit-transform: none;
            -ms-transform: none;
                transform: none;
    }

    .content-teaser-slider .arrow-wrapper .icon{
        opacity: 1;
        font-size: 15px;
    }

    .content-teaser-slider .arrow-wrapper.arrow-left {
        left: -5px;
    }

    .content-teaser-slider .arrow-wrapper.arrow-right {
        right: -5px;
    }

    .arrow-wrapper.arrow-wrapper--teaser.arrow-left .icon {
        left: 8px;
    }

    .arrow-wrapper.arrow-wrapper--teaser.arrow-right .icon {
        right: 8px;
    }
    .storytelling-grid .row--vertical-gutter-30:first-child{
        margin-top: -30px;
    }
    .storytelling-grid .row--vertical-gutter-30{
        margin-top: 0;
    }
    .list-sponsors>li img{
        width: 90px;
    }

    .list-sponsors>li {
        margin: 10px 20px;
    }
    .contact-teaser__body{
        padding: 15px;
    }
    .arrow-wrapper.arrow-wrapper--teaser.arrow-weather{
        background-color: rgba(86, 86, 86, 0.6);
        height: 40px;
        width: 30px;
    }

    .arrow-wrapper.arrow-weather .icon{
        font-size: 18px;
    }

    .weather-teaser .weather-item__icon{
        height: 80px;
        width: 80px;
        float: left;
        margin-top: 15px;
    }

    .weather-teaser .weather-slider .weather-item__icon{
        margin-top: 0;
    }

    .weather-teaser{
        margin-bottom: 20px;
    }

    .weather-teaser .day-time{
        font-style: normal;
        font-family: SourceSansPro-Bold, Arial;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .avalanche-text{
        margin-bottom: 15px;
    }
    .weather-teaser-simple .icon-weather{
        height: 110px;
        width: 110px;
    }
    .arrange-block-xs .arrange-sizeFit,
    .arrange-block-xs .arrange-sizeFill{
        display: block;
    }
    /* No Arrange xs */
    .no-arrange-xs.arrange>.arrange-sizeFill,
    .no-arrange-xs.arrange>.arrange-sizeFit{
        display: block;
    }

    /* Arrange xs */

    .arrange-xs{
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        display: table !important;
        margin: 0;
        min-width: 100%;
        padding: 0;
        table-layout: auto;
    }

    .no-arrange-xs .arrange-width--33{
        width: 100%;
    }
    .status-slide .status-slide__mountain {
        text-align: center;
    }

    .status-slide .mountain-figure__mountain {
        position: absolute;
        top: 22px;
        left: 190px;
        right: auto;
        width: 118px;
    }

    .status-slide .mountain-figure__mountain .icon {
        font-size: 17px;
        top: -25px;
        left: 0;
    }

    .status-slide .mountain-figure__tal {
        left: -10px;
        width: 70px;
    }

    .status-slide .mountain-figure__tal .icon {
        margin-right: 10px;
        font-size: 17px;
    }
    .nav-tabs.nav-tabs--scroll{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 5px;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        overflow-x: auto;
        padding-bottom: 10px;
        margin: 0 -5px;
    }
    .nav-tabs.nav-tabs--scroll li a{
        white-space: nowrap;
    }
    .nav-tabs.nav-tabs--scroll>li.active>a:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid #d7001e;
        left: 50%;
        margin-left: -9px;
        bottom: -10px;
        z-index: 10;
    }

    .filter-sidebar .btn{
        margin-bottom: 30px;
    }
    .row--inline-block--middle>.col.filter-paging-bar__item{
        text-align: center;
    }

    .filter-paging-bar .pagination{
        margin: 10px 0;
    }
    .img-poi{
        display: none;
    }
    .routenplaner .btn{
        margin-left: 0;
        margin-top: 10px;
    }
    .information-hint__body{
        padding: 60px 20px 15px;
    }
    .information-hint{
        top: auto;
        right: 0;
        left: 0;
        margin: 80px 10px auto;

        opacity: 0.95;
        overflow-y: scroll;
        max-height: 90%;
        width: auto;
        -webkit-overflow-scrolling: touch;
    }

    .information-hint .h2 {
        font-size: 20px;
    }

    .information-hint .decorated-hr{
        width: 260px;
    }

    /*.information-hint .decorated-hr,
    .information-hint .information-hint__desc{
        display: none;
    }*/

    .information-hint__close {
        font-size: 40px;
        right: 10px;
        top: 10px;
    }
    .darkpage__top{
        margin: 20px;
    }

    .darkpage__top img{
        width: 47px;
    }

    .darkpage__close{
        right: 20px;
        top: 20px;
        z-index: 1;
    }

    .darkpage__body .h2{
        margin-top: 50px;
    }
    .mfp-partner .lightbox__close-bar {
        top: 20px;
        right: 20px;
    }
    .expand-search{
        background-image: -webkit-gradient(linear,left top, left bottom,color-stop(0, #f8f9fa),to(white));
        background-image: -webkit-linear-gradient(top,#f8f9fa 0,white 100%);
        background-image: -o-linear-gradient(top,#f8f9fa 0,white 100%);
        background-image: linear-gradient(180deg,#f8f9fa 0,white 100%);
        -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
                box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
        position: absolute;
        top: 49px;
        left: -1px;
        right: -1px;
        border-top: 1px solid #d7001e;
    }

    .navbar-microsite .expand-search{
        top:86px;
    }

    .expand-search .expand-search__input:focus{
        background: #fff;
    }

    .expand-search__body{
        padding: 60px 20px 25px 20px;
    }

    .expand-search__form{
        position: relative;
    }

    .expand-search .expand-search__input{
        height: 40px;
        background-color: #e5e5e5;
        border: 1px solid #bec1c2;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
        position: relative;
    }

    .expand-search .navbar-search__btn {
        border: none;
        height: 40px;
        width: 40px;
        position: absolute;
        right: 0;
        top: 0;
        background: #d7001e;
        color: #fff;
    }

    .expand-lang {
        background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f8f9fa), to(white));
        background-image: -webkit-linear-gradient(top, #f8f9fa 0, white 100%);
        background-image: -o-linear-gradient(top, #f8f9fa 0, white 100%);
        background-image: linear-gradient(180deg, #f8f9fa 0, white 100%);
        -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, .15);
                box-shadow: 0 0 8px rgba(0, 0, 0, .15);
        position: absolute;
        top: 49px;
        left: -1px;
        right: -1px;
        border-top: 1px solid #d7001e;
    }

    .expand-lang__body {
        padding: 20px 20px 10px;
    }

    .expand-lang__item{
        font-family: Oswald-Regular, Arial;
        font-size: 18px;
        color: #7d7d80;
        padding: 10px 0;
    }

    .expand-lang-list>li+li{
        border-top: 1px solid #d9d9d9;
    }

    .expand-lang__item .lang-flag{
        margin-right: 7px;
    }

    .lang-mobile{
        font-family: SourceSansPro-Bold, Arial;
        font-size: 18px;
        padding: 10px 10px;
        position: relative;
    }

    .lang-open .lang-mobile:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #d7001e;
        left: 50%;
        margin-left: -6px;
        bottom: -11px;
        z-index: 10;
    }

    .lang-mobile .lang-flag{
        width: 21px;
        height: 15px;
        margin-left: 0;
        position: relative;
        top:1px;
    }

    .lang-mobile .lang-flag.flag-en{
        background-position: 0 -27px;
    }

    .lang-mobile .lang-flag.flag-nl {
        background-position: 0 -41px;
    }

    .lang-mobile .lang-flag.flag-fr {
        background-position: 0 -13px;
    }
    .top-event-slider {
        padding-top:20px;
    }
    .top-event-slider .arrow-wrapper .icon{
        font-size: 15px;
    }

    .top-event-slider .arrow-wrapper.arrow-left{
        left:-5px;
    }

    .top-event-slider .arrow-wrapper.arrow-right{
        right:-5px;
    }

    .top-event-slider .arrow-wrapper.arrow-wrapper--teaser.arrow-left .icon {
        left: 5px;
    }

    .top-event-slider .arrow-wrapper.arrow-wrapper--teaser.arrow-right .icon {
        right: 5px;
    }

    .top-event-slider .arrow-wrapper.arrow-wrapper--teaser{
        height: 40px;
        width: 20px;
    }
    .perfect-day__info-box .show-more {
        padding: 15px;
        font-size: 18px;
    }
    .perfect-day__links-right>a:not(:last-child){
        margin-bottom: 10px;
    }
    .perfect-day__info-box {
        position: static;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
                flex-direction: column-reverse;
    }

    .perfect-day__timeline {
        display: none;
    }

    .perfect-day__hl {
        font-size: 30px;
        line-height: 40px;
        margin-top: 85px;
    }

    .perfect-day__more-infos {
        position: static;
    }

    .perfect-day__more-infos {
        width: 100%;
    }

    .perfect-day__info-text {
        padding-right: 0;
    }

    .perfect-day__more-infos {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        padding: 30px 20px;
    }

    .perfect-day__links-bottom {
        margin-top: 40px;
    }

    .perfect-day__links-right {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }

    .perfect-day__links-right .link-primary
    {
        display: inline-block;
        padding: 10px 15px;
    }

    .perfect-day__links-right .link-primary+.link-primary {
        margin-top: 0;
    }

    .perfect-day__links-right .link-primary>span.icon {
        margin-right: 10px;
    }

    .perfect-day__close-icon {
        display: none;
    }

    .perfect-day__info-box .show-more {
        text-align: left;
    }

    .perfect-day__info-box .show-more:after {
        content: "\E018";
        font-family: iconfont;
        font-size: 9px;
        position: absolute;
        right: 15px;
        -webkit-transition: -webkit-transform 500ms;
        transition: -webkit-transform 500ms;
        -o-transition: transform 500ms;
        transition: transform 500ms;
        transition: transform 500ms, -webkit-transform 500ms;
        -webkit-transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
                transform: rotate(-180deg);
    }

    .perfect-day__info-box .show-more.collapsed:after {
        -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
                transform: rotate(0deg);
    }


    /* ---------- DOTS --------------- */


    .perfect-day-slider .perfect-day__time-item.slick-slide {
        width: 30vw;
    }

    .perfect-day-slider .dots-wrapper {
        text-align: center;
        height: 100%;
        padding-top: 4px;
        width: auto;
    }

    .perfect-day-slider .perfect-day__time-item button {
        position: relative;
        font-size: 0;
        width: 10px;
        height: 10px;
        border: none;
        border-radius: 50%;
        margin: 0 5px;
        background: #fff;
        padding: 0;
    }

    .perfect-day-slider.js-perfect-day-slider-times {
        margin-bottom: 0;
        width: 100%;
        height: 60px;
        background: rgba(0,0,0,.55);
        border-top: 3px solid #d9dadb;
        margin-top: -60px;
        padding: 0 25px;
    }

    .perfect-day-slider.js-perfect-day-slider-times .slick-track,
    .perfect-day-slider.js-perfect-day-slider-times .slick-list {
        height: 100%;
    }

    .perfect-day-slider .perfect-day__time-item.slick-current button {
        background: #d7001e;
    }

    .perfect-day-slider .perfect-day__time-item.slick-current button:before {
        content: '';
        position: absolute;
        bottom: auto;
        left: 40%;
        top: 10px;
        width: 2px;
        height: 16px;
        background: #d7001e;
        border-radius: 0;
    }

    .perfect-day-slider .perfect-day__time-item .perfect-day__time {
        font-family: Oswald-Regular, Arial;
        width: 100%;
    }

    .perfect-day-slider .perfect-day__time-item.slick-current .perfect-day__time {
        color: #d7001e;
    }


    /* ----------- ARROWS -------------- */

    .perfect-day-slider .arrow-wrapper {
        z-index: 100;
        height: 58px;
        width: 25px;
        top: auto;
        bottom: -29px;
    }

    .perfect-day-slider .arrow-wrapper.arrow-right .icon {
        right: 4px;
    }

    .perfect-day-slider .arrow-wrapper.arrow-left .icon {
        left: 4px;
    }
    .single-facility-teaser{
        margin-top: 15px;
    }
    .single-facility-teaser__hl{
        font-size: 18px;
    }

    .facility-list>li{
        border-left:none;
        width: 100%;
        margin-bottom: 20px;
    }

    /*same height cols*/
    .row--same-height-xs .row--same-height__item,
    .row--same-height--xs.slick-initialized .row--same-height__item.slick-slide {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--same-height-xs{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }

    .row--same-height-xs .slick-track{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }

    .row--same-height-xs:before,
    .row--same-height-xs:after{
        display: none;
    }

    .row--same-height-xs.overflow-visible{
        overflow: visible;
    }

    .row--same-height-xs > .col,
    .row--same-height-xs.slick-slider .col,
    .row--same-height-xs.slick-slider .slick-slide {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: auto;
    }

    .flip-clock-wrapper {
        max-width: 330px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .flip-clock-wrapper ul {
        width: 26px;
        height: 42px;
        padding: 10px;
    }

    .flip-clock-wrapper ul li {
        line-height: 44px;
    }

    .flip-clock-wrapper ul li a div div.inn {
        font-size: 22px;
    }

    .flip-clock-divider .flip-clock-label {
        left: 17px;
        top: 56px;
        font-size: 14px;
    }

    .flip-clock-wrapper ul li a div.up:after {
        top: 21px;
    }

    .flip-clock-divider {
        height: 80px;
        width: 10px;
    }

    .social-sharing__wrapper { width:auto; }
  .head-sujet__btn{
    padding: 16px 20px;
    font-size: 17px;
  }
   .headline-block__hl{
     margin-bottom: 10px;
   }
  .accommodation-teaser .link-primary{
    margin-top: 15px;
    font-size: 15px;
    display: inline-block;
  }

  .btn.accommodation-teaser__btn{
    font-size: 17px;
    padding: 16px 20px;
  }

  .acco-console__nav { top: auto; bottom: 0; left: 100%; -webkit-transform: rotate(90deg) translateY(-50%) translateX(-100%); -ms-transform: rotate(90deg) translateY(-50%) translateX(-100%); transform: rotate(90deg) translateY(-50%) translateX(-100%); }
  .acco-console__nav .console__item{
    line-height: 17px;
    font-size: 13px;
    padding: 7px 5px;
  }
  .acco-console__content { padding-top: 30px; }
  .header-img-text-block__text-block{
    margin-top: 50px;
  }
   .header-top-bar{
     padding-left: 90px;
   }
  .imx-search-form .btn{
    height: 40px;
  }
  .imx-search-teaser__hl{
    margin-top: 10px;
  }

  .imx-search-teaser{
    padding-bottom: 1.25rem;
    border-bottom: 1px solid #ddd;
  }

  .contact-person-teaser{ margin: 0 10px; }

  .contact-person-teaser__contact-item+.contact-person-teaser__contact-item{ margin-top: 5px; }
   .contact-person-area .arrow-wrapper.arrow-wrapper--teaser{
       height: 40px;
       width: 30px;
       background-color: #666;
   }

    .contact-person-area .arrow-wrapper.arrow-left{
        left: -6px;
   }

    .contact-person-area .arrow-wrapper.arrow-right{
        right: -6px;
    }

    .contact-person-area .arrow-wrapper .icon{
        font-size: 18px;
        margin-top: 0;
        top: 21px;
        color: #fff;
        opacity: 1;
    }

    .brand-person-teaser__name{ font-size: 15px; }

    .brand-person-teaser__title{ display: none; }

    .brand-person-teaser__link{ font-size: 14px; }

    .brand-person-teaser__link .link-primary>span.icon{ font-size: 13px; }

    .quote-item__img{ margin-left: auto; margin-right: auto; }
    .modal-dialog{
        margin: 50px 20px 20px;
    }
    .consent__overlay-text{
        font-size: 10px;
    }
    .col-xs-6 .embed-responsive-video-portrait .consent__overlay-text{
        font-size: 15px;
    }

    .col-xs-6 .embed-responsive-video-portrait .consent__overlay-title{
        font-size: 17px;
        margin-top: 10px;
    }

    .btn{
        margin-top: 15px;
    }

    .teaser-grid-big .pagination-top .pagination{ margin-bottom: 10px; }

    .teaser-grid-big .pagination-bottom .pagination{ margin-bottom: 0; margin-top: 10px; }

    .authors-slider .arrow-wrapper.arrow-wrapper--teaser{ background-color: #666666; height: 40px; width: 20px; }

    .authors-slider .arrow-wrapper.arrow-wrapper--teaser.arrow-left{ left: -15px; margin-top: -25px; z-index: 2; }

    .authors-slider .arrow-wrapper.arrow-wrapper--teaser.arrow-right{ right: -15px; margin-top: -25px; z-index: 2; }

    .authors-slider .arrow-wrapper.arrow-wrapper--teaser.arrow-left .icon{ font-size: 13px; opacity: 1; left: 6px; margin-top: 1px; }

    .authors-slider .arrow-wrapper.arrow-wrapper--teaser.arrow-right .icon{ font-size: 13px; opacity: 1; right: 6px; }

    .image-text-sujet__hl{ margin-bottom: 10px; text-align: center; }

    .img-teaser .icon-text .icon{ display: none; }

    .author-sujet__img{ text-align: center; }

    .author-sujet__hl{ margin-top: 40px; text-align: center; }

    .author-sujet__social{ text-align: center; }

    .author-sujet .wysiwyg{ font-size: 17px; line-height: 1.47059; }

    .author-teaser .btn.btn-bold{ width: 100%; }
   .article-detail-bar .icon-text .icon{
       font-size: 26px;
   }
   .img-landscape__container{ padding: 0 15px; }
   .img-portrait__container{ padding: 0 15px; }
   .article-img-text-slide__hl{ margin-top: 10px; }
   .article-checklist__hl { margin-top: 10px; }
   .article-category-teaser-slide .arrow-wrapper.arrow-wrapper--teaser{
       top: auto;
       bottom: 37%;
   }
   .article-category-slide{ background-color: #f2f4f5; height: 100%; overflow: hidden; }
   .article-category-slide .link-teaser__hl{ bottom: 20px; left: 20px; right: 20px; }
   .article-category-slide .link-teaser__icon{ top: 20px; left: 20px; right: 20px; font-size: 30px; }
    .region--silvretta .nav-tabs.nav-tabs--primary>li.active>a,
    .region--silvretta .nav-tabs.nav-tabs--primary>li.active>a:hover,
    .region--silvretta .nav-tabs.nav-tabs--primary>li.active>a:focus,
    .region--silvretta .nav-tabs.nav-tabs--primary>li>a:hover{
        border-bottom: 2px solid #E52A1E;
    }
    .region--silvretta .nav-tabs.nav-tabs--scroll>li.active>a:before{
        border-top-color: #E52A1E;
    }
    .region--raetikon .nav-tabs.nav-tabs--primary>li.active>a,
    .region--raetikon .nav-tabs.nav-tabs--primary>li.active>a:hover,
    .region--raetikon .nav-tabs.nav-tabs--primary>li.active>a:focus,
    .region--raetikon .nav-tabs.nav-tabs--primary>li>a:hover{
        border-bottom: 2px solid #FAB118;
    }
    .region--raetikon .nav-tabs.nav-tabs--scroll>li.active>a:before{
        border-top-color: #FAB118;
    }
    .region--verwall .nav-tabs.nav-tabs--primary>li.active>a,
    .region--verwall .nav-tabs.nav-tabs--primary>li.active>a:hover,
    .region--verwall .nav-tabs.nav-tabs--primary>li.active>a:focus,
    .region--verwall .nav-tabs.nav-tabs--primary>li>a:hover{
        border-bottom: 2px solid #4BA927;
    }
    .region--verwall .nav-tabs.nav-tabs--scroll>li.active>a:before{
        border-top-color: #4BA927;
    }
    .region--tal .nav-tabs.nav-tabs--primary>li.active>a,
    .region--tal .nav-tabs.nav-tabs--primary>li.active>a:hover,
    .region--tal .nav-tabs.nav-tabs--primary>li.active>a:focus,
    .region--tal .nav-tabs.nav-tabs--primary>li>a:hover{
        border-bottom: 2px solid #6EC3BF;
    }
    .region--tal .nav-tabs.nav-tabs--scroll>li.active>a:before{
        border-top-color: #6EC3BF;
    }
    .tour-detail-info__month{ min-width: 100px; } }
@media screen and (min-width: 768px) {
   .hidden-sm{
       display: none!important;
   }

    .mb30 { margin-bottom:30px; }
    .mb40 { margin-bottom:40px; }
    .mb50 { margin-bottom:50px; }
    .mb80 { margin-bottom:80px; }

    .mt30 { margin-top:30px; }
    .mt40 { margin-top:40px; }
    .mt50 { margin-top:50px; }
    .mt60 { margin-top:60px; }

    .mr30{ margin-right: 30px; }


    .text-right-sm{
        text-align: right;
    }
    .col-sm-20perc{
        width:21%;
    }
    .ankerinfo:target {
        padding-top: 80px;
        margin-top: -80px;
    }
    .fz60{ font-size: 60px; }
    .d-sm-flex{ display: -webkit-box; display: -ms-flexbox; display: flex; }
    .page-wrapper:before {
        top: 100px;
    }
    .page-wrapper {
        padding-top: 100px;
        padding-right: 100px;
    }

    .row{
        margin-left: -10px;
        margin-right: -10px;
    }

    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .pimcore_area_content{
        margin: 35px 0;
    }

    .pimcore_area_separator.pimcore_area_content{
        margin: 45px 0;
    }

    .contentblock,
    .content-block{
        margin: 40px 0;
    }
    /*.content-block{*/
        /*margin: 60px 0;*/
    /*}*/
    .contentblock-small{
        margin: 30px 0;
    }
    body {
        font-size: 17px;
        line-height: 1.47059;
    }
    h1, .h1 {
        font-size: 35px;
        line-height: 1.25714;
    }
    h2, .h2 {
        font-size: 35px;
        line-height: 1.25714;
    }
    h3, .h3 {
        font-size: 22px;
        line-height:1.36364;
    }
    h4, .h4 {
        font-size: 20px;
        line-height:1.4;
    }

    h5, .h5 {
        font-size: 20px;
        line-height:1.1;
    }

    h6, .h6{
        font-size: 18px;
        line-height:1.44444;

    }

    .fz30 { font-size: 18px; }
    .fz20 { font-size: 20px; }
    .fz24 { font-size: 24px; }
    .wysiwyg .floated-item.pull-left{
        margin-right: 20px;
        overflow: hidden;
    }

    .wysiwyg .floated-item.pull-right{
        margin-left: 20px;
        overflow: hidden;
    }

    .embed-responsive.embed-responsive-img-teaser-vertical { padding-top: 211%; }
    .embed-responsive.embed-responsive-intermaps-map {
        padding-top: 47.266%;
    }

    .embed-responsive.embed-responsive-big {
        padding-top: 47.24%;
    }

    .embed-responsive.embed-responsive-3by1-on-sm {
        padding-top: 33.33%;
    }

    .embed-responsive.embed-responsive-2by1-on-sm {
        padding-top: 50%;
    }

    .embed-responsive.embed-responsive-4by1-on-sm{
        padding-top: 25%;
        padding-bottom: 0;
    }

    .embed-responsive-article-category-top-img{ padding-top: 32.679%; }
    .btn{
        font-size: 16px;
        line-height: 17px;
        padding: 10px 17px;
    }

    .btn.btn-lg{
        padding: 12px 20px;
    }

    .btn.btn-bold{ font-size: 22px; padding: 10px 25px; }

    .btn.btn-bold>.icon{ font-size: 35px; vertical-align: -7px; }
    .navbar.navbar-main {
        height: 100px;
        position: fixed;
        border-radius: 0;
    }

    .navbar.navbar-main.affix{
        height: 85px;
    }

    .navbar-main.navbar-microsite{
        height: 141px;
    }
    .container>.navbar-header{
        margin: 0;
        height: auto;
    }

    .navbar>.container .navbar-brand{
        margin-left: 0;
    }

    .navbar-brand>img{
        max-height: 136px;
    }

    .navbar-main .navbar-brand{
        width: 100%;
        -webkit-transition: all 0.15s linear;
        -o-transition: all 0.15s linear;
        transition: all 0.15s linear;
    }

    .navbar-main.affix .navbar-brand{
        width: 86.5%;
    }

    .navbar-nav__icon{
        display: block;
        margin-bottom: 10px;
        font-size: 24px;
        height:24px;
    }

    .navbar-main.affix .navbar-nav__icon{
        margin-bottom: 5px;
    }

    .navbar-nav__icon.icon-biken{
        font-size: 20px;
    }

    .navbar-nav>li{
        position: static;
    }

    .navbar-nav>li.single-dropdown{
        position: relative;
    }

    .navbar-nav>li.single-dropdown .navbar-nav__dropdown{
        min-width: 250px;
        left: -50%;
        padding: 10px 20px;
    }

    .navbar-nav>li.single-dropdown:first-child .navbar-nav__dropdown{
        left:0;
    }

    .navbar-default .navbar-nav>li>a>.subnav-toggle{
        display: none;
    }

    .navbar-main .navbar-nav{
        margin-left: 6px;
    }

    .navbar-main.affix .navbar-nav{
        margin-left: -6px;
    }

    .navbar-nav>li>a:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1px;
        height: 60px;
        background: #e6e6e6;
        top: 50%;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
    }

    .navbar-no-icons .navbar-nav>li>a:after{
        display: none;
    }

    .navbar-default.navbar-no-icons .navbar-nav>li>a{
        padding: 39px 9px;
        font-size: 14px;
    }

    .navbar-nav>li.has-dropdown:hover>a:before,
    .navbar-nav>li.has-dropdown.is-open>a:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid #d7001e;
        left: 50%;
        margin-left: -9px;
        bottom: -7px;
        z-index: 10;
    }

    .navbar-nav>li:hover .navbar-nav__dropdown,
    .navbar-nav>li.is-open .navbar-nav__dropdown{
        display: block;
    }

    .navbar-nav .navbar-nav__dropdown{
        background: -webkit-gradient(linear, left top, left bottom, from(#f8f9fa), to(white));
        background: -webkit-linear-gradient(top, #f8f9fa 0%, white 100%);
        background: -o-linear-gradient(top, #f8f9fa 0%, white 100%);
        background: linear-gradient(180deg, #f8f9fa 0%, white 100%);
        -webkit-box-shadow: 0 -1px 0 #d7001e, 0 0 8px rgba(0, 0, 0, 0.15);
                box-shadow: 0 -1px 0 #d7001e, 0 0 8px rgba(0, 0, 0, 0.15);
    }

    .navbar-nav__dropdown ul{
        margin-top: 15px;
    }

    .navbar-nav__dropdown__hl{
        font-size: 18px;
        padding-bottom: 15px;
    }

    .navbar-nav__dropdown__hl.has-border{
        border-bottom: 1px solid #d9dadb;
    }
    .navbar-nav .navbar-nav__dropdown ul>li{
        margin-bottom: 8px;
    }

    .navbar-nav .navbar-nav__dropdown ul>li:before{
        top: 2px;
    }
    .navbar-right .btn-webshop{
        margin-right: 10px;
    }
    .navbar-right .navbar-search .tt-menu{
        right: 0;
        left: auto!important;
    }
    .navbar-main .container--nav,
    .navbar-main .navbar-collapse{
        height: 85px!important; /* overwrite bootstrap navbar-collapse style */
    }
  .navbar-lp.affix .navbar-lp-brand img{
    -webkit-transform: scale(0.92);
        -ms-transform: scale(0.92);
            transform: scale(0.92);
  }
    .breadcrumb-wrapper{
        padding: 5px 120px 5px 0;
    }

    html.console-open,
    html.console-open body{
        overflow: hidden;
    }

    .console{
        position: fixed;
        right: 0;
        bottom: 0;
        top: 100px;
        color: #fff;
        z-index: 6;

        width: 880px;
        -webkit-transform: translateX(780px);
            -ms-transform: translateX(780px);
                transform: translateX(780px);
        -webkit-transition: -webkit-transform 500ms ease;
        transition: -webkit-transform 500ms ease;
        -o-transition: transform 500ms ease;
        transition: transform 500ms ease;
        transition: transform 500ms ease, -webkit-transform 500ms ease;
    }

    .console.console-microsite{
        top: 141px;
    }

    .console.is-open{
        -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
                transform: translateX(0);
    }

    .console.affix{
        top:82px;
    }

    .console.console-microsite.affix{
        top: 123px;
    }

    .console .icon-weather{
        width: 110px;
        height: 110px;
    }

    /*.console:before {*/
    /*content: '';*/
    /*position: absolute;*/
    /*!* background: #fc0; *!*/
    /*top: 0;*/
    /*bottom: 0;*/
    /*left: -250px;*/
    /*width: 0;*/
    /*background: linear-gradient(to right, transparent 0%, rgba(0, 0, 0, 0.5) 100%);*/
    /*opacity: 0;*/
    /*transition: opacity 500ms ease;*/

    /*}*/

    /*.console.is-open:before {*/
    /*opacity: 1;*/
    /*width: 250px;*/
    /*}*/

    .console-nav{
        width: 100px;
        height: 100%;
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
    }


    .nav-tabs.nav-tabs--console{
        border:none;
    }

    .nav-tabs.nav-tabs--console>li {
        float: none;
        margin-bottom: 0;
    }

    .nav-tabs.nav-tabs--console>li>a{
        border: none;
        border-radius: 0;
        margin: 0;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        border-bottom: 1px solid #58585a;
        padding: 18px 10px;
        color: #d9dadb;
    }

    .nav-tabs.nav-tabs--console>li>a.console-weather{
        padding: 10px 20px;
    }

    .nav-tabs.nav-tabs--console>li>a:hover,
    .nav-tabs.nav-tabs--console>li.active>a,
    .nav-tabs.nav-tabs--console>li.active>a:hover,
    .nav-tabs.nav-tabs--console>li.active>a:focus{
        border: none;
        background-color: #f2f4f5;
        color: #2b2c2c;
        border-bottom: 1px solid #58585a;
    }

    .nav-tabs.nav-tabs--console>li>a>.icon{
        display: block;
        font-size: 25px;
        margin-bottom: 10px;
    }

    .nav-tabs.nav-tabs--console>li>a .icon-weather{
        height: 55px;
        width: 55px;
    }

    .tab-content--console .tab-pane{
        position: absolute;
        right: 0;
        left: 100px;
        top: 0;
        bottom: 0;
        background: #f2f4f5;
        z-index: 4;
        color: #323233;
        padding: 30px 40px;
        overflow-y: scroll;
    }

    .tab-content--console .tab-pane.active{
    }

    .close-console {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #7d7d80;
        cursor: pointer;
        font-size: 28px;
    }

    .console .col__quickfinder-console {
        margin-left: 25%;
    }

    .tab-content--console .tab-pane .table-status{
        margin-bottom: 30px;
    }

    .console .progress.progress--primary{
        height: 15px;
        width: 70%;
    }

    .console .status-slide{
        margin: 30px 0;
    }
    .console .status-slide .snow-value{
        font-size: 20px;
    }

    .console .weather-item__temp{
        font-size: 28px;
    }
    .footer:not(.footer--fullwidth){
        padding-right: 100px;
    }
    .footer__social{
        padding: 60px 0;
    }

    .footer__social ul{
        max-height: 105px;
        margin-bottom: 0;
    }

    .footer__social ul>li{
        margin: 0 10px;
    }
    .circle-social .icon.icon-soc-youtube{
        margin-left: -1px;
        font-size: 15px;
    }
    .footer__basic{
        padding: 40px 0;
    }
    .footer__basic .meta-links{
        float: right;
    }
    .footer__basic .link-list>li{
        padding-right: 25px;
    }

    .footer__basic .link-list>li:after{
        right: 3px;
    }
    .slide{
        padding: 40px 0;
    }
    .arrow-wrapper{
        width: 85px;
    }
    .arrow-wrapper .icon {
        font-size: 40px;
    }

    .arrow-wrapper.arrow-left .icon{
        left: 33px;
    }

    .arrow-wrapper.arrow-right .icon{
        right: 33px;
    }

    .portal-header-slider .slick-dots,
    .content-slider .slick-dots,
    .teaser-slider .slick-dots,
    .top-event-slider .slick-dots,
    .tour-slider .slick-dots,
    #webcam-panorama-slider .slick-dots,
    #webcam-slider .slick-dots{
        display: none !important; /** Overwrite inline slick style */
    }

    .teaser-slider .slick-list {
        margin:0 auto;
    }
    .initialized .portal-header-slider__item img{
        -webkit-transform: translateX(0) scale(1.09);
            -ms-transform: translateX(0) scale(1.09);
                transform: translateX(0) scale(1.09);
        -webkit-transform-origin: 60% center;
            -ms-transform-origin: 60% center;
                transform-origin: 60% center;
        -webkit-transition: -webkit-transform 3s ease-out;
        transition: -webkit-transform 3s ease-out;
        -o-transition: transform 3s ease-out;
        transition: transform 3s ease-out;
        transition: transform 3s ease-out, -webkit-transform 3s ease-out;
    }
    .portal-header-slider__item .slider-item__body{ pointer-events: none; }
    .portal-header-slider__item .slider-item__body {
        max-width: 700px;
        top:120px;
        left:150px;
        padding: 0;
        font-size: 20px;
        text-align: left;
    }

    .portal-header-slider__item h1, .portal-header-slider__item .h1{
        font-size: 80px;
        line-height: 85px;
        margin-bottom: 25px;
    }
    .portal-header-slider__item .video-wide .embed-responsive-video {
        position: initial;
    }
    .content-header-image__body--fullwidth-bottom{
        bottom:70px;
    }
    .content-header-image__body .h1{
        font-size: 80px;
        line-height: 85px;
    }

    .content-header-image__body .h3{
        margin: 15px 0 25px;
    }
    .vjs-default-skin .vjs-big-play-button{
        width: 100px;
        height: 100px;
        font-size: 35px;
        margin-top: -50px;
        margin-left: -50px;
    }

    .vjs-default-skin .vjs-big-play-button:before{
        left: 3px;
    }
    .content-header .video-play,
    .content-slider__item .video-play{
        font-size: 36px;
        height: 100px;
        width: 100px;
    }
    .video-wide:hover .video-overlay,
    .image-teaser-slide--video:hover .video-overlay,
    .video-teaser:hover .video-overlay{
        border-color: #fff;
        -webkit-box-shadow: 0 0 3em #fff;
                box-shadow: 0 0 3em #fff;
        -webkit-transition: all 0s;
        -o-transition: all 0s;
        transition: all 0s;
        cursor: pointer;
    }
    .video-wide .video-overlay,
    .video-teaser .video-overlay{
        top: 50%;
        font-size: 36px;
        height: 100px;
        width: 100px;
    }
    .video-wide .video-overlay .icon,
    .video-teaser .video-overlay .icon{
        top: 29px;
        left: 4px;
    }
    .content-video-overlay{ width: 100px; height: 100px; }
    .content-video-overlay .icon{ font-size: 30px; }
    .mfp-image-holder .mfp-content{
        border: 60px solid #fff;
        border-bottom: 0px solid #fff;
    }

    .mfp-content img.mfp-img{
        padding: 0;
    }


    .mfp-arrow{
        width: 40px;
        height: 60px;
    }

    .mfp-arrow:before{
        font-size: 25px;
    }


    .mfp-arrow-left{
        left: -60px;
    }

    .mfp-arrow-right{
        right: -60px;
    }


    .mfp-arrow-left:before{
        left: 25px;
        top: 40px;
    }

    .mfp-arrow-right:before{
        left: 13px;
        top: 17px;
    }
    .decorated-hr {
        width: 610px;
    }
    .decorated-hr.decorated-hr--golmi:before {
        width: 260px;
    }

    .decorated-hr.decorated-hr--golmi:after {
        width: 260px;
    }


    .decorated-hr.decorated-hr--golmi img{
        height: 50px;
    }
    .page-heading--box-top {
        padding:24px 38px;
        position: relative;
        top:-80px;
        margin-bottom: -40px;
        background-color: #FFFFFF;
        -webkit-box-shadow: 0 0 10px 0 rgba(0,0,0,0.17);
                box-shadow: 0 0 10px 0 rgba(0,0,0,0.17);
        z-index: 1;
    }
   .h4.page-heading__infra-sub{
       font-size: 30px;
   }  
    
    .page-heading__location-text{
        font-size: 17px;
    }

    .page-heading__location-icon{
        font-size: 25px;
        vertical-align: -6px;
    }

    .content-img-big{
        max-width: 990px;
        margin: 0 auto;
    }

    /*.heading{*/
        /*margin-bottom: -30px;*/
    /*}*/

    .infomax-container{
        width: 100%;
        max-width: 930px;
        height: 318px;
        background: #fff;
    }
    .content-accordion .panel-title>a{
        padding: 13px 15px;
        font-size: 24px;
        line-height: 1.29167;
        padding-right: 60px;
    }
    .content-accordion .panel-title>a .accordion__close{
        width: 57px;
    }
    .content-slider__item .slider-item__body{
        padding: 20px;
        padding-top: 100px;
    }

    .content-slider .arrow-wrapper.arrow-right{
        width: 89px;
    }
    .image-box__body{
        padding: 30px;
    }

    .image-box__desc{
        margin: 20px 0;
    }
    .snippet-teaser-slide__desc{
        margin: 20px 0;
    }
    .image-teaser-slide__body h2{
        font-size: 40px;
        line-height: 45px;
    }

    .image-teaser-slide__desc{
        margin: 20px 0;
    }
    /*.img-text-slide__center{*/
        /*position: absolute;*/
        /*top: 50%;*/
        /*transform: translateY(-50%);*/
        /*max-width: 440px;*/
    /*}*/
    .storytelling-teaser.teaser-big .h4{
        font-size: 28px;
        line-height: 1.46429;
    }

    .storytelling-teaser.teaser-big .storytelling-teaser__date{
        width: 78px;
        height: 78px;
    }

    .storytelling-teaser.teaser-big .storytelling-teaser__date .date-day{
        font-size: 40px;
        line-height: 1.25;
        margin-top: 2px;
    }
    .offer-teaser-slide__additional{
        font-size: 30px;
        line-height: 1.26667;
        margin-top: 60px;
    }

     .arrow-wrapper.arrow-wrapper--teaser .icon {
        font-size: 25px;
    }

     .offer-teaser-slide .arrow-wrapper.arrow-left{ left: -37px; }

     .offer-teaser-slide .arrow-wrapper.arrow-right{ right: -37px; }
    .contact-teaser{
        padding: 30px;
        margin-bottom: 30px;
    }
    .contact-slide .contact-block{
        font-size: 20px;
    }
    .weather-item__temp{
        font-size: 40px;
        margin-left: 20px;
    }
    .weather-teaser--big>ul>li{
        border-right: 1px solid #c5c6c7;
    }

    .weather-teaser--big>ul>li:last-child{
        border:none;
    }

    .weather-teaser{
        background: #e6e7e8;
        padding: 30px 30px 35px 30px;
    }


    .avalanche-teaser{
        padding: 20px 60px;
        width: 100%;
        max-width: 990px;
        margin: 0 auto;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .avalanche-text{
        font-size: 24px;
    }

    .avalanche-text__status{
        font-size: 36px;
    }
    .table.table-status.table-status--anlagen>thead>tr>th,
    .table.table-status.table-status--anlagen>tbody>tr>th,
    .table.table-status.table-status--anlagen>tfoot>tr>th,
    .table.table-status.table-status--anlagen>thead>tr>td,
    .table.table-status.table-status--anlagen>tbody>tr>td,
    .table.table-status.table-status--anlagen>tfoot>tr>td{
        padding: 8px 15px;
        font-size: 16px;
    }
    .status-slide{
        margin: 30px 0;
    }

    .status-slide .snow-value{
        font-size: 30px;
        line-height: 1.26667;
    }
    .status-slide .mountain-figure{
        display: inline-block;
        width: 505px;
        position: relative;
    }

    .status-slide .mountain-figure .mountain-icon{
        font-size: 130px;
    }

    .status-slide .mountain-figure__tal{
        left: -8px;
        width: 145px;
    }
    .nav-tabs.nav-tabs--primary>li>a>.nav-tabs-icon {
        position: absolute;
        top: 50%;
        left: 15px;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
    }

    .nav-tabs.nav-tabs--primary>li.active>a:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 9px solid transparent;
        border-right: 9px solid transparent;
        border-top: 9px solid #d7001e;
        left: 50%;
        margin-left: -9px;
        bottom: -10px;
        z-index: 10;
    }

    .nav-tabs.nav-tabs--primary>li>a.icon-bigger{
        padding-left: 40px;
    }

    .tab-content--primary {
        margin-top: 40px;
    }
    textarea.form-control.textarea-height{
        height: 380px;
    }
    .participant-wrapper .remove-participant{
        position: relative;
        top: 32px;
    }
    .expand-search{
        position: fixed;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 10;

        width: 780px;
        -webkit-transform: translateX(780px);
            -ms-transform: translateX(780px);
                transform: translateX(780px);
        -webkit-transition: -webkit-transform 500ms ease;
        transition: -webkit-transform 500ms ease;
        -o-transition: transform 500ms ease;
        transition: transform 500ms ease;
        transition: transform 500ms ease, -webkit-transform 500ms ease;

        padding: 30px 80px;

        overflow-y: scroll;
    }

    .expand-search.is-open{
        -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
                transform: translateX(0);
    }

    .expand-search.affix{
        top:82px;
    }

    @media screen and (min-width: 1280px){
        .expand-search{
            width: 1160px;
            -webkit-transform: translateX(1160px);
                -ms-transform: translateX(1160px);
                    transform: translateX(1160px);
        }
    }

    .expand-search__form {
        position: relative;
        z-index:2;
        cursor: pointer;
        height: 40px;
    }

    .expand-search__form>label {
        cursor: pointer;
    }

    .expand-search__form .expand-search__input {
        position: absolute;
        width: 100%;
        height: 40px;
        right: 0;
        background-color: #fff;
        border: 1px solid #bec1c2;
        -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
                box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
        border-radius: 0;
        font-size: 18px;
        line-height: 23px;
    }


    .expand-search__form .navbar-search__btn{
        border: none;
        height: 40px;
        width: 40px;
        position: absolute;
        right: 0;
        /*background: var(--color-primary);*/
        /*color: #fff;*/
    }

    .expand-search__form .navbar-search__btn>span.icon{
        position: relative;
        left: -7px
    }

    .expand-search__close{
        font-size: 30px;
        color: #7d7d80;
        margin-left: 40px;
        position: relative;
        top:5px;
        cursor: pointer;
    }
    .search-desktop {
        margin-top: 11px;
        display: inline-block;
    }
    .expand-search--desktop-nav {
        position: absolute;
        -webkit-transform: translateX(350px);
            -ms-transform: translateX(350px);
                transform: translateX(350px);
        top: 30px;
        right: 0;
        width: 250px;
        height: 50px;
        background-color: white;
        padding: 0;
        -webkit-transition: -webkit-transform 300ms ease;
        transition: -webkit-transform 300ms ease;
        -o-transition: transform 300ms ease;
        transition: transform 300ms ease;
        transition: transform 300ms ease, -webkit-transform 300ms ease;
        overflow: visible;
    }
    .expand-search--desktop-nav.is-open {
        -webkit-transform: translateX(-100px);
            -ms-transform: translateX(-100px);
                transform: translateX(-100px);
    }
    .expand-search--desktop-nav .expand-search__form .navbar-search__btn>span.icon {
        left: 0;
    }
    .expand-search--desktop-nav .expand-search__form .form-control {
        padding-left: 50px;
    }
    .expand-search--desktop-nav .expand-search__form pre {
        display: none;
    }
    .expand-search--desktop-nav .navbar-search__btn {
        border: none;
        height: 40px;
        width: 40px;
        position: absolute;
        left: 0;
        top: 0;
        background: #d7001e;
        color: #fff;
    }
   .webcam-slider--nav{
       max-height: 556px;
   }

    .webcam-slider__caption .fz30{
        font-size: 25px;
    }
    .img-badge{
        right: 20px;
        top: 20px;
        width: 150px;
        height: 150px;
    }

    .img-badge .img-badge__text{
        font-size: 23px;
    }
    .leitprodukt-teaser__body{
        padding: 30px;
    }
    .anreise-map__desc{
        position: absolute;
        width: 500px;
        z-index: 1;
    }

    .tab-content--anreise{
        position: relative;
        top: 0;
        margin-top: 0;
        z-index: 0;
    }
    .top-event-slider {
        padding:30px 0;
    }

    .top-event-teaser.teaser-top{
        margin-bottom: 30px;
    }

    .top-event-teaser.teaser-bottom{
        margin-top: 30px;
    }


    .top-event-teaser:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        background: #d7001e;
        border-radius: 50%;
        bottom: -35px;
        left: 50%;
        margin-left: -4px;
        z-index: 1;
    }
    .top-event--no-timeline .top-event-teaser:before{
        display: none;
    }

    .top-event-teaser.teaser-bottom:before,
    .top-event-teaser.teaser-bottom:after{

        bottom: auto;
        top: -34px;
    }

    .top-event-teaser:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 35px;
        background: #d7001e;
        left: 50%;
        bottom: -35px;
        margin-left: -1px;
        z-index: 1;
    }
    .top-event--no-timeline .top-event-teaser:after{
        display: none;
    }


    .top-event__timeline{
        position: absolute;
        left: 50px;
        right: 50px;
        height: 1px;
        background-color: #d9dadb;
        top: 50%;
    }
    .top-event--no-timeline .top-event__timeline{
        display: none;
    }

    .top-event__timeline .is-left{
        position: absolute;
        left: 0;
        top: -25px;
    }

    .top-event__timeline .is-right{
        position: absolute;
        right: 0;
        top: -25px;
    }

    .top-event-teaser__icon{ display: block; position: absolute; left: 0; bottom: 0; font-size: 90px; opacity: 0.1; }

    .top-event-teaser__info{ gap: 0; }
    .single-facility-teaser{
        padding: 25px;
    }
    .facility-list{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .facility-list>li {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 100px;
                flex: 1 1 100px;
        position: relative;
    }


    .facility-list>li:first-child{
        padding-left: 5px;
        border-left: none;
    }

    .row--inline-block-sm {
        font-size: 0;
    }

    .row--inline-block-sm> .col.text-right-sm{
        text-align: right;
    }

    .row--inline-block-sm>.col {
        display: inline-block;
        float: none;
        font-size: 16px;
        vertical-align: top;
        text-align: left;
    }
    .row--inline-block--middle-sm>.col {
        vertical-align: middle;
    }


    /*same height cols*/
    .row--same-height__item,
    .slick-initialized .row--same-height__item.slick-slide {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
            -ms-flex: 1 0 auto;
                flex: 1 0 auto;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
    /*
     * the .row--same-height selector has to be after
     * .row--same-height__item to increase the specificity
     * in case of chaining the two selectors
     * (which is needed for nested rows)
     */
    .row--same-height{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }

    .row--same-height .slick-track {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }
    .row--same-height .slick-vertical .slick-track {
        display: block;
    }
    .row--same-height .slick-vertical .slick-list {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 0px;
                flex: 1 1;
    }

    .row--same-height:before,
    .row--same-height:after{
        display: none;
    }

    .row--same-height.overflow-visible{
        overflow: visible;
    }

    .row--same-height > .col,
    .row--same-height.slick-slider .col {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: auto;
    }

    .row--same-height__item--fixed-height {
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        -ms-flex-negative: 0;
            flex-shrink: 0;
        -ms-flex-preferred-size: auto;
            flex-basis: auto;
    }
    .row--same-height__item__variable-height {
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        -ms-flex-negative: 0;
            flex-shrink: 0;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
    }
    .row--same-height--center {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
    }
    .row--center-cols {
        /*remove whitespace*/
        font-size: 0;
        text-align: center;
    }
    .row--center-cols > .col {
        display: inline-block;
        float: none;
        font-size: 16px;
        vertical-align: top;
        text-align: left;
    }
    .row.row--tour-overview { margin-top: -30px; margin-left: -15px; margin-right: -15px; }
    .row--tour-overview > .col { margin-top: 30px; padding-left:15px; padding-right:15px; }
    .infobox {
        margin-bottom: 30px;
        padding: 30px;
    }

    .infobox>img{
        top: -8px;
        right: -8px;
    }

    .infobox__body__content{
        -webkit-column-count: 2;
           -moz-column-count: 2;
                column-count: 2;
        -webkit-column-gap: 30px;
           -moz-column-gap: 30px;
                column-gap: 30px;
    }
    .countdown-slide__body{
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
    }
  .head-sujet__content{
    padding: 30px 30px 45px;
  }
  .head-sujet__hl{
    font-size: 50px;
  }
  .head-sujet__video-play{
    width: 100px;
    height: 100px;
    font-size: 35px;
    margin-bottom: 50px;
  }
  .head-sujet__video-play .icon{
    margin-left: 4px;
  }
  .headline-block{
    margin: 30px 0 70px;
  }
  .img-gallery-sujet{
    margin: 70px 0 100px;
  }
  .img-gallery-sujet .video-play{
    width: 100px;
    height: 100px;
    font-size: 35px;
  }
  .accommodation-teaser__body{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1 0 auto;
            flex: 1 0 auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .accommodation-teaser__bottom{
    margin-top: auto;
  }
  .accommodation-teaser__price-info{
    margin-left: 10px;
    display: inline-block;
  }
  .acco-console{
    width: 600px;
    -webkit-transform: translateX(-600px) ;
        -ms-transform: translateX(-600px) ;
            transform: translateX(-600px) ;
  }
  .acco-console__nav .btn{
    padding: 10px 20px;
    font-size: 15px;
  }

  .acco-console__nav .console__item .icon{
    font-size: 18px;
  }

  .acco-console__content { top: 99px; }
  .acco-console__content {
    padding: 60px;
  }
  .acco-console__content .close-console{
    margin-bottom: 20px;
  }
  .request-teaser{
    background-color: #fff;
  }
  .header-img-text-block__text-block.header-img{
    margin-top: -160px;
  }

  .header-img-text-block__text-block .headline-block{
    padding: 70px 20px;
  }
  .header-top-bar{
    font-size: 20px;
  }
    .cookie-bar {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: baseline;
            -ms-flex-align: baseline;
                align-items: baseline;
    }
    .cookie-bar__text {
        display: block;
        -webkit-box-flex: 0;
            -ms-flex: 0 1 auto;
                flex: 0 1 auto;
    }
    .cookie-bar__detail {
        -webkit-box-flex: 1;
            -ms-flex: auto;
                flex: auto;
        margin-left: 1rem;
    }
    .cookie-bar__buttons {
        -webkit-box-flex: 0;
            -ms-flex: none;
                flex: none;
        margin-left: 1rem;
    }
    .tt-menu { min-width: 350px; width: auto; padding: 24px; }
    .contact-person-area{ margin-top: 50px; }
    .contact-person-teaser{ font-size: 16px; }
    .contact-person-teaser__img{ max-width: 168px; }
    .contact-person-teaser__contact{ font-size: 15px; }
    .contact-person-teaser__quote{ font-size: 17px; margin-top: 30px; }
    .contact-person-teaser__link a{ margin-top: 30px; }
    .brand-person-area{ margin-top: 50px; }
    .brand-person-teaser{ height: 100%; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; }
    .brand-person-teaser__body{ padding: 0 15px; }
    .brand-person-teaser__info{ font-size: 15px; }
    .brand-person-teaser__link{ margin-top: auto; }
    .brand-person-teaser__link a{ margin-top: 20px; }
    .quote-item--big .quote-item__text { width: 75%; font-size: 40px; margin: 0 auto; }
    .modal--center {
        text-align: center;
        padding: 0!important;
    }

    .modal.modal--center:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -4px;
    }

    .modal--center .modal-dialog {
        display: inline-block;
        text-align: left;
        vertical-align: middle;
    }
    .modal-lg{
        width: 1000px;
    }
    .info-modal-item{ width: 998px; }
    .info-modal-item{
        padding: 40px;
    }
    .info-modal-item__title{
        font-size: 24px;
    }
    .info-modal-item__icon {
        font-size: 27px;
        margin-top: 4px;

    }
    .consent:not(.full-width) .consent__overlay{
        padding: 40px;
        max-width: 65%;
        max-height: 65%;
    }
    .embed-responsive-2by3 .consent__overlay,
    .embed-responsive-video-portrait .consent__overlay,
    .embed-responsive-1by1 .consent__overlay{
        max-width: 95%;
        max-height: 95%;
        padding: 15px;
    }
    .hl-big{ font-size: 60px; }
    .is-magazin .content-block{ margin: 80px 0; }
    .author-btn{ margin-bottom: 80px; }
    .image-text-sujet__body{ padding: 40px 40px 30px; }
    .image-text-sujet--extended .image-text-sujet__body{ padding: 30px 40px; }
    .image-text-sujet__title{ font-size: 22px; }
    .image-text-sujet__hl{ font-size: 62px; }
    .text-sujet-slider .arrow-wrapper{

}
    .image-text-sujet__tags{ margin-top: auto; }
    .page-heading-magazin__hl{ font-size: 80px; }
    .page-heading-magazin .wysiwyg{ margin-top: 50px; }
    .teaser-text{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: vertical; -webkit-box-direction: normal; -ms-flex-direction: column; flex-direction: column; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; height: 100%; padding: 30px; }
    .teaser-text__hl{ font-size: 28px; margin-bottom: 20px; }
    .teaser-text ul>li{ font-size: 15px; }
    .img-teaser__body{ padding: 10px; }
    .img-teaser--big .img-teaser__body{ padding: 20px; }
    .img-teaser__top-title{ font-size: 13px; }
    .img-teaser__title{ font-size: 15px; }
    .img-teaser--big .img-teaser__title{ font-size: 22px; }
    .img-teaser .icon-text{ font-size: 13px; }
    .img-teaser__video-badge{ width: 27px; height: 27px; font-size: 12px; }
    .img-teaser__title-container{ padding-left: 35px; }
    .author-sujet__social{ margin-top: 50px; }
    .author-sujet__social__item{ font-size: 29px; }
    .teaser-facts__hl{ margin-bottom: 20px; }
    .tag-teaser{ padding: 15px; font-size: 14px; }
    .tag-teaser:after { height: 70px; }
    .author-teaser__body{ padding: 15px; }
    .author-teaser__title{ max-width: 50%; font-size: 30px; }
    .link-teaser__hl{ font-size: 35px; bottom: 25px; left: 25px; right: 25px; }
    .link-teaser__icon{ font-size: 38px; position: absolute; top: 25px; left: 25px; }
    .page-heading-tag{ margin: 100px auto 50px; }
    .page-heading-tag__hl,
.page-heading-tag__title{ font-size: 100px; }
    .page-heading-tag__hashtag{ vertical-align: -30px; margin-right: -25px; margin-left: 25px; }
    .page-heading-tag .wysiwyg{ margin-top: 20px; }
    .article-tabbing .tab-content{ min-height: 250px; padding-right: 50px; }
    .article-tabbing__hl{ font-size: 60px; text-align: right; position: absolute; top: 10%; padding: 30px 80px 30px 40px; }
    .article-tabbing__hl{

}
    .article-wysiwyg__hl{ font-size: 60px; margin-bottom: 30px; }
    .img-landscape__container{ max-width: 735px; width: 100%; margin: 0 auto; }
    .img-portrait__container{ max-width: 480px; width: 100%; margin: 0 auto; }
    .img-portrait__title,
.img-landscape__title{ font-size: 60px; bottom: 80px; -webkit-transform: translateX(15%); -ms-transform: translateX(15%); transform: translateX(15%); padding: 6px 30px 0; }
    .article-img-text-slide__hl{ font-size: 60px; margin-bottom: 20px; }
    .article-checklist__body { padding: 46px 36px 16px 36px; width: calc(100% - 50px); margin-top: -40px; position: relative; display: -webkit-inline-box; display: -ms-inline-flexbox; display: inline-flex; margin-left: 50px; }
    .article-checklist__hl { font-size: 50px; margin-bottom: 20px; }
    .article-checklist__icon { padding-right: 28px; }
    .article-teaser-slider__hl{ margin-bottom: 70px; }
    .article-category-teaser{ font-size: 15px; }
    .article-category-slide__img-title{ font-size: 60px; top: 30px; left: 30px; right: 30px; }
    .article-category-slide .link-teaser__hl{ font-size: 30px; bottom: 20px; left: 20px; right: 20px; }
    .article-category-slide .link-teaser__icon{ top: 20px; left: 20px; right: 20px; }
    .article-category-slide__tag-list{ font-size: 30px; }
    .article-category-slide__body{ padding: 30px 16%; }
    .article-category-slide__title{ font-size: 60px; }
    .category-slider{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .category-slider .article-category-slide {
        width: 100%;
    }
    .category-slider .slick-list {
        width: 100%;
    }

    .category-slider .slick-track {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
    }

    .category-slider .slick-slide {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        height: auto;
    }

    .category-slider .slick-slide>.row{
        height: 100%;
    }

    .arrow-wrapper-dark{ width: 40px; height: 60px; }

    .arrow-wrapper-dark .icon{ font-size: 20px; }

    .category-slider-navigation .arrow-left{ left: -45px; }

    .category-slider-navigation .arrow-right{ right: -45px; }

    .category-slider__paging{ font-size: 28px; }

    .audio-player__container{ padding: 13px 30px; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-orient: horizontal; -webkit-box-direction: normal; -ms-flex-direction: row; flex-direction: row; -webkit-box-pack: justify; -ms-flex-pack: justify; justify-content: space-between; }

    .audio-player__intro{ margin-bottom: 0; }

    .region-badge{ padding: 2px 6px 4px; }

    .region-badge__title{ font-size: 15px; line-height: 1.26667; }
    .region--silvretta .nav-tabs.nav-tabs--primary>li.active>a:before {
        border-top: 9px solid #E52A1E;
    }
    .region--silvretta .top-event-teaser:after, .region--raetikon .top-event-teaser:before{
        background: #E52A1E;
    }
    .region--raetikon .nav-tabs.nav-tabs--primary>li.active>a:before {
        border-top: 9px solid #FAB118;
    }
    .region--raetikon .top-event-teaser:after, .region--raetikon .top-event-teaser:before{
        background: #FAB118;
    }
    .region--verwall .nav-tabs.nav-tabs--primary>li.active>a:before {
        border-top: 9px solid #4BA927;
    }
    .region--verwall .top-event-teaser:after, .region--verwall .top-event-teaser:before{
        background: #4BA927;
    }
    .region--tal .nav-tabs.nav-tabs--primary>li.active>a:before {
        border-top: 9px solid #6EC3BF;
    }
    .region--tal .top-event-teaser:after, .region--tal .top-event-teaser:before{
        background: #6EC3BF;
    }
    .tour-teaser__content{ padding: 12px 20px; }
    .tour-teaser--small .tour-teaser__content{ padding: 9px 12px; }
    .tour-teaser__title{ font-size: 18px; line-height: 1.44444; }
    .tour-teaser--small .tour-teaser__title{ font-size: 12px; }
    .tour-teaser__info.tour-teaser__info-small{ font-size: 12px; }
    .tip-area__badge{ height: 140px; width: 140px; top: -40px; }
    .tip-area__badge-title{ font-size: 25px; line-height: 1.28; }
    .region-teaser__content{ padding: 15px; font-size: 12px; }
    .img-tour-area__title{ font-size: 30px; }
    .img-tour-area__slider{ margin-right: 20px; }
    .img-tour-area__slider-container{ padding: 0 25px; }
    .tour-head__content{ padding: 40px 20px 30px 20px; }
    .tour-head__title{ font-size: 30px; }
    .tour-head__badge{ adding: 5px 10px 6px; }
    .tour-detail-info__fact-list.list-inline{ margin-left: -40px; }
    .tour-detail-info__list.list-inline{ margin-left: -40px; }
    .tour-detail-info__list.list-inline li{ padding: 5px 40px; }
    .tour-detail-info__rating-list.list-inline li{ padding: 0 3px; }
    .tour-detail-info__fact-icon{ font-size: 42px; }
    .tour-detail-info__rating-icon{ font-size: 23px; }
    .poi-map-teaser__icon{ font-size: 86px; }
    .poi-map-teaser__content{ padding: 25px 33px 25px; }
    .poi-map__pin:after{ font-size: 35px; }
    .poi-map__pin:before{ font-size: 35px; } }
@media screen and (max-width: 930px) {
 .tour-teaser__info{ font-size: 13px; line-height: 1.30769; } }
@media screen and (max-width: 991px) {
 .filter-sidebar.filter-sidebar--overlay{ position: fixed; width: 100%; height: 100%; top: 0; left: 0; -webkit-transform: translateX(-100%); -ms-transform: translateX(-100%); transform: translateX(-100%); -webkit-transition: -webkit-transform 200ms ease-in-out; transition: -webkit-transform 200ms ease-in-out; -o-transition: transform 200ms ease-in-out; transition: transform 200ms ease-in-out; transition: transform 200ms ease-in-out, -webkit-transform 200ms ease-in-out; background: #FFF; z-index: 200; overflow-y: auto; }
 .filter-sidebar.filter-sidebar--overlay > .filter-sidebar__content{ padding: 20px; }
 .filter-sidebar.filter-sidebar--overlay.is-open{ -webkit-transform: translateX(0); -ms-transform: translateX(0); transform: translateX(0); }
 .filter-sidebar__overlay-toggle{ display: block; }
 .filter-sidebar__overlay-close{ display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center; position: absolute; top: 0; right: 0; width: 50px; height: 50px; background: #323233; color: #FFF; }
 .filter-sidebar__submit{ position: -webkit-sticky; position: sticky; bottom: 0; left: 0; width: 100%; background: #FFFFFF; padding: 20px; z-index: 1; -webkit-box-shadow: 0 0 4px rgb(0 0 0 / 15%); box-shadow: 0 0 4px rgb(0 0 0 / 15%); }
 .filter-sidebar--overlay .filter-sidebar__submit .btn{ width: 100%; height: 50px; margin: 0; } }
@media screen and (max-width: 1100px) { }
@media screen and (max-width: 1199px) { }
@media screen and (min-width: 1200px) {
    .fz30 { font-size: 30px; }
    .embed-responsive.embed-responsive-img-text-slide {
        padding-top: 50%;
    }
    .embed-responsive.embed-responsive-text-teaser{
        padding-top: 80%;
    }
    .embed-responsive.embed-responsive-perfect-day-slider {
        padding-top: 33.3333333333%;
    }
    .navbar-brand>img{
        max-height: 170px;
    }
    .navbar-right .expand-search-nav .expand-search__box{
        font-size: 18px;
        padding-left: 20px;
    }
    .container-scrollspy.affix{
        padding-left:145px;
    }
    .navbar-default.navbar-scrollspy .navbar-nav>li>a{
        font-size: 16px;
    }
    .image-teaser-slide__body h2{
        font-size: 80px;
        line-height: 85px;
    }

    .image-teaser-slide__desc{
        margin: 40px 0;
    }
    .event-teaser__body{
        padding: 15px 20px;
        font-size: 17px;
    }
    .weather-teaser--big .weather-data{
        padding: 0 40px;
    }
    .perfect-day__more-infos{
        width: 800px;
    }
  .head-sujet__hl{
    font-size: 80px;
  }
  .accommodation-teaser__body{
    padding: 25px 30px;
  }
  .contact-person-teaser{ font-size: 17px; }
  .contact-person-teaser__title{ font-size: 25px; }
  .contact-person-teaser__quote{ font-size: 20px; }
  .quote-item__img{ margin-left: 25px; }
  .teaser-facts{ padding: 40px 65px; }
  .author-teaser__title{ font-size: 50px; }
  .article-tabbing .tab-content{ padding-right: 100px; }
  .img-portrait__title,
.img-landscape__title{ -webkit-transform: translateX(40%); -ms-transform: translateX(40%); transform: translateX(40%); }
  .article-text-right .article-img-text-slide__body{ padding: 20px 20px 20px 80px; }
  .article-text-left .article-img-text-slide__body{ padding: 20px 80px 20px 20px; }
  .article-img-text-slide__row{ -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
  .article-checklist__container { padding: 80px 20px 20px 80px; }
  .article-checklist__hl { font-size: 60px; }
  .article-category-teaser{ font-size: 17px; }
  .tour-teaser--small .tour-teaser__content{ padding: 12px 20px; }
  .tour-teaser--small .tour-teaser__title{ font-size: 18px; }
  .tour-teaser__info + .tour-teaser__info{ margin-left: 20px; padding-left: 0; }
  .tour-teaser__info.tour-teaser__info-small{ font-size: 15px; }
  .tour-teaser__info + .tour-teaser__info:before{ display: none; }
  .tour-teaser__icon{ display: block; }
  .tip-area__container{ padding: 53px 20px 70px; }
  .region-teaser__content{ padding: 18px 32px 24px; font-size: 17px; }
  .img-tour-area__info + .img-tour-area__info{ margin-left: 15px; padding-left: 0; } }
@media screen and (min-width: 1500px) {
    .text-teaser__body{
        padding: 40px;
    }
    .top-event-teaser__icon{ font-size: 130px; }
    .top-event-teaser__info{ height: 100%; }
    .quote-item__img{ max-width: 150px; margin-left: 35px; }
    .tip-area__badge{ top: -24px; left: -24px; }
    .img-tour-area__content{ padding: 42px 20px; }
    .img-tour-area__title{ font-size: 40px; margin: 24px 0; }
    .img-tour-area__text{ margin-top: 24px; }
    .img-tour-area__slider-container{ padding: 0 50px; }
    .tour-head__content{ padding: 176px 30px 45px 210px; display: block; }
    .tour-head__title{ margin: 20px 0; font-size: 40px; }
    .tour-head__subtitle{ font-size: 22px; margin-bottom: 30px; } }
@media screen and (max-width: 1500px) {
    .arrow-wrapper.arrow-wrapper--teaser.arrow-small-lg{
        height: 40px;
        width: 25px;
    }

    .arrow-wrapper.arrow-wrapper--teaser.arrow-right.arrow-small-lg{
        right: -5px;
    }

    .arrow-wrapper.arrow-wrapper--teaser.arrow-left.arrow-small-lg{

        left: -5px;
    }

    .arrow-wrapper.arrow-wrapper--teaser.arrow-small-lg .icon {
        font-size: 15px;
    }

    .tour-teaser__info.tour-teaser__info-small .tour-teaser__icon{ display: none; } }
@media screen and (max-width: 768px){
    .page-heading .mt15 {
        margin-bottom:30px;
        margin-top: 0;
    }
    .nav-tabs.nav-tabs--primary>li{
        float: none;
        margin-bottom: 5px;
        text-align: center;
    }

    .nav-tabs.nav-tabs--primary>li.active>a,
    .nav-tabs.nav-tabs--primary>li.active>a:hover,
    .nav-tabs.nav-tabs--primary>li.active>a:focus,
    .nav-tabs.nav-tabs--primary>li>a:hover{
        border-bottom: 2px solid #d7001e;
    }

    .nav-tabs.nav-tabs--primary>li>a{
        padding: 10px 15px;
        border-right: none;
    }

    .anlagen-accordion .panel-default>.panel-heading+.panel-collapse>.panel-body{
        padding-bottom: 0;
    }

    .anlagen-accordion .panel-group{
        margin-bottom: 0;
    }
    .leitprodukt-teaser{
        margin: 15px 0;
    }
    #pc-cookie-notice #pc-button button{
        width: 100%;
    }

    #pc-cookie-notice #pc-button{
        position: relative!important;
    }

    #pc-cookie-notice #pc-message {
        padding: 0 15px!important;
    }
}
@media screen and (min-width: 992px){
    .row{
        margin-left: -15px;
        margin-right: -15px;
    }

    .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
        padding-left: 15px;
        padding-right: 15px;
    }

    .container{
        padding-left: 15px;
        padding-right: 15px;
    }

    /*.container>.navbar-header,*/
    /*.container-fluid>.navbar-header,*/
    /*.container>.navbar-collapse,*/
    /*.container-fluid>.navbar-collapse{*/
        /*margin-right: -15px;*/
        /*margin-left: -15px;*/
    /*}*/
    h1, .h1 {
        font-size: 40px;
        line-height: 1.225;
    }
    h2, .h2 {
        font-size: 40px;
        line-height: 1.225;
    }
    h3, .h3 {
        font-size: 28px;
        line-height:1.46429;
    }
    h4, .h4 {
        font-size: 22px;
        line-height:1.45455;
    }

    /*h5, .h5 {*/
    /*font-size: 20px;*/
    /*line-height:calc(22/20);*/
    /*}*/

    /*h6, .h6{*/
    /*font-size: 18px;*/
    /*line-height:calc(26/18);*/

    /*}*/

    .embed-responsive.embed-responsive-img-teaser-vertical { padding-top: 218%; }
    .embed-responsive.embed-responsive-big {
        padding-top: 48.4848%;
    }

    .embed-responsive.embed-responsive-2by1-on-md {
        padding-top: 50%;
    }
    .btn{
        font-size: 17px;
        line-height: 17px;
        padding: 12px 20px;
    }

    .btn.btn-lg{
        padding: 17px 30px;
    }
    .navbar-default.navbar-no-icons .navbar-nav>li>a{
        padding: 39px 11px;
        font-size: 15px;
    }
    .navbar-right .expand-search-nav .expand-search__box{
        width: 180px;
    }

    .navbar-right .btn-webshop{
        margin-right: 20px;
    }
    .slide{
        padding: 60px 0;
    }
    .slide.slide--lg{
        padding: 100px 0;
    }
    img.mfp-img{
        max-width: 990px;
    }
    .main-content{
        padding-top: 60px;
    }

    .margin-helper-80{
        margin-top: -80px;
    }
    .image-box__body{
        padding: 60px;
    }
    .img-text-slide__body blockquote{
        padding: 30px;
    }

    .img-text-slide__body .quote .h2{
        font-size: 26px;
        line-height: 1.34615;
    }
    .storytelling-teaser__body{
        padding: 30px;
        padding-top: 60px;
    }
    .navbar-teaser__body{
        font-size: 24px;
    }
    .list-sponsors>li {
        padding: 0;
        margin: 20px 40px;
    }
    .checkbox-wrap label:hover{
        color: #d7001e;
    }
    .webcam-slider__caption{
        background: #323233;
        padding: 8px 20px;
        font-size: 16px;
    }
    .facility-list>li{
        padding: 0 18px;
    }
    .row--vertical-gutter-30 {
        margin-top: -30px;
        clear: both;
    }
    .row--vertical-gutter-30 > .col {
        margin-top: 30px;
    }

    .row--vertical-gutter-80 {
        margin-top: -80px;
        clear: both;
    }
    .row--vertical-gutter-80 > .col {
        margin-top: 80px;
    }
  .acco-console__nav .btn{
    padding: 17px 20px;
  }
  .contact-person-teaser__img{ max-width: 225px; }
  .hl-big{ font-size: 100px; }
  .image-text-sujet__body{ padding: 80px 80px 30px 20%; }
  .image-text-sujet--extended .image-text-sujet__body{ padding: 50px 13%; }
  .image-text-sujet__title{ font-size: 28px; }
  .image-text-sujet__hl{ font-size: 75px; }
  .teaser-text ul>li{ font-size: 20px; }
  .img-teaser__body{ padding: 15px; }
  .img-teaser--big .img-teaser__body{ padding: 35px; }
  .img-teaser__top-title{ font-size: 15px; }
  .img-teaser__title{ font-size: 17px; }
  .img-teaser .icon-text{ font-size: 15px; }
  .img-teaser .icon-text .icon{ font-size: 20px; }
  .img-teaser__badge-overlay{ -webkit-transform: translateZ(0); transform: translateZ(0); }
  .img-teaser__badge{ width: 280px; -webkit-transform: rotate(45deg) translateX(75px) translateY(-45%); -ms-transform: rotate(45deg) translateX(75px) translateY(-45%); transform: rotate(45deg) translateX(75px) translateY(-45%); height: 76px; font-size: 20px; }
  .tag-teaser{ padding: 20px; font-size: 17px; }
  .article-checklist__body { margin-left: -130px; min-width: 380px; max-width: calc(100% + 130px); width: auto; margin-top: 0; }
}
@media screen and (min-width: 1280px){
    .page-wrapper {
        padding-right: 120px;
    }
    .page-wrapper.page-wrapper--microsite{
        padding-top: 140px;
    }
    .navbar-default .navbar-nav>li>a{
        padding: 24px 30px 17px;
        font-size: 18px;
    }

    .navbar-default.navbar-no-icons .navbar-nav>li>a{
        padding: 38px 21px;
        font-size: 17px;
    }

    .navbar-main.affix.navbar-default.navbar-no-icons .navbar-nav>li>a{
        padding: 30px 17px;
        font-size: 17px;
    }
    .console{
        width: 1280px;
        -webkit-transform: translateX(1160px);
            -ms-transform: translateX(1160px);
                transform: translateX(1160px);
    }

    .console-nav{
        width: 120px;
    }

    .nav-tabs.nav-tabs--console>li>a{
        font-size: 16px;
    }

    .tab-content--console .tab-pane{
        padding: 40px 80px;
        left: 120px;
    }
    .footer:not(.footer--fullwidth){
        padding-right: 120px;
    }
    .information-hint{
        right: 120px;
    }
    @media screen and (min-width: 768px){
        .expand-search{
            width: 1160px;
            -webkit-transform: translateX(1160px);
                -ms-transform: translateX(1160px);
                    transform: translateX(1160px);
        } }
    .expand-search--desktop-nav {
        width: 300px;
        height: 50px;
        -webkit-transform: translateX(350px);
            -ms-transform: translateX(350px);
                transform: translateX(350px);

    }
    .expand-search--desktop-nav.is-open {
        -webkit-transform: translateX(-165px);
            -ms-transform: translateX(-165px);
                transform: translateX(-165px);
    }

}
@media screen and (min-width: 1400px){
 .embed-responsive.embed-responsive-img-teaser-vertical { padding-top: 209%; }
    .navbar-right .expand-search-nav .expand-search__box{
        width: 345px;
    }
    .weather-teaser-simple{
        padding: 30px 40px;
        padding-bottom: 70px;

    }

    .weather-teaser-simple .icon-weather {
        height: 130px;
        width: 130px;
    }

    .weather-temp {
        /*left:70%;*/
        margin-left: 20px;
        font-size: 40px;
        line-height: 1.225;
    }

    .avalanche-img{
        width: 108px;
    }

    .avalanche-status{
        font-size: 30px;
        line-height: 1.3;
    }

    .contact-person-teaser__contact-item{ display: inline-block; margin-left: 10px; }

    .contact-person-teaser__contact-item+.contact-person-teaser__contact-item{ margin-left: 25px; }

    .image-text-sujet__body{ padding: 80px 120px 30px 27%; }

    .img-teaser__body{ padding: 25px; }

    .img-teaser__top-title{ font-size: 17px; }

    .img-teaser__title{ font-size: 20px; }

    .img-teaser .icon-text{ font-size: 17px; }

    .author-teaser__body{ padding: 25px; }

    .link-teaser__hl{ font-size: 50px; bottom: 45px; left: 45px; right: 45px; }

    .link-teaser__icon{ font-size: 50px; position: absolute; top: 45px; left: 45px; }

    .article-category-slide .link-teaser__hl{ font-size: 40px; bottom: 30px; left: 30px; right: 30px; }

    .article-category-slide .link-teaser__icon{ bottom: 30px; left: 30px; right: 30px; } }
@media screen and (min-width: 768px) and (max-width: 1199px){
    .embed-responsive.embed-responsive-text-teaser{
        padding-top: 125%;
    }
    .btn-webshop__text{
        display: none;
    }

    .navbar-right .btn-webshop .icon{
        margin-right: 0;
    }

    .navbar-right .btn-webshop{
        padding: 10px 15px 11px;
    }
    .event-short-info{
        font-size: 14px;
    }

    .event-teaser__hl{
        font-size: 17px;
    }
  .accommodation-teaser .link-primary{
    margin-top: 10px;
    display: inline-block;
  }

  .accommodation-teaser .link-primary{
    font-size: 16px;
  }
}
@media screen and (min-width: 767px) and (max-width: 1199px) {
    .embed-responsive.embed-responsive-perfect-day-slider {
        padding-top: 50%;
    }
}
@media screen and (min-width: 768px) and (max-width: 930px){
    .navbar-brand>img{
        max-height: 100px;
    }
    .navbar-default.navbar-no-icons .navbar-nav>li>a,
    .navbar-main.affix.navbar-default.navbar-no-icons .navbar-nav>li>a{
        font-size: 12px;
    }
    .navbar-right .navbar-search.navbar-search{
        width: 120px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1079px){
    .portal-gargellen .navbar-brand>img {
        width: 105px;
        margin-top: 19px;
    }
}
@media screen and (min-width: 992px) and (max-width: 1399px){
    .navbar-right .expand-search-nav .expand-search__box{
        width: 200px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1100px){
    .navbar-right .navbar-search{
        width: 150px;
    }

    .navbar-right .navbar-search .form-control{
        font-size: 12px;
        padding: 8px 10px;
    }

}
@media screen and  (min-width: 768px) and (max-width: 991px){
    .navbar-right .navbar-search input{
        max-width: 190px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px){
    .dropdown--lang{
        margin-left:5px;
    }
}
@media screen and (min-width: 1920px){
    .container-scrollspy.affix{
        padding-left:175px;
        width:1920px;
        left: 50%;
        margin-left:-960px;
    }
}
@media screen and (min-width: 768px) and (max-width: 991px){
    .navbar-default.navbar-scrollspy .navbar-nav>li>a{
        padding: 13px 10px 11px;
        font-size: 16px;
    }
    .content-header-image__body .h1{
        font-size: 50px;
        line-height: 55px;
    }

    .content-header-image__body .h3{
       font-size: 22px;

    }
    img.mfp-img{
        max-width: 700px;
    }
    .text-teaser__body{
        font-size: 16px;
    }

    .text-teaser__body a{
        margin-top: 10px;
    }
    .weather-teaser-simple .icon-weather {
        height: 80px;
        width: 80px;
    }

    .weather-teaser-simple{
        padding: 15px;
        padding-bottom: 50px;
    }

    .weather-teaser{
        padding: 20px;
    }

    .status-slide .snow-value {
        font-size: 25px;
    }
    .pagination>li>a, .pagination>li>span {
        padding: 6px 10px;
    }
    .information-hint{
        width: 300px;
        /*overflow-y: scroll;*/
    }

    .information-hint__body{
        padding: 20px;
    }

    .information-hint .h2{
        font-size: 30px;
    }

    .information-hint .decorated-hr {
        width: 262px;
        margin-bottom: 10px;
        margin-top: 20px;
    }
    .top-event-teaser__body{
        padding: 10px 12px;
    }

    .top-event-teaser__body h2{
        font-size: 16px;
    }

    .teaser .badge.event-badge {
        padding: 5px 5px;
        font-size: 15px;
        bottom: 10px;
    }
}
@media screen and (min-width: 768px) and (max-width: 992px){
  .navbar-lp-brand img{
    width: 100px;
  }
}
@media screen and (min-height: 820px){
    .nav-tabs.nav-tabs--console>li>a{
        padding: 28px 20px;
    }

    .nav-tabs.nav-tabs--console>li>a.console-weather{
        padding: 16px 20px;
    }
}
@media screen and (min-height: 768px) and (max-width: 1499px){
    .text-teaser__body .h3{
        font-size: 24px;
        line-height: 1.35;
    }

    .text-teaser__body{
        font-size: 16px;
    }
}
@media screen and (min-width: 1350px){
    .img-text-slide__text{
        padding: 40px 80px;
        font-size: 17px;
    }

    .img-text-slide__text a{
        margin-top: 30px;
    }
    .text-right .img-text-slide__center{
        right: 80px;
    }
    .img-text-slide__body blockquote{
        padding: 50px;
    }

    .img-text-slide__body .quote .h2{
        font-size: 38px;
        line-height: 1.26316;
    }
}
@media screen and (min-width: 1890px){
 .offer-teaser-slide .arrow-wrapper.arrow-left{ left: 85px; }
 .offer-teaser-slide .arrow-wrapper.arrow-right{ right: 85px; } }
@media screen and (min-width: 768px) and (max-width: 1890px){
 .offer-teaser-slide{ padding: 50px 100px; } }
@media screen and (min-width: 1024px) and (max-width: 1279px){
    .expand-search--desktop-nav.is-open {
        -webkit-transform: translateX(-130px);
            -ms-transform: translateX(-130px);
                transform: translateX(-130px);
    }
}
@media screen and (min-width: 768px) and (max-width: 1365px){
    .perfect-day--small-dots .dots-wrapper {
        width: 55px;
    }
}
@media screen and (min-width: 1366px) {
    .perfect-day--small-dots .dots-wrapper {
        width: 65px;
    }
}
@media screen and (min-width: 1199px) and (max-width: 1500px){
   .btn.accommodation-teaser__btn{
     font-size: 14px;
     line-height: 17px;
     padding: 12px 10px;
   }

  .accommodation-teaser .link-primary{
    font-size: 14px;
  }
}
@media screen and (min-width: 1650px){
 .contact-person-teaser__contact{ text-align: left; font-size: 17px; } }
@media screen and (min-width: 1300px){
 .brand-person-teaser__title{ font-size: 22px; } }
@media screen and (min-width: 1350px) and (max-width: 1850px){
    .col-sm-3 .embed-responsive-1by1 .consent__overlay-text{
        font-size: 14px;
    }

    .col-sm-3 .embed-responsive-1by1 .consent__overlay-title{
        font-size: 18px;
    }

    .col-sm-3 .embed-responsive-1by1 .btn{
        margin-top: 10px;
        padding: 6px 15px;
        font-size: 12px;
    }

    .col-sm-3 .embed-responsive-1by1 .consent__overlay{
        padding: 10px;
    }
}
@media screen and (min-width: 768px) and (max-width: 1350px){
    .col-sm-3 .embed-responsive-1by1 .consent__overlay-text{
        font-size: 12px;
    }

    .col-sm-3 .embed-responsive-1by1 .consent__overlay-title{
        font-size: 16px;
        margin-top: 10px;
    }

    .col-sm-3 .embed-responsive-1by1 .btn{
        margin-top: 10px;
        padding: 6px 15px;
        font-size: 12px;
    }

    .col-sm-3 .embed-responsive-1by1 .consent__overlay{
        padding: 8px;
    }
}
@media screen and (min-width: 1600px){
 .image-text-sujet__hl{ font-size: 100px; } }
@media screen and (min-width: 768px) and (max-width: 1200px){
 .author-teaser .btn.btn-bold{ font-size: 18px; }
 .author-teaser .btn.btn-bold>.icon{ font-size: 28px; }
 .article-category-teaser__title{ font-size: 18px; }
 .article-category-teaser .author-item__img img{ height: 40px; } }
@media screen and (min-width: 1380px){
 .article-category-teaser__top{ padding: 28px 28px 0; }
 .article-category-teaser .list-tags{ font-size: 18px; }
 .article-category-teaser__bottom{ padding: 50px 28px 28px; } }
@media screen and (min-width: 1200px) and (max-width: 1380px){
 .article-category-teaser__title{ font-size: 23px; } }
